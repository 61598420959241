import { Box, Button, Paper } from '@mui/material'
import { t } from 'i18next'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getUsersData } from '../../actions/getUsersDataAction'
import { resetState as resetStateUserData } from '../../slices/userDataSlice'
import Loader from '../../components/Loader'
import LogsSearch from './SerachMenu/LogsSearch'
import { getLogsData } from '../../actions/getLogosDataAction'
import { useLocation } from 'react-router-dom'
import DataGridTableLogs from './SerachMenu/DataGridTableLogs'

const Log = () => {



    const dispatch = useDispatch();
    const location = useLocation();
    const userDataReduser = useSelector((state) => state.userData)
    const searchParams = new URLSearchParams(location.search);


    useEffect(() => {
        dispatch(getUsersData())
        return () => {
            dispatch(resetStateUserData())
        }

    }, [dispatch])




    useEffect(() => {
        dispatch(getLogsData({ query: searchParams.toString() }))

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchParams.get("PageSize"), searchParams.get("PageNumber"), searchParams.get("SortOrder"), searchParams.get("SortBy")])


    if (userDataReduser.loading === 'pending' || userDataReduser.loading === 'idle') {
        return <Loader />
    }


    return (
        <Paper variant="p1">
            <Box sx={{ width: "100%", display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center" }}>

                <LogsSearch />

                <Button onClick={() => dispatch(getLogsData({ query: searchParams.toString() }))} variant='b2' sx={{ width: "200px" }}>
                    {t("search")}</Button>

            </Box>
            <Box marginTop={'40px'}>
                <DataGridTableLogs />

            </Box>
        </Paper>
    )
}

export default Log