// src/pages/NotFound.js

import React from 'react';
import { Link } from 'react-router-dom';
import { Container, Typography, Button } from '@mui/material';
import { SentimentVeryDissatisfied as SadIcon } from '@mui/icons-material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
    root: {
        textAlign: 'center',
        marginTop: theme.spacing(8),
    },
    icon: {
        fontSize: 100,
        color: theme.palette.error.main,
    },
    message: {
        marginTop: theme.spacing(2),
    },
    button: {
        marginTop: theme.spacing(4),
    },
}));

function NoMatch() {
    const classes = useStyles();

    return (
        <Container className={classes.root} maxWidth="sm">
            <SadIcon className={classes.icon} />
            <Typography variant="h4" className={classes.message}>
                Oops! Page not found.
            </Typography>
            <Typography variant="body1">
                The page you are looking for might have been removed or does not exist.
            </Typography>
            <Button
                variant="contained"
                color="primary"
                component={Link}
                to="/"
                className={classes.button}
            >
                Go back to the homepage
            </Button>
        </Container>
    );
}

export default NoMatch;
