import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { AxiosInstanceService } from "../services/axiosInstance";
import { apiRoute } from "../util/constant";

const initialState = {
    loading: 'idle',
    updated: false,
    added: false,
    hasError: false,
    errorMessage: "",
    parentList: []
}
export const getParentData = createAsyncThunk(
    'parent/data',
    async (_, { rejectWithValue }) => {
        try {
            const instance = await AxiosInstanceService.getAxiosInstance();
            const response = await instance.get(`${apiRoute.verificationValues}`)
            return response.data;
        } catch (error) {
            // return custom error message from backend if present
            if (error.response && error.response.data.message) {
                return rejectWithValue(error.response.data.message)
            } else {
                return rejectWithValue(error.message)
            }
        }
    }
);

export const parentSlice = createSlice({
    name: 'parent',
    initialState,
    reducers: {

        resetState: () => {
            return initialState;
        },
        resetError: (state) => {
            state.hasError = false;
            state.errorMessage = ""
        }


    },
    extraReducers: (builder) => {
        builder.addCase(getParentData.pending, (state, action) => {
            state.loading = 'pending';
            state.hasError = false;
            state.errorMessage = "";

        })
            .addCase(getParentData.fulfilled, (state, action) => {
                state.loading = 'succeeded';
                state.parentList = action.payload
                state.hasError = false;
                state.errorMessage = "";
            })
            .addCase(getParentData.rejected, (state, action) => {
                state.loading = 'failed';
                state.hasError = true;
                state.errorMessage = action.payload;
            })
    }

})
export const { resetState, resetError } = parentSlice.actions;

export default parentSlice.reducer