/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { Box, FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import { t } from 'i18next';

function UsersMenu() {
  const location = useLocation();
  const navigate = useNavigate();
  const userDataReducer = useSelector((state) => state.userData);

  const [mainData, setMainData] = useState([]);
  const [selectedUser, setSelectedUser] = useState(0);

  const updateURLQuery = () => {
    const searchParams = new URLSearchParams(location.search);
    if (!selectedUser) {
      searchParams.delete('UserId');
    } else {
      searchParams.set('UserId', selectedUser);
    }
    navigate({ search: searchParams.toString() });
  };

  useEffect(() => {
    if (userDataReducer.loading === 'succeeded') {
      setMainData(userDataReducer.Object);

      const searchParams = new URLSearchParams(location.search);
      const usersParam = searchParams.get('UserId');
      setSelectedUser(usersParam || 0);
    }
  }, [userDataReducer.loading, location.search]);

  useEffect(() => {
    updateURLQuery();
  }, [selectedUser]);

  return (
    <Box display='flex' justifyContent='end' alignItems='end'>
      <FormControl sx={{ minWidth: '200px' }}>
        <InputLabel>{t('UserId')}</InputLabel>
        <Select
          value={selectedUser}
          onChange={(event) => {
            const selectedValue = event.target.value;
            const selectedOption = mainData.find((item) => item.id === selectedValue);

            setSelectedUser(selectedOption?.id || 0);
          }}
          name='status'
        >
          <MenuItem value={0}>{'Select User'}</MenuItem>
          {mainData?.map((item) => (
            <MenuItem key={item.id} value={item.id}>
              {item.userName}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
}

export default UsersMenu;
