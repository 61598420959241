import React from "react";
import ReactDOM from "react-dom";
import Button from "@mui/material/Button";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import Grow from "@mui/material/Grow";
import Paper from "@mui/material/Paper";
import Popper from "@mui/material/Popper";
import MenuItem from "@mui/material/MenuItem";
import MenuList from "@mui/material/MenuList";
import { t } from "i18next";
import { useDispatch, useSelector } from "react-redux";
import { downloadExcelContainer } from "../../../actions/fatechExcelContainerAction";
import Progress from "../../Loader/Progress ";
import { Box } from "@mui/material";

function GenerateExcelButton({ slectedId }) {
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);
  const reduserExcelContainer = useSelector((state) => state.excelContainer);

  const dispatch = useDispatch();
  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  function handleListKeyDown(event) {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpen(false);
    } else if (event.key === "Escape") {
      setOpen(false);
    }
  }

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(open);
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);

  const handleGenerateExcel = (templateId) => {
    dispatch(
      downloadExcelContainer({ query: slectedId, templateId: templateId })
    );
    setOpen(false);
  };

  return (
    <>
      <Box
        sx={{
          display:
            reduserExcelContainer?.loading === "pending" ? "inline" : "none",
        }}
      >
        <Progress Msg="Generate As Excel" />
      </Box>

      <Box
        sx={{
            display:
            reduserExcelContainer?.loading === "pending" ? "none" : "inline",
        }}
      >
        <Button
          ref={anchorRef}
          id="composition-button"
          aria-controls={open ? "composition-menu" : undefined}
          aria-expanded={open ? "true" : undefined}
          aria-haspopup="true"
          variant="b2"
          onClick={handleToggle}
        >
          {t("Generate As Excel")}
        </Button>
        {open &&
          ReactDOM.createPortal(
            <Popper
              open={open}
              anchorEl={anchorRef.current}
              role={undefined}
              placement="bottom-start"
              transition
              disablePortal
            >
              {({ TransitionProps, placement }) => (
                <Grow
                  {...TransitionProps}
                  style={{
                    transformOrigin:
                      placement === "bottom-start" ? "left top" : "left bottom",
                  }}
                >
                  <Paper>
                    <ClickAwayListener onClickAway={handleClose}>
                      <MenuList
                        autoFocusItem={open}
                        id="composition-menu"
                        aria-labelledby="composition-button"
                        onKeyDown={handleListKeyDown}
                      >
                        <MenuItem onClick={() => handleGenerateExcel(0)}>
                          Generate Excel - Template 1
                        </MenuItem>
                        <MenuItem onClick={() => handleGenerateExcel(1)}>
                          Generate Excel - Template 2
                        </MenuItem>
                        <MenuItem onClick={() => handleGenerateExcel(3)}>
                          Generate Excel - Template 3
                        </MenuItem>
                        <MenuItem onClick={() => handleGenerateExcel(4)}>
                          Generate Excel - Template 4
                        </MenuItem>
                        <MenuItem onClick={() => handleGenerateExcel(5)}>
                          Generate Excel - Template 5
                        </MenuItem>
                      </MenuList>
                    </ClickAwayListener>
                  </Paper>
                </Grow>
              )}
            </Popper>,
            document.body
          )}
      </Box>
    </>
  );
}

export default GenerateExcelButton;
