import { Box, Typography } from "@mui/material";
import React, { useState } from "react";
import AnimatedNumbers from "react-animated-numbers";

function CountVehicleView({ count , sx , animate = true }) {
    const [anchor] = useState(null);

  return (
    <Box
      sx={{
     
        minWidth:"200px",
        display:"flex",
        justifyContent:"end",
        alignItems:"center",
        gap:1 ,
        sx
      }}
      borderRadius={2}
    >
      <Typography variant={animate ? "subtitle2" :"h6"} component={"div"}>

        {animate ? 
        <AnimatedNumbers
                    anchorEl={anchor}
          animateToNumber={count}
          fontStyle={{ fontSize: 32 }}
          configs={(number, index) => {
            return {
              mass: 1,
              tension: 230 * (index + 1),
              friction: 50,
            };
          }}
          
        />
        :count}
      </Typography>
      <Typography variant="subtitle1">{"Total Vehicle".toUpperCase()}</Typography>
    </Box>
  );
}

export default CountVehicleView;
