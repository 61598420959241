import { createAsyncThunk } from '@reduxjs/toolkit'
import { AxiosInstanceService } from '../services/axiosInstance'
import { apiRoute } from '../util/constant'




export const getLogsData = createAsyncThunk(
    'logs/getData',
    async ({ query }, { rejectWithValue }) => {
        try {
            const instance = await AxiosInstanceService.getAxiosInstance();
            const response = await instance.get(`${apiRoute.getLogsData}?${query}`)
            return response.data;
        } catch (error) {
            // return custom error message from backend if present
            if (error.response && error.response.data.message) {
                return rejectWithValue(error.response.data.message)
            } else {
                return rejectWithValue(error.message)
            }
        }
    }
);
