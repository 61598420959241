/* eslint-disable react-hooks/exhaustive-deps */
import { Box, FormControl, InputLabel, MenuItem, Paper, Select, Typography } from "@mui/material"
import { t } from "i18next";
import { useEffect, useState } from "react";
import { useFormikContext } from "formik";
import { useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";



const CarStatus = () => {


    const formik = useFormikContext();
    const [searchParams] = useSearchParams();

    const formReducser = useSelector((state) => state.form);
    const vehicleId = searchParams.get(("vehicleId")) || null




    const [mainData, setMainData] = useState([])

    useEffect(() => {
        if (formReducser.loading === 'succeeded') {

            if (formReducser.data.statusList.length !== 0 && formik.values.status.id === undefined) {
                formik.setFieldValue('status', formReducser.data.statusList[0])
                const filter = formReducser.data.statusList.filter(data => (data.id !== 2 && data.id !== 5 && data.id !== 7 && data.id !== 8));
                setMainData(filter)

            } else {
                let filter = formReducser.data.statusList;



                // if (formik?.values?.status?.id) {

                //     switch (formik?.values?.status?.id) {

                //         case 1:
                //             filter = formReducser.data.statusList.filter(data => (data.id !== 2 && data.id !== 5 && data.id !== 7 && data.id !== 8));

                //             break;

                //         case 6:
                //             filter = formReducser.data.statusList.filter(data => (data.id !== 1 && data.id !== 2 && data.id !== 7 && data.id !== 7));

                //             break;

                //         case 8:
                //             filter = formReducser.data.statusList.filter(data => (data.id !== 1 && data.id !== 2 && data.id !== 6));

                //             break;
                //         case 7:
                //             filter = formReducser.data.statusList.filter(data => (data.id !== 1 && data.id !== 6 && data.id !== 8));

                //             break;
                //         case 2:
                //             filter = formReducser.data.statusList.filter(data => (data.id !== 1 && data.id !== 6 && data.id !== 8 && data.id !== 7));

                //             break;

                //         default:
                //     }

                    

                // }







                setMainData(filter)

            }


        }

    }, [formReducser.loading])





    useEffect(() => {


        if (formik?.values?.status.id === 1) {




        }

    }, mainData)



    const noOfDays = () => {


        try {
            const date1 = new Date(formik?.values?.purchaseDate)
            const date2 = new Date(new Date().toISOString().substr(0, 10));
            const Difference_In_Time = date2.getTime() - date1.getTime();
            const Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);
            return Difference_In_Days;
        } catch (error) {

        }
        return 0;
    }




    return (
        <Paper variant="p1">
            <Box display='flex' justifyContent='space-between' alignItems='center'>
                <FormControl sx={{
                    width: '380px',
                    height: '50px'
                }}>

                    <InputLabel>{t("carStatus")}</InputLabel>

                    <Select disabled ={!vehicleId}

                        value={formik.values.status?.id || ''} // Use optional chaining and nullish coalescing
                        onChange={(event) => {
                            const selectedValue = event.target.value;
                            const selectedOption = mainData.find((item) => item.id === selectedValue);
                            formik.setFieldValue('status', selectedOption || null);
                        }}
                        name='status'
                    >
                        {mainData && mainData.map(item =>
                            <MenuItem key={item.id} value={item.id}>{item.name}</MenuItem> // Use item.id as the value
                        )}
                    </Select>
                </FormControl>
                <Box sx={{
                    display: "flex",
                    alignItems: 'baseline'
                }}>
                    <Typography variant="h2" sx={{
                        fontWeight: 'bolder'
                    }} >
                        {noOfDays()}
                    </Typography>
                    <Typography variant="h6" sx={{
                        fontWeight: 'bolder'
                    }} >
                        Days
                    </Typography>

                </Box>
            </Box>
        </Paper >
    )
}

export default CarStatus;