import DashboardIcon from '@mui/icons-material/Dashboard';
import { Box, Grid, Typography } from '@mui/material';
import React from 'react';

import { Outlet, useNavigate } from 'react-router-dom';
import './main.css';

import { ReactComponent as ManagmentLogo } from '../../assets/images/managment.svg';
import FilePresentIcon from '@mui/icons-material/FilePresent';
import TimeToLeaveIcon from '@mui/icons-material/TimeToLeave';


const iconSize = 40; // Fixed font size for icons in pixels

const LeftNav = () => {
    const navigate = useNavigate();
    const leftNavItem = [
        {
            icon: <Box sx={{ fontSize: iconSize }}>
                <DashboardIcon color='secondary' sx={{ fontSize: iconSize }} />
            </Box>
            ,
            label: "Dashboard",
            action: () => { navigate('/') },
        },
        {
            icon:
                <Box sx={{ fontSize: iconSize }}>
                    <TimeToLeaveIcon color='secondary' sx={{ fontSize: iconSize }} />
                </Box>

            ,
            label: "Add New Vehicle",
            action: () => { navigate('/VehicleaddEdit') },
        },

        {
            icon: <ManagmentLogo color='secondary' width={iconSize} height={iconSize} />,
            label: "Management",
            action: () => { navigate('/management') },
        },
        {
            icon: <Box sx={{ fontSize: iconSize }}>
                <FilePresentIcon color='secondary' sx={{ fontSize: iconSize }} />
            </Box>,
            label: "Log Files",
            action: () => { navigate('/log') },
        }
    ]

    const currentYear = new Date().getFullYear();


    return (
        <>
            <Grid container spacing={2} minHeight={'100vh'}>
                <Grid item xs={1} md={1} maxWidth={'120px'} sx={{
                    background: 'black',
                    borderTopRightRadius: "20px",
                    marginTop: '10px',
                }}>
                    <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "space-between", height: "100%", maxHeight: "500px" }}>
                        {leftNavItem.map((item, indx) => (
                            <Box onClick={item.action} key={indx} sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                flexDirection: 'column',
                                alignItems: "center",
                                cursor: 'pointer',
                            }}>
                                {item.icon}
                                <Typography sx={{
                                    color: 'secondary.main',
                                    textAlign: "center"
                                }}>
                                    {item.label}
                                </Typography>
                            </Box>
                        ))}
                    </Box>
                </Grid>
                <Grid item xs={11} md={11}>
                    <Outlet />

                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', }}>
                        <Typography sx={{ pt: 4 }}>
                            © Copyright <a href="https://pinetechs.com/" target="_blank" rel="noopener noreferrer">Pinetechs</a>. All Rights Reserved {currentYear}
                        </Typography>
                    </div>
                </Grid>
            </Grid>
        </>
    )
}

export default LeftNav;
