import { useDispatch, useSelector } from 'react-redux';

import { Box } from '@mui/material';
import NavBarClient from './NavBarClient';
import { useEffect } from 'react';
import { initialClientView } from '../../actions/clientViewAction';
import Progress from '../../components/Loader/Progress ';
import { useSearchParams } from 'react-router-dom';
import { resetState } from "../../slices/clientViewInitialSlice";


function ClientDashboard() {


    const dispatch = useDispatch();

    const InitialData = useSelector((state) => state.clientViewInitial)
    const user = useSelector((state) => state.user.userInfo);
    const [searchParams] = useSearchParams();


    useEffect(() => {
        
        const fetchData = async () => {
            try {
                await dispatch(resetState());

                if (user.accountType === "admin") {
                    dispatch(initialClientView({ clientId: searchParams.get("clientId") }));
                } else {
                    dispatch(initialClientView({clientId: null}));
                }
            } catch (error) {
                console.error('Error in useEffect:', error);
            }
        };

        fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);




    if (InitialData.loading === "pending" || InitialData.loading === "idle") {


        return (
            <Box sx={{
                width: "100vw",
                height: "100vh",
                display: "flex",
                alignItems: "center", // for vertical centering
                justifyContent: "center" // for horizontal centering
            }}>
                <Progress Msg="Please wait ..." />
            </Box>
        );



    }


    return (

        <>

            <NavBarClient />


        </>


    )
}

export default ClientDashboard




