/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Button, Checkbox, FormControlLabel, Menu, MenuItem } from '@mui/material'
import React, { useEffect, useState } from 'react'
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
function CountriesMenu() {

    const location = useLocation();
    const navigate = useNavigate();

    const [selectedCountry, setSelectedCountry] = useState([]);
    const [anchor, setAnchor] = useState(null);
    const open = Boolean(anchor);
    const [mainData, setMainData] = useState({});
    const formReducer = useSelector((state) => state.form);
  


  

   
    const updateURLQuery = () => {
        if (anchor) {

        const searchParams = new URLSearchParams(location.search);
        if (selectedCountry.length === 0) {
            searchParams.delete('Countries');
        } else {
            searchParams.set('Countries', selectedCountry.join(','));
        }
        navigate({ search: searchParams.toString() });
    }
    };





    useEffect(() => {
        if (formReducer.loading === 'succeeded') {
            setMainData(formReducer.data);
        }

        const searchParams = new URLSearchParams(location.search);
        const countrysParam = searchParams.get('Countries');

        if (countrysParam === null) {
            setSelectedCountry([]);
        } else {
            const selected = countrysParam.split(',').map(Number);
            setSelectedCountry(selected);
        }
    }, [formReducer.loading, location.search]);









    useEffect(() => {
        updateURLQuery();
    }, [selectedCountry]);




   


    const handleClick = (event) => {


        setAnchor(event.currentTarget);
    };
    const handleClose = (e) => {

        setAnchor(null);
    };

    const handleCheckboxChange = (event) => {
        const countryId = Number(event.target.value);
        const isChecked = event.target.checked;

        if (isChecked && countryId === 0) {
            setSelectedCountry([]);
        } else {
            if (isChecked) {
                setSelectedCountry((prevSelected) => [...prevSelected, countryId]);
            } else {
                setSelectedCountry((prevSelected) => prevSelected.filter((id) => id !== countryId));
            }
        }
    };


    return (
        <Box className='Countries'>
            <Box >
                <Button
                    id="Countries"
                    aria-controls={open? 'basic-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? 'true' : undefined}
                    onClick={handleClick}

                >
                    Countries
                    {open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                </Button>
            </Box>
            <Menu
                id="basic-menu"
                anchorEl={anchor}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
                PaperProps={{
                    style: {
                        width: '85%',
                        maxWidth: '100%',
                        maxHeight: '300px',
                        left: 0,
                        right: 0,
                    },
                }}
            >
                <Box sx={{ display: 'flex', flexDirection: 'column', flexWrap: 'wrap', width: "100%", maxHeight: '300px', overflow: 'auto', overflowY: 'hidden' }}>
                    <MenuItem>
                        <FormControlLabel
                            label="All Countries"
                            control={<Checkbox checked={selectedCountry.length === 0} value={0} onChange={handleCheckboxChange} />}
                        />
                    </MenuItem>

                    {mainData.countriesList?.map((item) => (
                        <MenuItem key={item.id}>
                            <FormControlLabel
                                label={item.name}
                                control={<Checkbox checked={selectedCountry.includes(item.id)} value={item.id} onChange={handleCheckboxChange} />}
                            />
                        </MenuItem>
                    ))}
                </Box>
            </Menu>
        </Box>


    )
}

export default CountriesMenu