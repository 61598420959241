import { createAsyncThunk } from '@reduxjs/toolkit'
import { AxiosInstanceService } from '../services/axiosInstance'
import { apiRoute } from '../util/constant'

export const fileUpload = createAsyncThunk(
    'file/upload',
    async ({ files, uploadName, multipleRecords }, { rejectWithValue }) => {
        try {
            const instance = await AxiosInstanceService.getAxiosInstance();
            const response = await instance.post(`${apiRoute.upload}${uploadName}`, files, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },

            },)
            return { response: response.data, uploadName, multipleRecords };
        } catch (error) {
            // return custom error message from backend if present
            if (error.response && error.response.data.message) {
                return rejectWithValue(error.response.data.message)
            } else {
                return rejectWithValue(error.message)
            }
        }
    }
)