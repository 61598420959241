import { createTheme } from '@mui/material/styles';

export const portalTheme = (dir) => createTheme({

    palette: {


        primary: {

            main: "#010000"

        },

        secondary: {
            main: "#FEFEFE"

        },


        colorA: {
            light: '#ffa726',
            main: "#150000",
            dark: '#c1c1d7',
        },


        colorB: {
            light: '#ffa726',
            main: "#6F6F6F",
            dark: '#c1c1d7',
        },

        colorC: {
            light: '#ffa726',
            main: "#FEFEFE",
            dark: '#c1c1d7',
        },

        colorD: {
            light: '#ffa726',
            main: "#FE4545",
            dark: '#c1c1d7',
        },

        background: {
            default: '#FBFBFB',
            paper: '#FEFEFE',

        },


    },
    direction: dir,
    background: {
        default: '#fafafa',
    },
    components: {
        // Name of the component
        MuiButton: {
            styleOverrides: {
                // Name of the slot
                b1: {
                    background: "#FE4545",
                    color: "#FFFFFF !important",
                    "&:active": {
                        background: "#AC1818"
                    }
                    , "&.Mui-disabled": {
                        opacity: .7
                    },
                    '&:hover': { background: '#E11F1F' }


                },


                

                b2: {
                    background: "transparent",
                    color: "#FE4545",
                    borderRadius: "5px",
                    border: "1px solid",
                    "&:active": {
                        background: "#AC1818",
                        color: "#FFFFFF"
                    }
                    , "&.Mui-disabled": {
                        opacity: .7
                    },
                    '&:hover': {
                        background: '#E11F1F', color: "#FFFFFF"
                    }


                },




                upload: {
                    background: "transparent",
                    color: "#FE4545",
                    borderRadius: "5px",
                    border: "1px solid",
                    "&:active": {
                        background: "#AC1818",
                        color: "#FFFFFF"
                    }
                    , "&.Mui-disabled": {
                        opacity: .7
                    },
                    '&:hover': {
                        background: '#0ADB1F', color: "#FFFFFF" ,  
                         border: "1px solid",

                         borderRadius: "20px",

                    }


                },
            },
        },
        MuiTypography: {
            styleOverrides: {
                h1: {

                    fontFamily: dir === 'rtl' && 'ArabicBlack'


                },

                h2: {

                    fontFamily: dir === 'rtl' && 'ArabicBlack',


                },


                h3: {

                    fontFamily: dir === 'rtl' && 'ArabicBlack'


                },


                h4: {

                    fontFamily: dir === 'rtl' && 'ArabicBold'


                },


                h5: {

                    fontFamily: dir === 'rtl' && 'ArabicRegualr'


                },


                h6: {

                    fontFamily: dir === 'rtl' && 'ArabicRegualr'


                },

                subtitle1: {
                    fontFamily: dir === 'rtl' && 'ArabicRegualr'




                },

                subtitle2: {
                    fontFamily: dir === 'rtl' && 'ArabicRegualr'




                },


                body1: {
                    fontFamily: dir === 'rtl' && 'ArabicRegualr'




                },



                body2: {
                    fontFamily: dir === 'rtl' && 'ArabicRegualr'




                },


            }

        },
        MuiPaper: {
            styleOverrides: {
                p1: {
                    // display: 'flex',
                    alignItems: 'center',
                    marginLeft: '31px',
                    padding: 40,
                    minHeight: 172,
                    justifyContent: 'space-between',
                    backgroundColor: '#F7F7F7',
                    borderRadius: '20px 0px 0px 20px',
                    boxShadow: '0px 10px 30px #00000029',
                },
                p2: {
                    marginLeft: '31px',
                    padding: '40px 20px',
                    minHeight: 172,
                    justifyContent: 'space-between',
                    borderRadius: '20px 0px 0px 20px',
                    marginTop: 100,
                },
                p3: {
                    background: '#F8F8F8',
                    margin: '64px 49px',
                    padding: '17px'
                }
            }
        },
        MuiFormControl: {
            styleOverrides: {
                root: {
                    // width: '380px',
                    backgroundColor: '#FFFFFF',
                    // height: 55,
                    borderRadius: '10px',


                }
            }
        },
        MuiInputLabel: {
            styleOverrides: {
                root: {
                    background: '#FFFFFF',
                }
            }
        },
        MuiSelect: {
            styleOverrides: {
                root: {
                    borderRadius: '10px',
                    // width: "380px",
                    background: "#FFFFFF",

                }
            }
        },
        MuiOutlinedInput: {
            styleOverrides: {
                root: {
                    border: "unset",
                    borderRadius: '10px',
                }

            }
        },
        popoverPaper: {
            width: '100%',
            height: '100%',
            maxHeight: 'unset',
            maxWidth: 'unset',
        },
    },
});