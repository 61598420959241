
import { Box, Button } from '@mui/material';


import React, { useEffect, useState } from 'react';


import { t } from 'i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import CountriesMenu from './ContainerMenu';
import BookingMenu from './BookingMenue';
import ConsigneeMenu from './ConsigneeMenu';
import SearchOptionMenu from './SearchOptionMenu';
import MixSearch from './MixSearch';



const ContainerDropdownMenu = () => {

    const location = useLocation();
    const navigate = useNavigate();

    const formReducer = useSelector((state) => state.form);

    // eslint-disable-next-line no-unused-vars
    const [adavancedSearchPrems, setAdavancedSearchPrems] = useState(formReducer?.data?.advancedSearches || []);

    useEffect(() => {
        if (formReducer.loading === 'succeeded') {
            setAdavancedSearchPrems(formReducer?.data?.advancedSearches || []);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formReducer.loading]);




    const handelResetFilter = () => {
        const searchParams = new URLSearchParams(location.search);

        searchParams.delete('Container');
        searchParams.delete('Booking');
        searchParams.delete('Consignee');
        searchParams.delete('SearchOption');


        navigate({ search: searchParams.toString() });



    }




    return (
        <Box sx={{display:"flex",flexDirection:"column" , gap:2}}>
            <Box sx={{ pl: 2, display: "flex", gap: 2, alignItems: "start" }}>

                <CountriesMenu />
                <BookingMenu />
                <ConsigneeMenu />
                <SearchOptionMenu />




                <Button color='colorD' onClick={handelResetFilter}>
                    {t("ResetFilter")}
                </Button>





            </Box>
            <Box sx={{width:"35%", mt:1 , mb:2}}>
            <MixSearch />

            </Box>

        </Box>
    );
};

export default ContainerDropdownMenu;
