/* eslint-disable react-hooks/exhaustive-deps */
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Menu,
  MenuItem,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { initialForm } from "../../../actions/formAction";
import { resetState as resetInitialState } from "../../../slices/formInitialSlice";
import Progress from "../../Loader/Progress ";

function SearchOptionMenu() {
  const location = useLocation();
  const navigate = useNavigate();

  const [selectedSearchOption, setSelectedSearchOption] = useState([]);
  const [anchor, setAnchor] = useState(null);
  const open = Boolean(anchor);
  const [mainData, setMainData] = useState({});
  const formReducer = useSelector((state) => state.form);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(initialForm());

    return () => {
      dispatch(resetInitialState());
    };
  }, [dispatch]);

  const updateURLQuery = () => {
    if (anchor) {
      const searchParams = new URLSearchParams(location.search);
      if (selectedSearchOption.length === 0) {
        searchParams.delete("SearchOption");
      } else {
        searchParams.set("SearchOption", selectedSearchOption.join(","));
      }
      navigate({ search: searchParams.toString() });
    }
  };

  useEffect(() => {
    if (formReducer.loading === "succeeded") {
      setMainData(formReducer.data);
    }

    const searchParams = new URLSearchParams(location.search);
    const searchOptionsParam = searchParams.get("SearchOption");

    if (searchOptionsParam === null) {
      setSelectedSearchOption([]);
    } else {
      const selected = searchOptionsParam.split(",").map(Number);
      setSelectedSearchOption(selected);
    }
  }, [formReducer.loading, location.search]);

  useEffect(() => {
    updateURLQuery();
  }, [selectedSearchOption]);

  const handleClick = (event) => {
    setAnchor(event.currentTarget);
  };
  const handleClose = (e) => {
    setAnchor(null);
  };

  const handleCheckboxChange = (event) => {
    const searchOptionId = Number(event.target.value);
    const isChecked = event.target.checked;

    if (isChecked && searchOptionId === 0) {
      setSelectedSearchOption([]);
    } else {
      if (isChecked) {
        setSelectedSearchOption((prevSelected) => [
          ...prevSelected,
          searchOptionId,
        ]);
      } else {
        setSelectedSearchOption((prevSelected) =>
          prevSelected.filter((id) => id !== searchOptionId)
        );
      }
    }
  };

  if (formReducer?.loading === "pending") {
    return <Progress Msg="Options" />;
  }

  return (
    <Box className="SearchOption">
      <Box>
        <Button
          id="SearchOption"
          aria-controls={open ? "basic-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
          onClick={handleClick}
        >
          Options
          {open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
        </Button>
      </Box>
      <Menu
        id="basic-menu"
        anchorEl={anchor}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
        PaperProps={{
          style: {
            width: "85%",
            maxWidth: "100%",
            maxHeight: "300px",
            left: 0,
            right: 0,
          },
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            flexWrap: "wrap",
            width: "100%",
            maxHeight: "300px",
            overflow: "auto",
            overflowY: "hidden",
          }}
        >
          <MenuItem>
            <FormControlLabel
              label="Undefined options"
              control={
                <Checkbox
                  checked={selectedSearchOption.length === 0}
                  value={0}
                  onChange={handleCheckboxChange}
                />
              }
            />
          </MenuItem>

          {mainData.searchOptionContainerList?.map((item) => (
            <MenuItem key={item.id}>
              <FormControlLabel
                label={item.name}
                control={
                  <Checkbox
                    checked={selectedSearchOption.includes(item.id)}
                    value={item.id}
                    onChange={handleCheckboxChange}
                  />
                }
              />
            </MenuItem>
          ))}
        </Box>
      </Menu>
    </Box>
  );
}

export default SearchOptionMenu;
