// MixSearch.js
import React, { useEffect } from "react";
import TextField from "@mui/material/TextField";
import { useLocation, useNavigate } from "react-router-dom";


function MixSearch({inputValue, setInputValue}) {
    const location = useLocation();
    const navigate = useNavigate();
    const searchParams = new URLSearchParams(location.search);

   


    useEffect(() => {
        // Update URL query when inputValue changes
        if (inputValue === ""){

            updateURLQuery()
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [inputValue]);

    useEffect(() => {
        // Read from URL parameters and update inputValue on component mount
        const mixSearchParam = searchParams.get("MixSearch");
        setInputValue(mixSearchParam || "");
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location.search]);

    const updateURLQuery = () => {


        if (!inputValue){

            searchParams.delete("MixSearch");
            navigate({ search: searchParams.toString() });

            return;

        }

        if (inputValue.trim() === "") {
            // If inputValue is empty, remove 'mixSearch' parameter from URL
            searchParams.delete("MixSearch");
        } else {
            // Set 'mixSearch' parameter in URL
            searchParams.set("MixSearch", inputValue);
            searchParams.set("SortBy", "v.id");
        }
        navigate({ search: searchParams.toString() });
    };
    

    const handleInputChange = (event) => {
        setInputValue(event.target.value);
    };

    return (
        <>
            <TextField size="small"  sx={{ minWidth: "250px" }}
                variant="outlined"
                label={"Search"}
                value={inputValue}
                onChange={handleInputChange}
                onKeyDown={(event) => {
                    if (event.key === 'Enter') {
                        // Call updateURLQuery when Enter key is pressed
                        updateURLQuery();
                    }
                }}
            />
        </>


         
    );
}

export default MixSearch;
