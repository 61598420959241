import { combineReducers } from "@reduxjs/toolkit";
import userReducer from "./userSlice";
import fileReducer from "./fileSlice"
import formReducer from "./formInitialSlice"
import addVehicleReducer from "./addNewVehiclesSlice";
import vehicleInfoReducer from "./vehicleInfoSlice"
import fetchDataReducser from "./fetchDataSlice"
import vehicleCountReducser from "./vehicleCountSlice"
import manageReducer from "./manageSlice";
import userDataReduser from "./userDataSlice";
import logsSliceReducser from "./logsSlice";
import parentReducer from "./parentSlice"
import toastReducer from "./toastSlice";
import excelContainerRedUcer from "./fetchExcelContainerSlice";
import containerCountSlice from "./containerCountSlice";
import clientViewInitialSlice from "./clientViewInitialSlice"

export default combineReducers({
    user: userReducer,
    files: fileReducer,
    form: formReducer,
    add: addVehicleReducer,
    vehicleInfo: vehicleInfoReducer,
    fetchData: fetchDataReducser,
    vehicleCount: vehicleCountReducser,
    manage: manageReducer,
    userData: userDataReduser,
    logsData: logsSliceReducser,
    parentData: parentReducer,
    toast: toastReducer,
    excelContainer : excelContainerRedUcer,
    containerCount: containerCountSlice,
    clientViewInitial : clientViewInitialSlice

})