import { AppBar, Box, Menu, MenuItem, Toolbar, Typography } from '@mui/material';
import React from 'react'
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import LogoutIcon from '@mui/icons-material/Logout';
import { persistor } from '../../store/store';
import AppHeader from '../AppHeader';
import LeftNav from '../LeftNav';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { logoutUser } from '../../actions/userAction';

const NavBar = ({ children }) => {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const { user } = useSelector((state) => state.user.userInfo) || {};
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const handleLogout = () => {
        dispatch(logoutUser())
        persistor.purge();
        navigate('/login')
    }

    return (
        <>
            <Box sx={{ flexGrow: 1 }}>
                <AppBar component="nav" sx={{
                    bgcolor: 'primariy',
                }}>
                    <Toolbar sx={{
                        display: 'flex',
                        alignSelf: 'end',

                    }} >
                        <Box sx={{ display: 'flex', gap: 1 }}>
                            <Typography color="secondary" >
                                Howdy, {user?.firstName}!
                            </Typography>
                            <>
                                <AccountCircleIcon
                                    id="basic-button"
                                    aria-controls={open ? 'basic-menu' : undefined}
                                    aria-haspopup="true"
                                    aria-expanded={open ? 'true' : undefined}
                                    onClick={handleClick}
                                    sx={{
                                        marginRight: '20px'

                                    }} />
                                <Menu
                                    id="basic-menu"
                                    variant='menu'
                                    anchorEl={anchorEl}
                                    open={open}
                                    onClose={handleClose}
                                    MenuListProps={{
                                        'aria-labelledby': 'basic-button',
                                    }}
                                >
                                    {/* <MenuItem onClick={handleClose}>Profile</MenuItem> */}
                                    <MenuItem onClick={() => {
                                        navigate('/updatePassword')
                                        handleClose();
                                    }}>Change Password</MenuItem>

                                </Menu>
                            </>
                            <LogoutIcon onClick={handleLogout} />
                        </Box>
                    </Toolbar>
                </AppBar>
            </Box>
            {/* {children} */}
            <AppHeader />
            <LeftNav />

        </>

    )
}

export default NavBar;