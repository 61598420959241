/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import { isArray, } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { CircularProgress } from '@mui/material';
import { t } from 'i18next';
import { searchClient } from '../../../../actions/formAction';

function ClientMenu() {
    const location = useLocation();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [selectedClient, setSelectedClient] = useState([]);
    const [anchor, setAnchor] = useState(null);
    const open = Boolean(anchor);
    const [debouncedInputValue, setDebouncedInputValue] = useState('');
    const [input, setInput] = useState('');



    const formReducer = useSelector((state) => state.form);
    const [mainData, setMainData] = useState([]);

    useEffect(() => {


        if (formReducer.isLoadingClientData === 'succeeded') {

            const newData = formReducer.client;
            const addedList = newData.filter(item2 => !mainData.some(item1 => item1.id === item2.id));

            if (addedList) {
                setMainData(prevData => [...prevData, ...addedList]);
            }
        }

        const searchParams = new URLSearchParams(location.search);
        const ClientParam = searchParams.get('Clients');

        if (ClientParam === null) {
            setSelectedClient([]);
        } else {
            const selectedTypes = ClientParam.split(',').map(Number);
            setSelectedClient(selectedTypes);
        }
    }, [formReducer.isLoadingClientData, location.search]);

    useEffect(() => {
        updateURLQuery();
    }, [selectedClient]);

    useEffect(() => {
        const timeoutId = setTimeout(() => {
            dispatch(searchClient({ name: input }));
        }, 500);

        return () => clearTimeout(timeoutId);
    }, [debouncedInputValue, dispatch]);

    const updateURLQuery = () => {
        if (anchor) {
            const searchParams = new URLSearchParams(location.search);
            if (selectedClient.length === 0) {
                searchParams.delete('Clients');
            } else {
                searchParams.set('Clients', selectedClient.join(','));
            }
            navigate({ search: searchParams.toString() });
        }
    };

    const handleAutocompleteChange = (event, value) => {
        setSelectedClient(value.map((item) => item.id));
    };

    const handleClick = (event) => {
        setAnchor(event.currentTarget);
    };

    const handleClose = () => {
        setAnchor(null);
    };



    return (
        <Box className="Client">
            <Button
                id="Client"
                aria-controls={open ? 'basic-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                onClick={handleClick}
            >
                Client
                {open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
            </Button>

            <Menu
                id="basic-menu"
                anchorEl={anchor}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'Client',
                }}
                PaperProps={{
                    style: {
                        width: '25%',
                        maxWidth: '100%',
                        maxHeight: '300px',
                        left: 0,
                        right: 0,
                    },
                }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        flexWrap: 'wrap',
                        width: '100%',
                        maxHeight: '300px',
                        overflow: 'auto',
                        overflowY: 'hidden',
                        p: 2
                    }}
                >
                    <Autocomplete
                        multiple
                        id="client-autocomplete"
                        options={mainData}
                        value={selectedClient.map(id => isArray(mainData) ? mainData.find(item => item.id === id) || null : [])}
                        onChange={handleAutocompleteChange}
                        inputValue={input}
                        onInputChange={(event, value) => {

                            setDebouncedInputValue(value);
                        }}
                        clearText="Clear"

                        disableCloseOnSelect
                        getOptionLabel={(option) => option?.name}
                        loading={formReducer.isLoadingClientData === 'pending'}

                        renderInput={(params) => <TextField {...params} label={`#${t('Client')}`} onChange={({ target }) => setInput(target.value)} InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                                <>
                                    {formReducer.isLoadingClientData === 'pending' ? <CircularProgress color="error" size={20} /> : null}
                                    {params.InputProps.endAdornment}
                                </>
                            ),
                        }} />}








                    />

                </Box>
            </Menu>
        </Box>
    );
}

export default ClientMenu;
