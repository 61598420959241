import { createSlice } from "@reduxjs/toolkit";
import { PURGE } from "redux-persist";
import {  auctionsLocationsBuyerNumber, auctionsbooking, carModels, containers, initialForm, searchClient, searchConsignee } from "../actions/formAction";

const initialState = {
    loading: 'idle',
    hasError: false,
    errorMessage: '',
    data: {
        auctionsList: [],
        colorList: [],
        destinationList: [],
        fuelTypeList: [],
        portList: [],
        statusList: [],
        vehicleTypeList: [],
        makeList: [],
        warehouseList: [],
        clientList: [],
        titleProblemList: [],
        titleTypeList: [],
        pickupLocationList:[],
        consigneeList :[]
    },
    auctionLocationsAndBuyerNumber: {
        isLoadingAuctionDetails: 'idle',
        auctionLocationsList: [],
        buyerNumberList: []
    },
    carModels: {
        models: [],
        isLoadingCarModels: 'idle',
    },
    container: [],
    booking: [],
    consignee: [],
    client:[],
    isLoadingContainerData: 'idle',
    isLoadingBookingData: 'idle',
    isLoadingConsigneeData: 'idle',
    isLoadingClientData: 'idle',


}

const formInitialSlice = createSlice({
    name: 'form',
    initialState,
    reducers: {
        resetState: () => {

            return initialState;
        },
        addDeletedObject: (state, action) => {


            const { name, object } = action.payload


            state.data[name].push(object);
        }
    },
    extraReducers: (builder) => {
        builder.addCase(PURGE, () => {
            return initialState;
        })
            .addCase(initialForm.pending, (state) => {
                state.loading = 'pending';
                state.hasError = false
            })
            .addCase(initialForm.fulfilled, (state, action) => {
                const response = action.payload;
                state.loading = 'succeeded';
                state.hasError = false;
                state.data = response

            })
            .addCase(initialForm.rejected, (state, action) => {
                state.loading = 'failed';
                state.hasError = true;
                state.errorMessage = action.payload
            })
            .addCase(auctionsLocationsBuyerNumber.pending, (state) => {
                state.auctionLocationsAndBuyerNumber.isLoadingAuctionDetails = 'pending';
                state.hasError = false
            })
            .addCase(auctionsLocationsBuyerNumber.fulfilled, (state, action) => {
                const response = action.payload;
                state.auctionLocationsAndBuyerNumber = {
                    isLoadingAuctionDetails: 'succeeded',
                    hasError: false,
                    ...response
                }

            })
            .addCase(auctionsLocationsBuyerNumber.rejected, (state, action) => {
                state.auctionLocationsAndBuyerNumber.isLoadingAuctionDetails = 'failed';
                state.hasError = true;
                state.errorMessage = action.payload
            })
            //
            .addCase(carModels.pending, (state) => {
                state.carModels.isLoadingCarModels = 'pending';
                state.hasError = false
            })
            .addCase(carModels.fulfilled, (state, action) => {
                const response = action.payload;
                state.carModels = {
                    isLoadingCarModels: 'succeeded',
                    models: response,
                };
                state.hasError = false


            })
            .addCase(carModels.rejected, (state, action) => {
                state.carModels.isLoadingCarModels = 'failed';
                state.hasError = true;
                state.errorMessage = action.payload

            })
            //
            .addCase(containers.pending, (state) => {
                state.isLoadingContainerData = 'pending';
                state.hasError = false
            })
            .addCase(containers.fulfilled, (state, action) => {
                const response = action.payload;
                state.container = response
                state.hasError = false
                state.isLoadingContainerData = 'succeeded';
            })
            .addCase(containers.rejected, (state, action) => {
                state.isLoadingContainerData = 'failed';
                state.hasError = true;
                state.errorMessage = action.payload

            })


            .addCase(auctionsbooking.pending, (state) => {
                state.isLoadingBookingData = 'pending';
                state.hasError = false
            })
            .addCase(auctionsbooking.fulfilled, (state, action) => {
                const response = action.payload;
                state.booking = response
                state.hasError = false
                state.isLoadingBookingData = 'succeeded';
            })
            .addCase(auctionsbooking.rejected, (state, action) => {
                state.isLoadingBookingData = 'failed';
                state.hasError = true;
                state.errorMessage = action.payload

            })





            .addCase(searchConsignee.pending, (state) => {
                state.isLoadingConsigneeData = 'pending';
                state.hasError = false
            })
            .addCase(searchConsignee.fulfilled, (state, action) => {
                const response = action.payload;
                state.consignee = response
                state.hasError = false
                state.isLoadingConsigneeData = 'succeeded';
            })
            .addCase(searchConsignee.rejected, (state, action) => {
                state.isLoadingConsigneeData = 'failed';
                state.hasError = true;
                state.errorMessage = action.payload
                

            })



            .addCase(searchClient.pending, (state) => {
                state.isLoadingClientData = 'pending';
                state.hasError = false
            })
            .addCase(searchClient.fulfilled, (state, action) => {
                const response = action.payload;
                state.client = response
                state.hasError = false
                state.isLoadingClientData = 'succeeded';
            })
            .addCase(searchClient.rejected, (state, action) => {
                state.isLoadingClientData = 'failed';
                state.hasError = true;
                state.errorMessage = action.payload

            })
    }

});
export const { resetState, addDeletedObject } = formInitialSlice.actions;

export default formInitialSlice.reducer;
