/* eslint-disable react-hooks/exhaustive-deps */
import { TreeItem, TreeView } from '@mui/lab';
import { Box, Button, Checkbox, FormControlLabel, Menu, MenuItem } from '@mui/material';
import React, { useEffect, useState } from 'react'
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { useLocation, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
const renderTree = (nodes, checkedNodes, handleToggle, handleCheckboxChange) => {


    const nodeId = nodes.id || nodes.name; // If id doesn't exist, use the name as nodeId



    const labelId = `checkbox-${nodeId}`;

    const selectedObj = checkedNodes.find((node) => node.id === nodeId);

    if (selectedObj !== null) {

    }

    const isCheckedBuyerId = (id) => { return Boolean(selectedObj && selectedObj.buyerNumbers.find(element => element === id)); }

    const isCheckedAuctionLocation = (id) => { return Boolean(selectedObj && selectedObj.auctionLocationses.find(element => element === id)); }

    const isCheckedAuctionsId = Boolean(selectedObj)


    let buyerNumbers = [];
    let auctionLocationses = [];



    if (Array.isArray(nodes.buyerNumbers)) {
        buyerNumbers = nodes.buyerNumbers.map((buyer) => ({
            reId: `buyer-${buyer.id}`,
            name: buyer.name,
            id: buyer.id
        }));
    }

    if (Array.isArray(nodes.auctionLocationses)) {
        auctionLocationses = nodes.auctionLocationses.map((location) => ({
            reId: `location-${location.id}`,
            name: location.name,
            id: location.id

        }));
    }

    return (
        <TreeItem
            key={nodeId}
            nodeId={nodeId}
            label={
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <Checkbox
                        checked={isCheckedAuctionsId}
                        inputProps={{ 'aria-labelledby': labelId }}
                        onChange={() => handleCheckboxChange({ target: { value: nodeId, checked: !isCheckedAuctionsId } }, 'auction')}
                    />
                    <label id={labelId}>{nodes.name}</label>
                </div>
            }
        >
            {buyerNumbers.length > 0 && (
                <TreeItem nodeId={`buyerNumbers-${nodeId}`} label="Buyer Numbers">
                    {buyerNumbers.map((buyer) => (
                        <TreeItem
                            key={buyer.reId}
                            nodeId={buyer.reId}
                            label={
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <Checkbox
                                        checked={isCheckedBuyerId(buyer.id)}
                                        inputProps={{ 'aria-labelledby': buyer.reId }}
                                        onChange={() => handleCheckboxChange({ target: { id: nodeId, buyerId: buyer.id, checked: !isCheckedBuyerId(buyer.id) } }, 'buyerNumber')}

                                    />
                                    <label id={buyer.id}>{buyer.name}</label>
                                </div>
                            }
                        />
                    ))}
                </TreeItem>
            )}
            {auctionLocationses.length > 0 && (
                <TreeItem nodeId={`auctionLocationses-${nodeId}`} label="Auction Locations">
                    {auctionLocationses.map((location) => (
                        <TreeItem
                            key={location.reId}
                            nodeId={location.reId}
                            label={
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <Checkbox
                                        checked={isCheckedAuctionLocation(location.id)}
                                        inputProps={{ 'aria-labelledby': location.reId }}
                                        onChange={() => handleCheckboxChange({ target: { id: nodeId, auctionLocationId: location.id, checked: !isCheckedAuctionLocation(location.id) } }, 'auctionLocation')}

                                    />
                                    <label id={location.id}>{location.name}</label>
                                </div>
                            }
                        />
                    ))}
                </TreeItem>
            )}
            {Array.isArray(nodes.auction) &&
                nodes.auction.map((auctionItem) =>
                    renderTree(auctionItem, checkedNodes, handleToggle, handleCheckboxChange)
                )}
        </TreeItem>
    );
};
function AuctionsMenu() {
    const location = useLocation();
    const navigate = useNavigate();
    const formReducer = useSelector((state) => state.form);

    const [mainData, setMainData] = useState({});
    const [anchor, setAnchor] = useState(null);
    const [checkedNodes, setCheckedNodes] = useState([]);
    const [selected, setSelected] = useState([]);

    const open = Boolean(anchor);

    useEffect(() => {
        if (formReducer.loading === 'succeeded') {
            setMainData(formReducer.data);
        }

        const searchParams = new URLSearchParams(location.search);

        // Get the 'Auctions' parameter from the URL query
        const auctionsParam = searchParams.get('Auctions');

        if (auctionsParam) {
            // Parse the JSON string back into an array of objects
            const auctionsArray = JSON.parse(auctionsParam);

            // Update the 'selected' state with the parsed data
            setSelected(auctionsArray);
        }

    }, [formReducer.loading, location.search]);



    useEffect(() => {

        updateURLQuery(selected);

    }, [selected]);



    const handleCheckboxChange = (event, type) => {
        const chekedId = Number(event.target.value);
        const isChecked = event.target.checked;

        switch (type) {
            case 'auction': {


                if (isChecked && chekedId === 0) {
                    setSelected([]);
                } else {



                    if (isChecked) {
                        // Check if the id exists in selected
                        const existingIndex = selected.findIndex((item) => item.id === chekedId);

                        if (existingIndex === -1) {
                            // If the id does not exist, add a new entry with the buyerId
                            setSelected((prevSelected) => [...prevSelected, { id: chekedId, buyerNumbers: [], auctionLocationses: [] }]);
                        } else {
                            // If the id exists, update the buyerId array
                            setSelected((prevSelected) => {
                                const updatedSelected = [...prevSelected];
                                return updatedSelected;
                            });
                        }

                    } else {
                        // If the checkbox is unchecked, remove the buyerId from the selected state
                        setSelected((prevSelectedTypes) => prevSelectedTypes.filter(object => object.id !== chekedId));
                    }


                }


                break;
            }
            case 'buyerNumber': {
                const id = event.target.id;
                const buyerId = event.target.buyerId;

                if (isChecked) {
                    // Check if the id exists in selected
                    const existingIndex = selected.findIndex((item) => item.id === id);

                    if (existingIndex === -1) {
                        // If the id does not exist, add a new entry with the buyerId
                        setSelected((prevSelected) => [...prevSelected, { id, buyerNumbers: [buyerId], auctionLocationses: [] }]);
                    } else {
                        // If the id exists, update the buyerId array
                        setSelected((prevSelected) => {
                            const updatedSelected = [...prevSelected];
                            updatedSelected[existingIndex].buyerNumbers.push(buyerId);
                            return updatedSelected;
                        });
                    }

                } else {
                    // If the checkbox is unchecked, remove the buyerId from the selected state
                    setSelected((prevSelected) => {
                        const existingIndex = prevSelected.findIndex((item) => item.id === id);
                        if (existingIndex !== -1) {
                            const updatedSelected = [...prevSelected];
                            updatedSelected[existingIndex].buyerNumbers = updatedSelected[existingIndex].buyerNumbers.filter((id) => id !== buyerId);
                            return updatedSelected;
                        }
                        return prevSelected;
                    });
                }


                break;
            }


            case 'auctionLocation': {

                const id = event.target.id;
                const auctionLocationId = event.target.auctionLocationId;

                if (isChecked) {
                    // Check if the id exists in selected
                    const existingIndex = selected.findIndex((item) => item.id === id);

                    if (existingIndex === -1) {
                        // If the id does not exist, add a new entry with the buyerId
                        setSelected((prevSelected) => [...prevSelected, { id, auctionLocationses: [auctionLocationId], buyerNumbers: [] }]);
                    } else {
                        // If the id exists, update the buyerId array
                        setSelected((prevSelected) => {
                            const updatedSelected = [...prevSelected];
                            updatedSelected[existingIndex].auctionLocationses.push(auctionLocationId);
                            return updatedSelected;
                        });
                    }

                } else {
                    // If the checkbox is unchecked, remove the buyerId from the selected state
                    setSelected((prevSelected) => {
                        const existingIndex = prevSelected.findIndex((item) => item.id === id);
                        if (existingIndex !== -1) {
                            const updatedSelected = [...prevSelected];
                            updatedSelected[existingIndex].auctionLocationses = updatedSelected[existingIndex].auctionLocationses.filter((id) => id !== auctionLocationId);
                            return updatedSelected;
                        }
                        return prevSelected;
                    });
                }


                break;
            }
            default:
                break;
        };
    }





    const updateURLQuery = (selectedItems) => {

        if (anchor) {

            const searchParams = new URLSearchParams(location.search);

            // Get other query parameters if they exist
            const otherParams = {};
            for (let [key, value] of searchParams.entries()) {
                if (key !== 'Auctions') {
                    otherParams[key] = value;
                }
            }

            if (selectedItems.length === 0) {
                // If there are no selected items, remove 'Auctions' parameter
                delete otherParams['Auctions'];
            } else {
                // Convert selected items to the required format
                const auctionsObj = selectedItems.map((item) => ({
                    id: item.id,
                    buyerNumbers: item.buyerNumbers,
                    auctionLocationses: item.auctionLocationses,
                }));

                // Update the 'Auctions' parameter
                otherParams['Auctions'] = JSON.stringify(auctionsObj);
            }

            // Set the updated search params back to the URL
            const updatedSearchParams = new URLSearchParams(otherParams);
            navigate({ search: updatedSearchParams.toString() });
        }
    };







    const handleToggle = (nodeId) => {
        const currentIndex = checkedNodes.indexOf(nodeId);
        const newChecked = [...checkedNodes];

        if (currentIndex === -1) {
            newChecked.push(nodeId);
        } else {
            newChecked.splice(currentIndex, 1);
        }

        setCheckedNodes(newChecked);
    };





    const handleClick = (event) => {


        setAnchor(event.currentTarget);
    };
    const handleClose = (e) => {

        setAnchor(null);
    };


    return (
        <Box className='Auctions'>
            <Box >
                <Button
                    id="basic-button"
                    aria-controls={open ? 'basic-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? 'true' : undefined}
                    onClick={handleClick}
                >
                    Auctions
                    {open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                </Button>
            </Box>
            <Menu
                id="basic-menu"
                anchorEl={anchor}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
                PaperProps={{
                    style: {
                        width: '85%',
                        maxWidth: '100%',
                        maxHeight: '300px',
                        left: 0,
                        right: 0,
                    },
                }}
            >
                <Box sx={{ width: "100%", maxHeight: '300px' }}>
                    <MenuItem>
                        <FormControlLabel
                            label="All Auctions"
                            control={<Checkbox checked={selected.length === 0} value={0} onChange={(e) => handleCheckboxChange(e, "auction")} />}
                        />
                    </MenuItem>

                    <TreeView
                        aria-label="rich object"
                        defaultCollapseIcon={<ExpandMoreIcon />}
                        defaultExpanded={['root']}
                        defaultExpandIcon={<ChevronRightIcon />}
                        sx={{ display: 'flex', flexDirection: 'column', flexWrap: 'wrap', maxHeight: '500px', overflow: 'auto', overflowY: 'hidden' }}
                    >

                        <Box sx={{ display: "flex", flexWrap: "wrap" }}>
                            {mainData.auctionsListWithLocationsAndBuyers?.map((node) => renderTree(node, selected, handleToggle, handleCheckboxChange))}

                        </Box>


                    </TreeView>

                </Box>






            </Menu>
        </Box>
    )
}

export default AuctionsMenu