/* eslint-disable react-hooks/exhaustive-deps */
import { Box, FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import { useFormikContext } from 'formik';
import { t } from 'i18next';
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';

function Country({isAbleEdit}) {



    const formik = useFormikContext();

    const formReducser = useSelector((state) => state.form);
    const country = useSelector((state)=> state.user.userInfo?.user?.country) 


    const [mainData, setMainData] = useState({})

    useEffect(() => {
        if (formReducser.loading === 'succeeded') {
                 setMainData(formReducser.data)

            if (formReducser.data.countriesList?.length !== 0 && formik.values.country?.id === undefined) {
              formik.setFieldValue('country', formReducser.data.countriesList[0])


            }
        }

    }, [formReducser.loading])



    const disabled = isAbleEdit ? false : formik?.values?.status.id !== 1;


    return (
        <Box display='flex' justifyContent='end' alignItems='end' width="98%">
            <FormControl sx={{
                width: '280px',
            }}>

                <InputLabel>{t("country")}</InputLabel>

                <Select disabled ={country != null || disabled} 

                    value={formik.values.country?.id || ''} // Use optional chaining and nullish coalescing
                    onChange={(event) => {
                        const selectedValue = event.target.value;
                        const selectedOption = mainData.countriesList.find((item) => item.id === selectedValue);
                        formik.setFieldValue('country', selectedOption || null);
                    }}
                    name='status'
                >
                    {mainData.countriesList && mainData.countriesList.map(item =>
                        <MenuItem key={item.id} value={item.id}>{item.name}</MenuItem> // Use item.id as the value
                    )}
                </Select>
            </FormControl>
           
        </Box>
    )
}

export default Country