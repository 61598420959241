/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Typography, Modal, Button, CircularProgress, Alert } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useDispatch, useSelector } from 'react-redux';
import { fileUpload } from '../../actions/fileAction';
import { useEffect, useState } from 'react';
import { resetState } from '../../slices/fileSlice';
import { useFormikContext } from 'formik';
import { baseUrl } from '../../util/constant';
const PreviewModal = ({ open, label, handleClose, uploadName, formikName, index, accept = "image/*", disabled }) => {


  const dispatch = useDispatch();


  const formik = useFormikContext();

  const url = baseUrl + "/"



  const accessNestedProperty = (obj, path) => {
    const properties = path.split(".");
    let value = obj;
    for (const property of properties) {
      if (value !== null && value.hasOwnProperty(property)) {
        value = value[property];
      } else {
        value = undefined;
        break;
      }
    }
    return value;
  };

  const convertToDynamicFormikName = (string) => {
    const parts = string.split(".");
    const dynamicParts = parts.map((part) => {
      if (!isNaN(part)) {
        // If the part is a number, use it as an array index with square brackets
        return `[${part}]`;
      } else {
        // Otherwise, use it as a property name
        return part;
      }
    });

    return dynamicParts.join("");
  };







  const [tempFiles, setTempFiles] = useState(accessNestedProperty(formik.values, formikName));




  const [selectedFiles, setSelectedFiles] = useState([]);
  const filesObj = useSelector((state) => state.files);
  const files = filesObj.files;







  useEffect(() => {


    if (!fileUpload.isLoading && !filesObj.hasError && files.length > 0) {
      setTempFiles(() => [...tempFiles, ...files]);
      dispatch(resetState());
    }


  }, [files, filesObj, tempFiles, dispatch])






  const deleteFileById = (id) => {

    setTempFiles(tempFiles.filter(file => file.id !== id))

  }










  const imageFormats = ['.jpg', '.jpeg', '.png', '.gif'];

  const handleFileClick = () => {
    const fileInput = document.createElement('input');
    fileInput.type = 'file';
    fileInput.accept = accept;
    fileInput.multiple = true;

    fileInput.onchange = (event) => {

      setSelectedFiles(Array.from(event.target.files));
    };
    fileInput.click();

  };



  const handleSave = () => {

    formik.setFieldValue(convertToDynamicFormikName(formikName), tempFiles);
    //formik.setFieldValue(formikName, tempFiles);
    handleClose();
  }

  

  useEffect(() => {


    return () => {
      
      dispatch(resetState())
    }
  },[])

  useEffect(() => {

    handleUpload()
  }, [selectedFiles])

  const handleUpload = () => {

    if (selectedFiles.length > 0) {

      const formData = new FormData();
      selectedFiles.forEach((file) => {
        formData.append('files', file);
      });

      dispatch(
        fileUpload({
          files: formData,
          uploadName
        })
      );
      setSelectedFiles([]);
    }
  };







  return (
    <Modal open={open} onClose={handleClose}>
      <div>
        <Modal
          open={open}
          onClose={handleClose}
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >

          <Box
            className='content' sx={{
              width: '80%',
              maxHeight: '80%',
              bgcolor: 'white',
              borderRadius: '10px',
              p: 4,
              zIndex: 1000,
              display: 'flex',
              flexDirection: 'column',
            }}
          >


            {/* Header */}
            <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
              <Typography variant="h6" component="h2">
                {label}
              </Typography>
              <Box onClick={handleClose}>
                <CloseIcon />
              </Box>
            </Box>

            {filesObj.hasError && <Alert sx={{ mb: 4 }} severity="error">{filesObj.errorMessage}</Alert>}

            {/* File upload section */}
            {!disabled && (
              <Box sx={{ pb: 2, display: 'flex', justifyContent: 'flex-end' }}>
                {!filesObj.isLoading ? (
                  <Button variant='b2' onClick={handleFileClick} >
                    Choose File
                  </Button>
                ) : (
                  <CircularProgress />
                )}
              </Box>
            )}

            {/* Content */}
            <Box sx={{ flex: 1, overflow: 'auto' }}>
              <Box
                display="flex"
                alignContent="center"
                justifyContent="center"
                gap={5}
                sx={{ flexWrap: 'wrap' }}
              >
                {/* Box content */}
                {tempFiles && tempFiles.length > 0 &&
                  tempFiles?.map((item) => {
                    const extension = item.extension.toLowerCase();
                    const isImage = imageFormats.some((format) => extension.includes(format));

                    if (isImage) {
                      return (
                        <Box key={item.id} width={'200px'} position='relative' >

                          {!disabled ?

                            <Box
                              onClick={() => { deleteFileById(item.id) }}
                              sx={{
                                position: "absolute",
                                right: 0,
                                color: "red"
                              }}

                            >
                              <CloseIcon />
                            </Box> : ''



                          }





                          <a
                            href={`${url}${item.filePath}`}
                            download={item.fileName}
                            target="_blank"
                            rel="noopener noreferrer"
                            style={{ textDecoration: 'none', color: 'inherit' }}
                          >
                            <img
                              src={`${url}${item.filePath}`}
                              alt={item.fileName}
                              width={'170px'}
                              height={'170px'}
                            />
                          </a>
                          <Typography variant='body1'>{item.originalFileName}</Typography>
                        </Box>
                      );
                    } else {
                      return (
                        <Box key={item.id} sx={{ width: "200px", position: "relative" }}>
                          {!disabled ?
                            <Box
                              onClick={() => { deleteFileById(item.id) }}
                              sx={{
                                position: "absolute",
                                right: 0,
                                color: "red"
                              }}

                            >
                              <CloseIcon />
                            </Box> : ''
                          }

                          <a
                            href={`${url}${item.filePath}`}
                            download={item.fileName}
                            target="_blank"
                            rel="noopener noreferrer"
                            style={{ textDecoration: 'none', color: 'inherit' }}
                          >
                            <Box
                              sx={{
                                width: '170px',
                                height: '170px',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                borderStyle: 'ridge',
                              }}
                            >
                              <Typography variant='h3'>{item.extension.substring(1).toUpperCase()}</Typography>
                            </Box>
                          </a>
                          <Typography variant='body1'>{item.originalFileName}</Typography>
                        </Box>
                      );
                    }
                  })}
              </Box>
            </Box>


            {/* Save Button */}


            {!disabled ?
              <Box sx={{ pt: 4, display: 'flex', justifyContent: 'flex-end', position: 'sticky', bottom: 0, zIndex: 1 }}>
                <Button variant='b1' disabled={filesObj.isLoading} sx={{ width: "10%" }} onClick={handleSave}>
                  SAVE
                </Button>
              </Box>
              : ''}

          </Box>

        </Modal>
      </div>
    </Modal>
  );
};




export default PreviewModal;


