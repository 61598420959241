import { createSlice } from "@reduxjs/toolkit";
import { PURGE } from "redux-persist";
import { getContainerCount } from "../actions/getContainerCountAction";

const initialState = {
    Object:null,
    loading : 'idle',
    hasError: false,
    errorMessage: ''
}
const containerCountSlice = createSlice({
    name: 'containerCount',
    initialState,
    reducers: {
         resetState: () => {
            return initialState;
        }

    },
    extraReducers: (builder) => {
        builder.addCase(PURGE, () => {
            return initialState;
        })
            .addCase(getContainerCount.pending, (state) => {
                state.loading = 'pending';
                state.hasError = false
            })
            .addCase(getContainerCount.fulfilled, (state, action) => {
                state.loading = 'succeeded';
                state.hasError = false;
                state.Object = action.payload ;
                state.errorMessage = ""


            })
            .addCase(getContainerCount.rejected, (state, action) => {
                state.loading = 'failed';
                state.hasError = true;
                state.errorMessage = action.payload
            })
    }
});
export const { deleteFileById , resetState } = containerCountSlice.actions;

export default containerCountSlice.reducer;