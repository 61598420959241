/* eslint-disable react-hooks/exhaustive-deps */
import { Autocomplete, Box, Button, FormControl, FormHelperText, IconButton, InputAdornment, InputLabel,OutlinedInput, Paper,  TextField } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {  useNavigate, useSearchParams } from 'react-router-dom';
import PageHeaderManage from '../PageHeader/PageHeaderManage';
import { t } from 'i18next';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { addManageRefrsh, updateManageByIdRefrsh } from '../../actions/mangeAction';
import { resetSelectedUser, resetState } from '../../slices/manageSlice';
import { showErrorToast } from '../../slices/toastSlice';
import Loader from '../Loader';

const AddUser = () => {
    const [showPassword, setShowPassword] = useState(false);
    const handleClickShowPassword = () => setShowPassword((show) => !show);

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };
    const dispatch = useDispatch();
    const [searchParams] = useSearchParams();
    const navigate = useNavigate()
    const id = parseInt(searchParams.get('id'));
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const selectedUser = useSelector((state) => state.manage.selectedUser) || {};



    
    const { updated, added, hasError, errorMessage, loading } = useSelector((state) => state.manage) || {};
    const { roleList, countriesList } = useSelector((state) => state.form.data) || {};
    const [availableRoles, setAvailableRoles] = useState(roleList);
    useEffect(() => {
        if (Object.values(selectedUser).length !== 0) {
            const selectedRoleIds = selectedUser?.roles?.map(role => role.id);
            const remainingRoles = roleList.filter(role => !selectedRoleIds?.includes(role.id));
            setAvailableRoles(remainingRoles);
        }
    }, [roleList, selectedUser])

    const validationSchema = yup.object().shape({
        userName: yup.string().required('User name is required'),
        firstName: yup.string().required('First name is required'),
        lastName: yup.string().required('Last name is required'),
        email: yup.string().nullable().email('Invalid email'),
        roles: yup.array().min(1, 'Please select at least one role'),
        password: yup
            .string()
            .required('Password is required')
            .min(8, 'Password must be at least 8 characters long')
            .matches(
                // eslint-disable-next-line no-useless-escape
                /^(?=.*[!@#$%^&*()_+{}\[\]:;<>,.?~\\/-]).*$/,
                'Password must contain at least one special character'
            ),
        country: yup.object().shape({
            id: yup.string().required('Please select a country'),
            name: yup.string().required('Please select a country'),
        }),


    });
    const validationSchemaWithoutPassword = yup.object().shape({
        userName: yup.string().required('User name is required'),
        firstName: yup.string().required('First name is required'),
        lastName: yup.string().required('Last name is required'),
        email: yup.string().nullable().email('Invalid email'),
        roles: yup.array().min(1, 'Please select at least one role'),
        country: yup.object().shape({
            id: yup.string().required('Please select a country'),
            name: yup.string().required('Please select a country'),
            deleted: yup.string(), // or yup.boolean() if it's a boolean
        }),


    });
    const formik = useFormik({
        initialValues: {
            userName: selectedUser.userName || '',
            firstName: selectedUser.firstName || '',
            lastName: selectedUser.lastName || '',
            email: selectedUser.email || null,
            password: '',
            roles: selectedUser.roles || [],
            country: selectedUser.country || { id: 'all', name: "All" },


        },
        validationSchema: !id ? validationSchema : validationSchemaWithoutPassword,
        onSubmit: (values) => handleSaveUpdate(values),
        enableReinitialize: true,
    });
    const handleSaveUpdate = (data) => {
        const payload = {
            ...data,
            roles: data.roles.map(item => item.name),
            country: data.country.name === "All" ? null : data.country
        }
        if (id) {
            dispatch(updateManageByIdRefrsh({ mode: 'user', id, data: payload }));
            return;
        }
        dispatch(addManageRefrsh({ mode: 'user', data: payload }));

    }
    useEffect(() => {
        // eslint-disable-next-line no-mixed-operators
        if (updated || added && !hasError) {

            navigate('/user/userList')
        }

        return () => {
            dispatch(resetState());
            dispatch(resetSelectedUser());
        }

    }, [updated, added, hasError]);

    const handleKeyDown = (event, submitForm) => {
        if (event.key === 'Enter') {
            event.preventDefault();
            submitForm();
        }
    };

    if (hasError) {
        dispatch(showErrorToast(errorMessage));

    }
    if (loading === 'pending') {
        return (
            <Loader />
        )
    }

    return (
        <>
                    <div onKeyDown={(e) => handleKeyDown(e, formik.submitForm)}>

            <Paper variant='p1'>
                <PageHeaderManage title={t('user').toUpperCase()} />

                <Box display='grid' gridTemplateColumns={`${!id ? "repeat(4, 1fr)" : "repeat(3, 1fr)"}`} gap={"20px"} >
                    <TextField
                        label="User name"
                        name="userName"
                        variant="outlined"
                        value={formik.values.userName}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={formik.touched.userName && Boolean(formik.errors.userName)}
                        helperText={formik.touched.userName && formik.errors.userName}
                    />
                    {!id && (
                        <FormControl variant="outlined">
                            <InputLabel htmlFor="password">Password</InputLabel>
                            <OutlinedInput
                                id="password"
                                type={showPassword ? 'text' : 'password'}
                                name="password"
                                value={formik.values.password}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                error={formik.touched.password && Boolean(formik.errors.password)}
                                endAdornment={
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={handleClickShowPassword}
                                            onMouseDown={handleMouseDownPassword}
                                            edge="end"
                                        >
                                            {showPassword ? <VisibilityOff /> : <Visibility />}
                                        </IconButton>
                                    </InputAdornment>
                                }
                                label="Password"
                            />
                            <FormHelperText variant='filled' sx={{
                                color: 'red'
                            }}>{formik.touched.password && formik.errors.password}</FormHelperText>

                        </FormControl>
                    )}
                    <TextField
                        label="First name"
                        variant="outlined"
                        name="firstName"
                        value={formik.values.firstName}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={formik.touched.firstName && Boolean(formik.errors.firstName)}
                        helperText={formik.touched.firstName && formik.errors.firstName}
                    />
                    <TextField
                        label="Last name"
                        name="lastName"
                        variant="outlined"
                        value={formik.values.lastName}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={formik.touched.lastName && Boolean(formik.errors.lastName)}
                        helperText={formik.touched.lastName && formik.errors.lastName}
                    />

                </Box>
                <Box display='grid' gridTemplateColumns={"repeat(4, 1fr)"} marginY={"40px"} gap={"20px"} >

                    <Autocomplete
                        options={[{ id: 'all', name: "All" }, ...countriesList]}
                        getOptionLabel={(option) => option?.name}
                        filterSelectedOptions
                        onChange={(event, value) => {
                            formik.setFieldValue('country', value)
                        }}
                        value={formik.values.country}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                variant="outlined"
                                label="Country"
                                name='country'
                                error={formik.touched.country && Boolean(formik.errors.country)}
                                helperText={formik.touched.country && formik.errors.country}

                            // placeholder="Favorites"
                            />
                        )}
                        onBlur={formik.handleBlur}
                        error={formik.touched.roles && Boolean(formik.errors.roles)}
                    />
                    <TextField
                        label="Email"
                        variant="outlined"
                        name="email"
                        value={formik.values.email}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={formik.touched.email && Boolean(formik.errors.email)}
                        helperText={formik.touched.email && formik.errors.email}
                    />

                    <Autocomplete
                        multiple
                        options={availableRoles}
                        getOptionLabel={(option) => option?.name}
                        filterSelectedOptions
                        onChange={(event, value) => {
                            formik.setFieldValue('roles', value)
                        }}
                        value={formik.values.roles}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                variant="outlined"
                                label="Roles"
                                name='roles'
                                error={formik.touched.roles && Boolean(formik.errors.roles)}
                                helperText={formik.touched.roles && formik.errors.roles}

                            // placeholder="Favorites"
                            />
                        )}
                        onBlur={formik.handleBlur}
                        error={formik.touched.roles && Boolean(formik.errors.roles)}
                    />

                    <Box display='grid' gridTemplateColumns={"repeat(2, 1fr)"} alignItems='center'>
                        <Button variant="b2" sx={{
                            marginInline: '20px'
                        }} onClick={() => {
                            navigate('/user/userList');
                            dispatch(resetSelectedUser())
                        }} >Cancel</Button>
                        <Button variant="b1" disabled={Object.keys(formik.errors).length >= 1} onClick={formik.handleSubmit}>Save</Button>

                    </Box>
                </Box>
            </Paper>
            </div>
        </>
    )
}

export default AddUser