/* eslint-disable react-hooks/exhaustive-deps */

import ArrowCircleRightOutlinedIcon from '@mui/icons-material/ArrowCircleRightOutlined';
import { Box, Button, Card, CardActions, CardContent, Paper, Typography } from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import VehiclesTable from './Components/Tables/VehiclesTable';
import MegaMenu from './Components/searchOptionComponents/DropdownMenu';
import { useDispatch, useSelector } from 'react-redux';
import { initialForm } from '../../actions/formAction';
import Loader from '../../components/Loader';
import { t } from 'i18next';
import { downloadExcel, getFetchData } from '../../actions/fetchDataAction';
import { useLocation, useNavigate } from 'react-router-dom';
import { getVehicleCount } from '../../actions/getVehicleCountAction';
import { resetState as resetInitialState } from '../../slices/formInitialSlice';
import { resetState } from '../../slices/vehicleCountSlice';
import AnimatedNumbers from "react-animated-numbers";
import React from 'react';
import Progress from '../../components/Loader/Progress ';
import CountryMenu from './Components/searchOptionComponents/CountryMenu'
import CountVehicleView from '../Client/CountVehicleView';

const Dashboard = () => {


    const dispatch = useDispatch();
    const location = useLocation();
    const navigate = useNavigate();
    const dataGridRef = useRef(null);
    const dataGridPaperRef = useRef(null);
    const formReducer = useSelector((state) => state.form);
    const vehicleCountReducer = useSelector((state) => state.vehicleCount);
    const searchParams = new URLSearchParams(location.search);
    const [anchor] = useState(null);

    const fetchData = useSelector((state) => state.fetchData);
    const country = useSelector((state) => state.user.userInfo?.user?.country)
    const [countries,setCountries] = useState(country?.id || null)

    useEffect(() => {
        dispatch(initialForm())

        

        return () => {

            dispatch(resetInitialState())
            dispatch(resetState())

        }

    }, [dispatch])

  


    useEffect(() => {

        if (country) {
            searchParams.set('Countries', country.id);
            navigate({ search: searchParams.toString() });
            if (countries !==country ){
                setCountries(country.id)
                dispatch(getVehicleCount({Countries: country.id}))
            } 

        } else{


            if ((searchParams.get("Countries") !==countries) ||  countries ===null ){
                dispatch(getVehicleCount({Countries: searchParams.get("Countries")}))
                setCountries(searchParams.get("Countries"))

            }

        }


    },[searchParams.get("Countries") , countries])




    useEffect(() => {



        dispatch(getFetchData({ query: searchParams.toString() }))


    }, [searchParams.get("PageSize"), searchParams.get("PageNumber"), searchParams.get("SortOrder"), searchParams.get("SortBy"),searchParams.get("MixSearch"), countries])




    const handlingFetchData = () => {

        dispatch(getFetchData({ query: searchParams.toString() }))
        if (dataGridPaperRef.current) {
            dataGridPaperRef.current.scrollIntoView({
                behavior: 'smooth',
                block: 'start',
            });
        }


    }


    const handleCardClick = (name, id) => {
        // Get the current query parameters
        const searchParams = new URLSearchParams(location.search);
        const pageSize = searchParams.get('PageSize') || null;

        // Set the 'name' parameter to the given id
        if (name === 'Status') {
            searchParams.set('Status', id);
            if (pageSize === null) {
                navigate(`/?Status=${id}&PageSize=5`)
            } else {
                navigate(`/?Status=${id}`)
            }

        } else {
            if (pageSize === null) {
                navigate(`/?SearchOption=${id}&PageSize=5`)
            } else {
                navigate(`/?SearchOption=${id}`)
            }

        }


        if (dataGridPaperRef.current) {
            dataGridPaperRef.current.scrollIntoView({
                behavior: 'smooth',
                block: 'start',
            });
        }

    };


    if (formReducer.loading === 'pending' || formReducer.loading === 'idle' || vehicleCountReducer.loading === 'pending' || vehicleCountReducer.loading === 'idle') {
        return <Loader />
    }




    return (
        <>


            <Box sx={{ display: !country? "flex" : 'none', flexDirection: "row", alignItems: "center", justifyContent: "end", pr: 2 }}>

                <CountryMenu />

            </Box>

            <Paper
                sx={{
                    p: 2,
                    minHeight: "200px",
                    flexGrow: 1,

                }}
                anchorEl={anchor}



            >


                {vehicleCountReducer.loading === 'pending' || vehicleCountReducer.loading === 'idle' ? <Loader /> :

                    <Box>



                        <Box sx={{ display: 'flex', marginTop: '20px', justifyContent: 'center', flexWrap: 'wrap', gap: '20px' }}>


                            {vehicleCountReducer.Object?.map((item, index) => (
                                <Box
                                    key={index}
                                    sx={{
                                        width: "170px",
                                        borderTop: 8,
                                        borderTopColor: item.color
                                    }}
                                    borderRadius={2}
                                >
                                    <Card>
                                        <CardContent>
                                            <Typography variant='h4' component={'div'}>

                                                <AnimatedNumbers
                                                    animateToNumber={item.count}
                                                    fontStyle={{ fontSize: 32 }}
                                                    configs={(number, index) => {
                                                        return { mass: 1, tension: 230 * (index + 1), friction: 50 };
                                                    }}
                                                />
                                            </Typography>
                                            <Typography>{item.name}</Typography>
                                        </CardContent>
                                        <CardActions onClick={() => handleCardClick(item.type, item.id)} sx={{ display: 'flex', justifyContent: 'end', cursor: 'pointer' }}>
                                            <ArrowCircleRightOutlinedIcon />
                                        </CardActions>
                                    </Card>
                                </Box>
                            ))}

                        </Box>
                    </Box>





                }
            </Paper>

            <Paper
                ref={dataGridPaperRef}

                sx={{
                    p: 2,
                    minHeight: "200px",
                    flexGrow: 1,
                    marginTop: '20px'
                }}
            >
                <Box sx={{mb:2, display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "start" }}>
                    <MegaMenu />
                    <Box sx={{ display: "flex", gap: 2, flexWrap:"wrap" , justifyContent:"end" }}>
                        <Button onClick={() => handlingFetchData()} variant='b2' sx={{ minWidth: '200px' }}>{t('FetchData')} </Button>

                        {fetchData.excelObject?.loading === 'pending' ? <Progress Msg='Generate As Excel' /> :
                            <Button variant='b2' onClick={() => dispatch(downloadExcel({ query: searchParams.toString() }))} sx={{ minWidth: '200px' }}>{t('Generate As Excel')}</Button>
                        }
                        

                        {fetchData.loading === 'pending' ?"":
                     <CountVehicleView animate={false} count={fetchData.Object.totalElements} /> 
                    }


                    </Box>
                </Box>
                {fetchData.loading === 'pending' ? <Progress Msg='Lodding ...' /> :
                    <VehiclesTable ref={dataGridRef} data={{ content: [] }} />
                }
            </Paper>

        </>
    )
}

export default Dashboard;