import { createAsyncThunk } from '@reduxjs/toolkit'
import { AxiosInstanceService } from '../services/axiosInstance'
import { apiRoute } from '../util/constant'

export const loginUser = createAsyncThunk(
    'user/login',
    async ({ userName, password ,accountType }, { rejectWithValue }) => {
        try {
            const instance = await AxiosInstanceService.getAxiosInstance();
            const response = await instance.post(`${apiRoute.login}/${accountType}`, {
                userName,
                password
            })
            return response.data;

        } catch (error) {
            // return custom error message from backend if present
            if (error.response && error.response.data.message) {
                return rejectWithValue(error.response.data.message)
            } else {
                return rejectWithValue(error.message)
            }
        }
    }
)

///api/account/logout

export const logoutUser = createAsyncThunk(
    'user/logout',
    async (_, { rejectWithValue }) => {
        try {
            const instance = await AxiosInstanceService.getAxiosInstance();
            const response = await instance.get(apiRoute.logout)
            return response.data;

        } catch (error) {
            // return custom error message from backend if present
            if (error.response && error.response.data.message) {
                return rejectWithValue(error.response.data.message)
            } else {
                return rejectWithValue(error.message)
            }
        }
    }
)


export const updatePassword = createAsyncThunk(
    'user/updatePassword',
    async ({ oldPassword, newPassword }, { rejectWithValue }) => {
        try {
            const instance = await AxiosInstanceService.getAxiosInstance();
            const response = await instance.put(apiRoute.changeAccountPassword, {
                oldPassword,
                newPassword
            })
            return response.data;

        } catch (error) {
            // return custom error message from backend if present
            if (error.response && error.response.data.message) {
                return rejectWithValue(error.response.data.message)
            } else {
                return rejectWithValue(error.message)
            }
        }
    }
)



export const updatePasswordClient = createAsyncThunk(
    'user/updatePasswordClient',
    async ({ oldPassword, newPassword }, { rejectWithValue }) => {
        try {
            const instance = await AxiosInstanceService.getAxiosInstance();
            const response = await instance.put(apiRoute.changeAccountPasswordClient, {
                oldPassword,
                newPassword
            })
            return response.data;

        } catch (error) {
            // return custom error message from backend if present
            if (error.response && error.response.data.message) {
                return rejectWithValue(error.response.data.message)
            } else {
                return rejectWithValue(error.message)
            }
        }
    }
)