import { Divider, Typography } from "@mui/material";


const PageHeader = ({ title, width }) => {
    return (
        <div style={{
            display: "flex",
            alignItems: 'center',
            marginBottom: 20

        }}>
            <div style={{ backgroundColor: 'red', width: 11, height: 24, marginRight: 29 }} />
            <Typography variant="p" component="p" fontWeight='bolder' >
                {title}
            </Typography>

            <Divider sx={{
                width: width || '82%',
                height: 10,
                marginLeft: '5px',
            }}

            />

        </div>
    );
}

export default PageHeader