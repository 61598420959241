/* eslint-disable react-hooks/exhaustive-deps */
import { Alert, Box, Button, Card, CardContent,  FormControl,  InputLabel, MenuItem, Select, TextField } from '@mui/material';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { loginUser } from '../../actions/userAction';
import Logo from "../../assets/images/logo/logo.png";
import imageBackground from "../../assets/images/home/background.jpg";

import "./login.css";
import React from 'react';
import { persistor } from '../../store/store';

function Login() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const {  success, error } = useSelector((state) => state.user);
    const [userName, setUserName] = useState('');
    const [password, setPassword] = useState('');

    const handleLogin = (e) => {
        e.preventDefault();
        dispatch(loginUser({ userName, password ,accountType }));
    }

    const [accountType,setAccountType] = useState("admin")


    useEffect(() => {
        // Check if there's an account type in the URL search parameters
        const searchParams = new URLSearchParams(window.location.search);
        const accountTypeFromUrl = searchParams.get('accountType');

        // Use the account type from URL if present, otherwise use the one from localStorage
        const initialAccountType = accountTypeFromUrl || localStorage.getItem('accountType') || 'admin';
        localStorage.setItem('accountType', initialAccountType);

        setAccountType(initialAccountType);
    }, []);




 const onChangeAccountType = (e) => {
        const newAccountType = e.target.value;
        setAccountType(newAccountType);
        localStorage.setItem('accountType', newAccountType);

        // Optional: Update the URL search parameter
        const searchParams = new URLSearchParams(window.location.search);
        searchParams.set('accountType', newAccountType);
        window.history.replaceState(null, null, "?" + searchParams.toString());
    };

    useEffect(() => {
        if (success)
            navigate('/')
    }, [success])

    return (

        <Box component="div" sx={{ height: '100vh', width:"100%", backgroundImage: `url(${imageBackground})`, top: 0, right: 0, left: 0, bottom: 0, backgroundSize: "cover", backgroundPosition: "center", backgroundRepeat: "no-repeat", zIndex: -1 ,position: 'fixed',}}>


            <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "start", alignItems: "center", height: "100vh", pt: 10, }}>
                <img src={Logo} alt='logo' className='logo-image' style={{ width: "150px", height: "350px" }} />

                <Card sx={{ width: { sm: "600px", xs: "90%" } }}>
                    <CardContent  >

                        <Box sx={{ p: 4 }}>


                            <FormControl fullWidth variant="outlined" size="medium">
                                <InputLabel id="status-filter-label">Account Type</InputLabel>
                                <Select
                                    labelId="status-filter-label"
                                    id="status-filter"
                                    label="Status"
                                    value={accountType}
                                    onChange={onChangeAccountType}
                                    
                                >
                                    <MenuItem value="admin">Admin</MenuItem>
                                    <MenuItem value="client">Client</MenuItem>
                                    {/* Add more status options here */}
                                </Select>
                            </FormControl>


                            <form onSubmit={handleLogin}>
                                <TextField
                                    label="User name"
                                    variant="outlined"
                                    onChange={(e) => setUserName(e.target.value)}
                                    error={!!error}
                                    fullWidth
                                    margin="normal"
                                />
                                <TextField
                                    type='password'
                                    label="Password"
                                    variant="outlined"
                                    onChange={(e) => setPassword(e.target.value)}
                                    error={!!error}
                                    fullWidth
                                    margin="normal"
                                />
                                {error && (
                                    <Alert
                                        onClose={() => { persistor.purge() }}
                                        sx={{ margin: "20px 0" }}
                                        variant="filled"
                                        severity="error"
                                    >
                                        User Name Or Password Incorrect
                                    </Alert>
                                )}
                                <Box display='flex' flexDirection='column' alignItems='center'>
                                    <Button type="submit" variant="b1" sx={{ width: '100%', mt: 2 }}>
                                        Login
                                    </Button>
                                </Box>
                            </form>
                        </Box>
                    </CardContent>
                </Card>

            </Box>
        </Box>

    )
}

export default Login;
