import { createAsyncThunk } from "@reduxjs/toolkit";
import { apiRoute } from "../util/constant";
import { AxiosInstanceService } from "../services/axiosInstance";
import { resetState } from "../slices/manageSlice";

export const getMangeData = createAsyncThunk(
    'manage/data',
    async ({ mode, query }, { rejectWithValue }) => {
        try {
            const instance = await AxiosInstanceService.getAxiosInstance();
            const response = await instance.get(`${apiRoute.getManage}${mode}/page?${query}`)
            return response.data;
        } catch (error) {
            // return custom error message from backend if present
            if (error.response && error.response.data.message) {
                return rejectWithValue(error.response.data.message)
            } else {
                return rejectWithValue(error.message)
            }
        }
    }
);




export const getMangeDataById = createAsyncThunk(
    'manage/dataById',
    async ({ mode, id }, { rejectWithValue }) => {
        try {
            const instance = await AxiosInstanceService.getAxiosInstance();
            const response = await instance.get(`${apiRoute.getManage}${mode}/${id}`)
            return response.data;
        } catch (error) {
            // return custom error message from backend if present
            if (error.response && error.response.data.message) {
                return rejectWithValue(error.response.data.message)
            } else {
                return rejectWithValue(error.message)
            }
        }
    }
);










export const updateManageById = createAsyncThunk(
    'manage/edit',
    async ({ mode, id, data }, { rejectWithValue }) => {
        try {
            const instance = await AxiosInstanceService.getAxiosInstance();
            const response = await instance.put(`${apiRoute.updateDeleteAddManage}${mode}/${id}`, {
                ...data
            })
            return response.data;
        } catch (error) {
            // return custom error message from backend if present
            if (error.response && error.response.data.message) {
                return rejectWithValue(error.response.data.message)
            } else {
                return rejectWithValue(error.message)
            }
        }
    }
);

export const updatePasswordById = createAsyncThunk(
    'manage/updatePassword',
    async ({ mode, id, data }, { rejectWithValue }) => {
        try {
            const instance = await AxiosInstanceService.getAxiosInstance();
            const response = await instance.put(`${apiRoute.updateDeleteAddManage}${mode}/changePassword/${id}`, {
                ...data
            })
            return response.data;
        } catch (error) {
            // return custom error message from backend if present
            if (error.response && error.response.data.message) {
                return rejectWithValue(error.response.data.message)
            } else {
                return rejectWithValue(error.message)
            }
        }
    }
);

export const updatePasswordByIdRefresh = (params) => async (dispatch) => {
    const mode = params.mode;
    const id = params.id
    const data = params.data

    await dispatch(resetState());
    await dispatch(updatePasswordById({ mode, id, data }))
}






export const updateManageByIdRefrsh = (params) => async (dispatch) => {
    const mode = params.mode;
    const id = params.id
    const data = params.data

    await dispatch(resetState());
    await dispatch(updateManageById({ mode, id, data }))
}






export const setDeliveredByIdRefrsh = (params) => async (dispatch) => {
    const mode = params.mode;
    const id = params.id
    const query = params.query;
    const deliveredDate = params.deliveredDate;
    await dispatch(setDelivered({  id , deliveredDate}))
    return await dispatch(getMangeData({ mode, query }))


}



export const deleteManageByIdRefrsh = (params) => async (dispatch) => {
    const mode = params.mode;
    const id = params.id
    const query = params.query;
    const moveId = params.moveId;
    await dispatch(deleteManageById({ mode, id , moveId}))
    return await dispatch(getMangeData({ mode, query }))


}





export const addManageRefrsh = (params) => async (dispatch) => {
    const mode = params.mode;
    const parentId = params.parentId;
    const data = params.data
    await dispatch(resetState());
    await dispatch(addManage({ mode, parentId, data }))

}



export const setDelivered = createAsyncThunk(
    'manage/setDelivered',
    async ({ id, deliveredDate }, { rejectWithValue }) => {
        try {

            const instance = await AxiosInstanceService.getAxiosInstance();
            // let response = null;
                const response = await instance.put(`${apiRoute.updateDeleteAddManage}container/delivered/${id}`, {
                    
                    delivered:deliveredDate
                })
                return response.data;
            

        } catch (error) {
            // return custom error message from backend if present
            if (error.response && error.response.data.message) {
                return rejectWithValue(error.response.data.message)
            } else {
                return rejectWithValue(error.message)
            }
        }
    }
);









export const deleteManageById = createAsyncThunk(
    'manage/delete',
    async ({ mode, id, moveId = null }, { rejectWithValue }) => { // set default value of moveId to null
        try {
            let url = `${apiRoute.updateDeleteAddManage}${mode}/${id}`;

            // If moveId is not null, append it to the url
            if (moveId !== null) {
                url += `/${moveId}`;
            }

            const instance = await AxiosInstanceService.getAxiosInstance();
            const response = await instance.delete(url);
            return response.data;
        } catch (error) {
            // Return custom error message from backend if present
            if (error.response && error.response.data.message) {
                return rejectWithValue(error.response.data.message);
            } else {
                return rejectWithValue(error.message);
            }
        }
    }
);








export const addManage = createAsyncThunk(
    'manage/add',
    async ({ mode, parentId, data }, { rejectWithValue }) => {
        try {

            const instance = await AxiosInstanceService.getAxiosInstance();
            // let response = null;
            if (parentId) {
                const response = await instance.post(`${apiRoute.updateDeleteAddManage}${mode}?parentId=${parentId}`, {
                    ...data
                })
                return response.data;
            }
            const response = await instance.post(`${apiRoute.updateDeleteAddManage}${mode}`, {
                ...data

            })
            return response.data;

        } catch (error) {
            // return custom error message from backend if present
            if (error.response && error.response.data.message) {
                return rejectWithValue(error.response.data.message)
            } else {
                return rejectWithValue(error.message)
            }
        }
    }
);


// need to modify this one to make it general
// export const generalHandler = createAsyncThunk(
//     'color/general',
//     async ({ method, apiUrl, payload, withBody }, { rejectWithValue }) => {
//         try {
//             const instance = await AxiosInstanceService.getAxiosInstance();
//             let responseData;
//             if (withBody) {
//                 responseData = await instance.method(`${apiUrl}?${query}`, {
//                     ...payload
//                 })
//             }else{
//                 responseData = await instance.method(`${apiUrl}?${query}`)
//             }
//             // const response = await instance.method(`${apiUrl}?${query}`)
//             return responseData.data;
//         } catch (error) {
//             // return custom error message from backend if present
//             if (error.response && error.response.data.message) {
//                 return rejectWithValue(error.response.data.message)
//             } else {
//                 return rejectWithValue(error.message)
//             }
//         }
//     }
// );