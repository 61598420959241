/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Button, Checkbox, Divider, FormControl, FormControlLabel, FormHelperText, FormLabel, InputAdornment, InputLabel, MenuItem, Radio, RadioGroup, Select, TextField, Typography } from "@mui/material"
import PageHeader from "../../../components/PageHeader/PageHeader"
import CustomDatePicker from "../../../components/CustomDatePicker/CustomDatePicker"

import { t } from "i18next"
import FileUpload from "../../../components/FileUpload"
import { Fragment, useEffect, useState } from "react"
import DeleteIcon from '@mui/icons-material/Delete';
import { useSelector } from "react-redux"
import { useFormikContext } from "formik"

const TowingInfo = ({isAbleEdit}) => {

    const formik = useFormikContext();


    const formReducser = useSelector((state) => state.form);


    const [mainData, setMainData] = useState({})

    useEffect(() => {
        if (formReducser.loading === 'succeeded') {
            setMainData(formReducser.data)
        }

    }, [formReducser])


    const getTowingMax = () => {

        const selectedValue = formik.values.pickupLocation;
        const selectedOption = mainData.pickupLocationList?.find((item) => item.name === selectedValue);

        if (selectedOption) {

            return selectedOption.towingMax
        } else {

            return 0
        }
    }


    useEffect(() => {

        if (formik?.values?.dispatchInformation.length === 0 &&  formik?.values?.status.id === 6 ){
            handleDuplicateRecord();
        }


    }, [formik.values])


    const disabled = isAbleEdit ? false : formik?.values?.status.id !== 6;


    const initialRecord = {

        id: 0,
        carrierName: '',
        towingPrice: 0,
        paid: false,
        extraFeesIfOffsite: false,
        extraAmount: 0,
        towingMax: getTowingMax(),
        driverPictures: [],
        receiptFile: [],
        pickUpDate: new Date().toISOString().substr(0, 10),
        deliveryDateToWH: new Date().toISOString().substr(0, 10),
        deliveryDateToWarehouse: null,
        vehicleType: null

    }

    const handleDuplicateRecord = () => {

        formik.setFieldValue("towingInformation", [...formik.values.towingInformation, initialRecord])
    };
    const handleDeleteRecord = (index) => {
        const updatedRecords = [...formik.values.towingInformation];
        updatedRecords.splice(index, 1);
        formik.setFieldValue("towingInformation", updatedRecords);

    }

    return (
        <>
            <PageHeader title={t('towingInformation')} />
            <Box display="grid" margin="64px 49px" justifyContent='end'>
                <Button disabled={disabled} variant='b2' sx={{ marginRight: '20px', borderRadius: '10px' }} onClick={handleDuplicateRecord}>Add New Record</Button>
            </Box>
            {formik.values.towingInformation.map((item, index) => {
                return (
                    <Fragment key={index}>

                        <Box sx={{ marginBottom: 10 }} >


                            <Box display="grid" margin="64px 49px" gridTemplateColumns="repeat(4, 1fr)" gap="30px">




                                <FormControl disabled={disabled}  >
                                    <InputLabel>{t('vehicleType')}</InputLabel>
                                    <Select





                                        value={formik.values.towingInformation[index].vehicleType?.id || ''} // Use optional chaining and nullish coalescing



                                        onChange={(event) => {
                                            const selectedValue = event.target.value;
                                            const selectedOption = mainData.vehicleTypeList.find((item) => item.id === selectedValue);
                                            formik.setFieldValue(`towingInformation.${index}.vehicleType`, selectedOption || null)

                                        }}
                                        error={formik.touched.towingInformation && formik?.errors?.towingInformation && formik?.errors?.towingInformation[index] && Boolean(formik?.errors?.towingInformation[index]?.vehicleType)}


                                        name={`towingInformation.${index}.vehicleType`}
                                    >
                                        {mainData.vehicleTypeList && mainData.vehicleTypeList.map((item) => (
                                            <MenuItem value={item.id} key={item.id}>{item.name}</MenuItem>
                                        ))}
                                    </Select>




                                    {formik.touched.towingInformation && formik?.errors?.towingInformation && formik?.errors?.towingInformation[index] && Boolean(formik?.errors?.towingInformation[index]?.vehicleType)&& (
                                        <FormHelperText error>
                                            {typeof formik.errors.towingInformation[index]?.vehicleType === 'string' ?
                                                formik.errors.towingInformation[index]?.vehicleType : 'An error occurred'}
                                        </FormHelperText>
                                    )}
                                
                                </FormControl>
                                <TextField disabled={disabled}
                                    label={t('towingPrice')}
                                    InputProps={{
                                        endAdornment: <InputAdornment position="end">USD</InputAdornment>,
                                    }}
                                    name={`towingInformation.${index}.towingPrice`}
                                    value={formik.values?.towingInformation[index]?.towingPrice}
                                    onChange={(e) => {
                                        formik.setFieldValue(`towingInformation.${index}.towingPrice`, parseFloat(e.target.value))
                                    }}
                                />
                                <TextField disabled={true}
                                    value={formik.values?.towingInformation[index]?.towingMax}
                                    onChange={formik.handleChange}
                                    name={`towingInformation.${index}.towingMax`}
                                    label={t('towingMax')}

                                    variant="outlined"
                                />
                                <Box display="flex" gap="1" justifyContent="space-around" alignItems="center" >
                                    <TextField width="85%" disabled={disabled}
                                        name={`towingInformation.${index}.carrierName`}
                                        label={t('carrierName')}
                                        variant="outlined"
                                        value={formik.values?.towingInformation[index]?.carrierName}
                                        onChange={formik.handleChange}
                                    />


                                    <Box sx={{ visibility: index !== 1 || disabled ? 'hidden' : 'visible' }} display='flex' width="10%" justifyContent={'center'} alignItems={'center'}
                                        onClick={() => handleDeleteRecord(index, 'Receipt', `towingInformation.${index}.receiptFile`)}>
                                        <DeleteIcon sx={{
                                            color: 'red',
                                            fontSize: '20px'
                                        }} />
                                    </Box>

                                </Box>

                            </Box>
                            <Box display="grid" margin="64px 49px" gridTemplateColumns="repeat(4, 1fr)" gap="30px">
                                <CustomDatePicker disabled={disabled}
                                    label={t('pickUpDate')}
                                    name={`towingInformation.${index}.pickUpDate`}
                                />


                                <CustomDatePicker disabled={disabled}
                                    label={t('DeliveryDateETA')}
                                    name={`towingInformation.${index}.deliveryDateToWH`}


                                />
                                <FileUpload disabled={disabled}
                                    label={t('uploadDriverPictures')}
                                    uploadName='DriverPictures'
                                    formikName={`towingInformation.${index}.driverPictures`}
                                />
                                <CustomDatePicker
                                    disabled={disabled}
                                    label={t('deliveryDateToWarehouse')}
                                    name={`towingInformation.${index}.deliveryDateToWarehouse`}

                                />
                                {/* <Box sx={{
                        display: "flex"
                    }}>
                        <CollectionsIcon sx={{
                            color: 'red'
                        }} />
                        <Typography>{t('showThePictures')}</Typography>
                    </Box> */}
                            </Box>
                            <Box display="grid" margin="64px 49px" gridTemplateColumns="repeat(4, 1fr)" gap="30px">
                                <FormControl disabled={disabled} sx={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'center'
                                }}>
                                    <FormLabel sx={{
                                        marginRight: 2
                                    }} >{t('paid')}</FormLabel>
                                    <RadioGroup
                                        row
                                        defaultValue={formik.values?.towingInformation[index]?.paid}
                                        onChange={(e) => {
                                            formik.setFieldValue(`towingInformation.${index}.paid`, !formik.values?.towingInformation[index]?.paid)
                                        }}
                                        name={`towingInformation.${index}.paid`}
                                    >
                                        <FormControlLabel value={true} control={<Radio />} label={t('yes')} />
                                        <FormControlLabel value={false} control={<Radio />} label={t('no')} />
                                    </RadioGroup>
                                </FormControl>



                                <FileUpload disabled={disabled} label={t('uploadReceipt')} w uploadName='Receipt' formikName={`towingInformation.${index}.receiptFile`}

                                />

                                <Box sx={{
                                    display: 'flex',
                                    alignItems: 'center'

                                }}>
                                    <Checkbox disabled={disabled}
                                        checked={formik.values?.towingInformation[index]?.extraFeesIfOffsite || false}
                                        value={formik.values?.towingInformation[index]?.extraFeesIfOffsite}
                                        onChange={(e) => {
                                            formik.setFieldValue(`towingInformation.${index}.extraFeesIfOffsite`, !formik.values?.towingInformation[index]?.extraFeesIfOffsite)
                                        }}
                                        name={`towingInformation.${index}.extraFeesIfOffsite`}
                                        aria-label="check"
                                        sx={{ '& .MuiSvgIcon-root': { fontSize: 28 } }}
                                    />
                                    <Typography>{t('extraFeesIfOffsite')}</Typography>
                                </Box>
                                <TextField disabled={disabled}
                                    label={t('extraAmount')}
                                    InputProps={{
                                        endAdornment: <InputAdornment position="end">USD</InputAdornment>,
                                    }}
                                    value={formik.values?.towingInformation[index]?.extraAmount}
                                    name={`towingInformation.${index}.extraAmount`}
                                    onChange={(e) => {
                                        formik.setFieldValue(`towingInformation.${index}.extraAmount`, parseFloat(e.target.value))
                                    }}
                                />










                            </Box>

                            <Box sx={{ display: "flex", justifyContent: "center", alignContent: "center" }}>

                                <Divider sx={{
                                    width: '40%',
                                    height: 10,
                                    marginLeft: '5px',
                                    textAlign: "center"
                                }} />


                            </Box>
                        </Box>

                    </Fragment>
                )
            })}

        </>
    )
}

export default TowingInfo;