import React, { useEffect, useState } from 'react';
import { Box, Button, FormControl, FormControlLabel, FormLabel, IconButton, InputLabel, Menu, MenuItem, Radio, RadioGroup, Select } from '@mui/material';
import SortIcon from '@mui/icons-material/Sort';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import { fetchVehiclesMobile } from '../../../actions/clientViewAction';
import MixSearch from '../searchOptionComponents/MixSearch';

function DesktopFilter({deleteMixSearch, getMixSearch}) {
    const navigate = useNavigate();
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const dispatch = useDispatch();
    const [mixSearchValue, setMixSearchValue] = useState(searchParams.get("MixSearch"));

    const { initialData } = useSelector((state) => state.clientViewInitial) || {};

    // State for filters and sorting
    const [selectStatus, setSelectStatus] = useState(0);
    const [selectAuction, setSelectAuction] = useState(0);
    const [selectPort, setSelectPorts] = useState(0);
    const [sortField, setSortField] = useState('purchaseDate');
    const [sortOrder, setSortOrder] = useState('desc');
    const [selectSort, setSelectSort] = useState('RecentPurchaseDate');
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    const user = useSelector((state) => state.user.userInfo);


    // Update URL with filter parameters
    useEffect(() => {
        updateURLQuery();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectStatus, selectAuction, selectPort, sortField, sortOrder]);

    const updateURLQuery = () => {
        searchParams.set('SortBy', sortField);
        searchParams.set('SortOrder', sortOrder);
        ['Status', 'Auction', 'Port'].forEach(key => {
            // eslint-disable-next-line no-eval
            handleQueryParamUpdate(key.toLowerCase(), eval(`select${key}`));
        });
        navigate(`?${searchParams.toString()}`);
    };

    const handleQueryParamUpdate = (key, value) => {
        value === 0 ? searchParams.delete(key) : searchParams.set(key, value);
    };

    // Handle sort option changes
    const handleSort = (e) => {
        const sortValue = e.target.value;
        setSortField("purchaseDate");
        setSortOrder(sortValue === "RecentPurchaseDate" ? "desc" : "asc");
        setSelectSort(sortValue);
    };

    // Handle filter changes
    const onFilterChange = (e) => {
        const { name, value } = e.target;
        // eslint-disable-next-line no-eval
        const stateSetter = eval(`setSelect${name}`);
        stateSetter(value);
    };



    useEffect(()=>{
        handleFetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[searchParams.get("MixSearch")])
    // Fetch data based on filters and sorting
    const handleFetchData = () => {
        if (mixSearchValue !== ""){

            if (mixSearchValue !== searchParams.get("MixSearch")){
                searchParams.set("MixSearch", mixSearchValue);
                searchParams.set("SortBy", "v.id");
                navigate({ search: searchParams.toString() });
                return;
            }

        }





        if (user.accountType === "admin") {
            dispatch(fetchVehiclesMobile({ query: searchParams.toString(), clientId: searchParams.get("clientId") }));

        } else {
            dispatch(fetchVehiclesMobile({ query: searchParams.toString() }));

        }
        handleCloseMenu();
    };



    const handleResetFilter = () => {
        setSelectStatus(0);
        setSelectAuction(0);
        setSelectPorts(0);
        setMixSearchValue("");
    };

    // Menu handlers
    const handleOpenMenu = (event) => setAnchorEl(event.currentTarget);
    const handleCloseMenu = () => setAnchorEl(null);

    return (
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', pt: 2, pb: 2, width: "100%", position: 'sticky', top: 0 }}>
            <IconButton onClick={handleOpenMenu}>
                <SortIcon />
            </IconButton>
            <Menu
                id="sort-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleCloseMenu}
            >
                <Box sx={{ p: 2 }}>
                    <FormControl>
                        <FormLabel>Sorts</FormLabel>
                        <RadioGroup row aria-labelledby="demo-row-radio-buttons-group-label" name="Sorts" value={selectSort} onChange={handleSort}>
                            <FormControlLabel value="RecentPurchaseDate" control={<Radio />} label="Recent Purchase Date" />
                            <FormControlLabel value="PreviousPurchaseDate" control={<Radio />} label="Previous Purchase Date" />
                        </RadioGroup>
                    </FormControl>
                    <Box sx={{ display: "flex", gap: 2, flexWrap: "wrap", flexDirection: "column", pt: 4 }}>
                        {/* Filters for Status, Auction, and Ports */}
                        {renderFilterControl('Status', selectStatus, initialData?.statusList, onFilterChange)}
                        {renderFilterControl('Auction', selectAuction, initialData?.auctionsList, onFilterChange)}
                        {renderFilterControl('Ports', selectPort, initialData?.portList, onFilterChange)}

                        <MixSearch inputValue ={mixSearchValue} setInputValue={setMixSearchValue} />
                        <Button variant='b1' onClick={handleFetchData}>Fetch Data</Button>
                        <Button variant='b2' onClick={handleResetFilter}>Reset Filter</Button>

                    </Box>
                </Box>
            </Menu>
        </Box>
    );
}

// Helper function to render filter controls
const renderFilterControl = (label, selectedValue, options, onChangeHandler) => (
    <FormControl variant="outlined" size="small" sx={{ minWidth: "100px" }}>
        <InputLabel id={`${label.toLowerCase()}-filter-label`}>{label}</InputLabel>
        <Select
            labelId={`${label.toLowerCase()}-filter-label`}
            id={`${label.toLowerCase()}-filter`}
            label={label}
            name={label}
            onChange={onChangeHandler}
            value={selectedValue}
        >
            <MenuItem value={0}>All</MenuItem>
            {options?.map(data => (
                <MenuItem key={`${label}_${data.id}`} value={data.id}>{data.name}</MenuItem>
            ))}
        </Select>
    </FormControl>
);

export default DesktopFilter;