/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Button, Checkbox, FormControlLabel, Menu, MenuItem } from '@mui/material'
import React, { useEffect, useState } from 'react'
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
function StatusMenu() {

    const location = useLocation();
    const navigate = useNavigate();

    const [selectedStatus, setSelectedStatus] = useState([]);
    const [anchor, setAnchor] = useState(null);
    const open = Boolean(anchor);
    const [mainData, setMainData] = useState({});
    const formReducer = useSelector((state) => state.form);






    const updateURLQuery = () => {
        if (anchor) {

            const searchParams = new URLSearchParams(location.search);
            if (selectedStatus.length === 0) {
                searchParams.delete('Status');
            } else {
                searchParams.set('Status', selectedStatus.join(','));
            }
            navigate({ search: searchParams.toString() });
        }
    };





    useEffect(() => {
        if (formReducer.loading === 'succeeded') {
            setMainData(formReducer.data);
        }

        const searchParams = new URLSearchParams(location.search);
        const statusParam = searchParams.get('Status');

        if (statusParam === null) {
            setSelectedStatus([]);
        } else {
            const selected = statusParam.split(',').map(Number);
            setSelectedStatus(selected);
        }
    }, [formReducer.loading, location.search]);









    useEffect(() => {
        updateURLQuery();
    }, [selectedStatus]);







    const handleClick = (event) => {


        setAnchor(event.currentTarget);
    };
    const handleClose = (e) => {

        setAnchor(null);
    };

    const handleCheckboxChange = (event) => {
        const statusId = Number(event.target.value);
        const isChecked = event.target.checked;

        if (isChecked && statusId === 0) {
            setSelectedStatus([]);
        } else {
            if (isChecked) {
                setSelectedStatus((prevSelected) => [...prevSelected, statusId]);
            } else {
                setSelectedStatus((prevSelected) => prevSelected.filter((id) => id !== statusId));
            }
        }
    };


    return (
        <Box className='Status'>
            <Box >
                <Button
                    id="Status"
                    aria-controls={open ? 'basic-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? 'true' : undefined}
                    onClick={handleClick}

                >
                    Status
                    {open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                </Button>
            </Box>
            <Menu
                id="basic-menu"
                anchorEl={anchor}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
                PaperProps={{
                    style: {
                        width: '85%',
                        maxWidth: '100%',
                        maxHeight: '300px',
                        left: 0,
                        right: 0,
                    },
                }}
            >
                <Box sx={{ display: 'flex', flexDirection: 'column', flexWrap: 'wrap', width: "100%", maxHeight: '300px', overflow: 'auto', overflowY: 'hidden' }}>
                    <MenuItem>
                        <FormControlLabel
                            label="All Status"
                            control={<Checkbox checked={selectedStatus.length === 0} value={0} onChange={handleCheckboxChange} />}
                        />
                    </MenuItem>

                    {mainData.statusList?.map((item) => (
                        <MenuItem key={item.id}>
                            <FormControlLabel
                                label={item.name}
                                control={<Checkbox checked={selectedStatus.includes(item.id)} value={item.id} onChange={handleCheckboxChange} />}
                            />
                        </MenuItem>
                    ))}
                </Box>
            </Menu>
        </Box>


    )
}

export default StatusMenu