import { createSlice } from "@reduxjs/toolkit";
import { PURGE } from "redux-persist";
import { getVehicleInfo } from "../actions/formAction";

const initialState = {
    Object: null,
    loading: 'idle',
    hasError: false,
    errorMessage: ''
}
const vehicleInfoSlice = createSlice({
    name: 'vehicleInfo',
    initialState,
    reducers: {
        resetState: () => {

            return initialState;
        }

    },
    extraReducers: (builder) => {
        builder.addCase(PURGE, () => {
            return initialState;
        })
            .addCase(getVehicleInfo.pending, (state) => {
                state.loading = 'pending';
                state.hasError = false
            })
            .addCase(getVehicleInfo.fulfilled, (state, action) => {
                state.loading = 'succeeded';
                state.hasError = false;
                state.Object = action.payload;
                state.errorMessage = ""


            })
            .addCase(getVehicleInfo.rejected, (state, action) => {
                state.loading = 'failed';
                state.hasError = true;
                state.errorMessage = action.payload
            })
    }
});
export const { deleteFileById, resetState } = vehicleInfoSlice.actions;

export default vehicleInfoSlice.reducer;