import { createAsyncThunk } from '@reduxjs/toolkit'
import { AxiosInstanceService } from '../services/axiosInstance'
import { apiRoute } from '../util/constant'
import { resetState } from '../slices/formInitialSlice';

export const initialForm = createAsyncThunk(
    'form/initialData',
    async (_, { rejectWithValue }) => {
        try {
            const instance = await AxiosInstanceService.getAxiosInstance();
            const response = await instance.get(`${apiRoute.verificationValues}`)
            return response.data;
        } catch (error) {
            // return custom error message from backend if present
            if (error.response && error.response.data.message) {
                return rejectWithValue(error.response.data.message)
            } else {
                return rejectWithValue(error.message)
            }
        }
    }
);










export const initialFormWithVehiclesId = (params) => async (dispatch) => {
    const vehicleId =  params.vehicleId ; 
    await await dispatch(resetState());
    if (params.vehicleId === null){
        return await dispatch(initialForm())
    } else {
        await  dispatch(initialForm())
        return await dispatch(getVehicleInfo({vehicleId}))
    }
}



export const auctionsLocationsBuyerNumber = createAsyncThunk(
    'form/auctionsLocationsBuyerNumber',
    async ({ id }, { rejectWithValue }) => {
        try {
            const instance = await AxiosInstanceService.getAxiosInstance();
            const response = await instance.get(`${apiRoute.auctionsLocationsBuyerNumber}${id}`)
            return response.data;
        } catch (error) {
            // return custom error message from backend if present
            if (error.response && error.response.data.message) {
                return rejectWithValue(error.response.data.message)
            } else {
                return rejectWithValue(error.message)
            }
        }
    }
)

export const carModels = createAsyncThunk(
    'form/carModels',
    async ({ id }, { rejectWithValue }) => {
        try {
            const instance = await AxiosInstanceService.getAxiosInstance();
            const response = await instance.get(`${apiRoute.models}${id}`)
            return response.data;
        } catch (error) {
            // return custom error message from backend if present
            if (error.response && error.response.data.message) {
                return rejectWithValue(error.response.data.message)
            } else {
                return rejectWithValue(error.message)
            }
        }
    }
);





export const containers = createAsyncThunk(
    'form/containers',
    async ({ name = '' , bookingId}, { rejectWithValue }) => {
        try {
            const instance = await AxiosInstanceService.getAxiosInstance();

            if (bookingId){

                const response = await instance.get(`${apiRoute.container}${name}&bookingId=${bookingId}`)
                return response.data;

            } else {

                const response = await instance.get(`${apiRoute.container}${name}`)
                return response.data;

            }


        } catch (error) {
            // return custom error message from backend if present
            if (error.response && error.response.data.message) {
                return rejectWithValue(error.response.data.message)
            } else {
                return rejectWithValue(error.message)
            }
        }
    }
);


export const searchClient = createAsyncThunk(
    'form/client',
    async ({ name = '' }, { rejectWithValue }) => {
        try {
            const instance = await AxiosInstanceService.getAxiosInstance();
            const response = await instance.get(`${apiRoute.client}${name}`)
            return response.data;
        } catch (error) {
            // return custom error message from backend if present
            if (error.response && error.response.data.message) {
                return rejectWithValue(error.response.data.message)
            } else {
                return rejectWithValue(error.message)
            }
        }
    }
);




export const searchConsignee = createAsyncThunk(
    'form/consignee',
    async ({ name = '' }, { rejectWithValue }) => {
        try {
            const instance = await AxiosInstanceService.getAxiosInstance();
            const response = await instance.get(`${apiRoute.consignee}${name}`)
            return response.data;
        } catch (error) {
            // return custom error message from backend if present
            if (error.response && error.response.data.message) {
                return rejectWithValue(error.response.data.message)
            } else {
                return rejectWithValue(error.message)
            }
        }
    }
);




export const auctionsbooking = createAsyncThunk(
    'form/booking',
    async ({ name = '' }, { rejectWithValue }) => {
        try {
            const instance = await AxiosInstanceService.getAxiosInstance();
            const response = await instance.get(`${apiRoute.booking}${name}`)
            return response.data;
        } catch (error) {
            // return custom error message from backend if present
            if (error.response && error.response.data.message) {
                return rejectWithValue(error.response.data.message)
            } else {
                return rejectWithValue(error.message)
            }
        }
    }
);







export const getVehicleInfo = createAsyncThunk(
    'form/getVehicleInfo',
    async ({ vehicleId }, { rejectWithValue }) => {
        try {
            const instance = await AxiosInstanceService.getAxiosInstance();
            const response = await instance.get(`${apiRoute.getVehicleInfo}${vehicleId}`)
            return response.data;
        } catch (error) {
            // return custom error message from backend if present
            if (error.response && error.response.data.message) {
                return rejectWithValue(error.response.data.message)
            } else {
                return rejectWithValue(error.message)
            }
        }
    }
);

export const addVehicle = createAsyncThunk(
    'form/addVehicle',
    async ({ data , id}, { rejectWithValue }) => {
      
        try {
            const instance = await AxiosInstanceService.getAxiosInstance();

            if (!id) {
           
            const response = await instance.post(`${apiRoute.addVehicle}`,
                data
            )
            return response.data;
            } else {
        
                const response = await instance.put(`${apiRoute.addVehicle}/${id}`, 
                    data
                )
                return response.data;

            }
        } catch (error) {
            // return custom error message from backend if present
            if (error.response && error.response.data.message) {
                return rejectWithValue(error.response.data.message)
            } else {
                return rejectWithValue(error.message)
            }
        }
    }
);
