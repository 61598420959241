import { createAsyncThunk } from "@reduxjs/toolkit";
import { AxiosInstanceService } from "../services/axiosInstance";
import { apiRoute } from "../util/constant";





export const initialClientView = createAsyncThunk(
    'clientView/initialData',
    async ({clientId =null}, { rejectWithValue }) => {

        let url = `${apiRoute.clientViewInitial}`;

        if (clientId != null) {
            url += `?clientId=${clientId}`;
        }


        try {
            const instance = await AxiosInstanceService.getAxiosInstance();
            const response = await instance.get(url )
            return response.data;
        } catch (error) {
            // return custom error message from backend if present
            if (error.response && error.response.data.message) {
                return rejectWithValue(error.response.data.message)
            } else {
                return rejectWithValue(error.message)
            }
        }
    }
);



export const fetchVehiclesMobile = createAsyncThunk(
    'clientView/vehiclesMobile',
    async ({ PageNumber = null, PageSize = null,clientId =null ,query }, { rejectWithValue }) => {
        // Construct the URL based on the provided parameters
        let url = `${apiRoute.vehiclesMobile}?${query}`;
        if (PageNumber != null) {
            url += `&PageNumber=${PageNumber}`;
        }
        if (PageSize != null) {
            url += `&PageSize=${PageSize}`;
        }

        if (clientId != null) {
            url += `&clientId=${clientId}`;
        }

        try {
            const instance = await AxiosInstanceService.getAxiosInstance();
            const response = await instance.get(url);
            return response.data;
        } catch (error) {
            const errorMessage = error.response?.data.message || error.message;
            return rejectWithValue(errorMessage);
        }
    }
);
