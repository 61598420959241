/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Button, Paper, TextField } from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams, useSearchParams } from "react-router-dom";
import {  addManageRefrsh, updateManageByIdRefrsh } from "../../actions/mangeAction";
import CustomToast from "../CustomToast/CustomToast";
import { resetError } from "../../slices/manageSlice";
import { t } from "i18next";
import PageHeaderManage from "../PageHeader/PageHeaderManage";



const AddPickUp = () => {
    const dispatch = useDispatch();
    const { mode } = useParams();
    const [searchParams] = useSearchParams()
    const parentId = searchParams.get('parentId');
    const location = useLocation();
    const state = location.state;
    const { id, name, towingMax } = state || {};
    const [value, setValue] = useState({
        name: '',
        towingMax: null
    });
    const { updated, added, hasError, errorMessage } = useSelector((state) => state.manage)
    const navigation = useNavigate();

    useEffect(() => {
    }, [value])
    useEffect(() => {
        // this for edit
        setValue({
            name,
            towingMax
        });
    }, [state]);
    const handleSave = () => {
        if (id) {
            dispatch(updateManageByIdRefrsh({ mode, id, data: { name: value.name, towingMax: value.towingMax } }));
            return;
        }
        dispatch(addManageRefrsh({ mode, data: { name: value.name, towingMax: value.towingMax }, parentId }));


    }

    const handleBack = () => {

        navigation(`/${mode}/pickup` + location.search, {
            state: {
                showSuccess: false
            }
        })

    }
    const handleToastClose = () => {
        dispatch(resetError())
    };
    if (hasError) {
        return (<>
            <CustomToast open={hasError} message={errorMessage} onClose={handleToastClose} />
        </>)

    }
    // eslint-disable-next-line no-mixed-operators
    if (updated || added && !hasError) {
        navigation(`/${mode}/pickup`, {
            state: {
                showSuccess: true,
                name: value.name
            }
        })
    }
    return (
        <Paper variant="p1" >

            <PageHeaderManage title={t(mode).toUpperCase()} />

            <Box display='flex' justifyContent='space-evenly' gap={2}>
                <TextField
                    label={mode}
                    variant="outlined"
                    onChange={(e) => setValue(pre => ({
                        ...pre,
                        name: e.target.value
                    }))}
                    sx={{
                        width: '50%'
                    }}
                    value={value.name}
                />
                <TextField
                    label={t('towingMax')}
                    variant="outlined"
                    type="number"
                    onChange={
                        (e) => {
                            // if (Number(e.target.value)) {
                            let input = e.target.value
                            if (input.match(/^([0-9]{1,})?(\.)?([0-9]{1,})?$/)) {
                                setValue(pre => ({
                                    ...pre,
                                    towingMax: parseFloat(e.target.value)
                                }))
                            }
                            // }
                        }
                    }
                    sx={{
                        width: '50%'
                    }}
                    value={value.towingMax}
                />
                <Box display='flex' justifyContent='space-evenly' alignItems='center'>
                    <Button variant="b2" sx={{
                        marginInline: '20px'
                    }} onClick={handleBack} >Cancel</Button>
                    <Button variant="b1" onClick={handleSave} disabled={value === '' || value === undefined ? true : false} >Save</Button>

                </Box>
            </Box>
        </Paper>
    )
}

export default AddPickUp;