import React, { useEffect } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import ClientsMoveMenu from "./searchOptionComponents/ClientsMoveMenu";
import { Typography } from "@mui/material";

const DeleteConfirmationAndMergeModal = ({
  isOpen,
  onClose,
  onDelete,
  selectedMoveClient,
  setSelectedMoveClient,
  deleteItem
}) => {
  useEffect(() => {
    setSelectedMoveClient(null);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Dialog open={isOpen} onClose={onClose}>
      <DialogTitle>Confirm Delete</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Before account deletion, identify the customer for vehicle transfer.
          This action is irreversible{" "}
        </DialogContentText>
        <ClientsMoveMenu
          selectedClient={selectedMoveClient}
          setSelectedClient={setSelectedMoveClient}
        />

        {selectedMoveClient !== null && (
          <Typography>
            {`All vehicles from account ${deleteItem.name} will be transferred to account ${selectedMoveClient.name}.`}{" "}
          </Typography>
        )}
      </DialogContent>

      <DialogActions>
        <Button onClick={onClose} variant="b2">
          Cancel
        </Button>
        <Button
          disabled={selectedMoveClient === null}
          onClick={onDelete}
          variant="b1"
        >
          Delete
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DeleteConfirmationAndMergeModal;
