import { createSlice } from "@reduxjs/toolkit";
import { addVehicle } from "../actions/formAction";
const initialState = {
    isAdding: false,
    message: "",
    object: {},
    hasError: false,
    errorMessage: '',
    isLoading: false,
    carAddedSuccessfully: false,

}
const addNewVehicle = createSlice({
    name: 'addNew',
    initialState,
    reducers: {
        resetState: () => {
            return initialState;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(addVehicle.pending, (state, action) => {
                state.isAdding = true;
                state.isLoading = true; 
                state.message = '';
                state.hasError = false;
                state.errorMessage = '';
                state.carAddedSuccessfully = false;

            })
            .addCase(addVehicle.fulfilled, (state, action) => {
                const response = action.payload;
                state.isAdding = false;
                state.isLoading = false; 

                state.object = response.object;
                state.message = response.message
                state.hasError = false;
                state.errorMessage = '';
                state.carAddedSuccessfully = true;

            })
            .addCase(addVehicle.rejected, (state, action) => {
                const response = action.payload;
                state.isLoading = false; 
                state.isAdding = false;
                state.hasError = true;
                state.errorMessage = response;
                state.carAddedSuccessfully = false;
            })
    }
});

export const { resetState } = addNewVehicle.actions
export default addNewVehicle.reducer