import { CacheProvider } from '@emotion/react';
import { ThemeProvider } from '@mui/material/styles';
import { Box, CssBaseline } from '@mui/material';
import { useTranslation } from 'react-i18next';
import createCache from "@emotion/cache";
import { prefixer } from 'stylis';
import rtlPlugin from "stylis-plugin-rtl";
import { create } from "jss";
import { portalTheme } from './portalTheme';
import { StylesProvider, jssPreset } from '@mui/styles';
import rtl from "jss-rtl";
import React from 'react';

import "../src/i18n";
import MasterRoute from './MasterRoute';
import Toast from './components/ToastProvider/ToastProvider'



function App() {



  const { i18n } = useTranslation();
  document.dir = i18n.dir;
  const theme = portalTheme(i18n.dir);

  const cacheRtl = createCache({
    key: theme.direction === "rtl" ? "muirtl" : "muiltrz",
    prepend: true,
    stylisPlugins: theme.direction === "rtl" ? [prefixer, rtlPlugin] : [prefixer],

  });

  const jss = create({ plugins: [...jssPreset().plugins, rtl()] });



  return (


    <CacheProvider value={cacheRtl}>

      <StylesProvider jss={jss}>

        <ThemeProvider theme={theme}>
          <Box component="section">

            <CssBaseline />
            <MasterRoute />
            <Toast />

          </Box>

        </ThemeProvider>

      </StylesProvider >

    </CacheProvider >

  );
}

export default App;
