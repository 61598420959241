/* eslint-disable react-hooks/exhaustive-deps */
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import {
    Alert,
    Box,
    Button,
    FormControlLabel,
    Paper,
    Switch,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    TableSortLabel,
} from '@mui/material';
import IconButton from '@mui/material/IconButton';
import { useTheme } from '@mui/material/styles';
import { visuallyHidden } from '@mui/utils';
import { t } from 'i18next';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { deleteManageByIdRefrsh, getMangeData } from '../../actions/mangeAction';
import { resetState as resetStateColor, setSelectedUser } from '../../slices/manageSlice';
import Loader from '../Loader';

import { Delete } from "@mui/icons-material";
import DeleteConfirmationModal from '../DeleteConfirmationModal/DeleteConfirmationModal';
import PageHeaderManage from '../PageHeader/PageHeaderManage';
import dayjs from 'dayjs';
import { initialForm } from '../../actions/formAction';
import RotateLeftIcon from '@mui/icons-material/RotateLeft';
import { showErrorToast } from '../../slices/toastSlice';
import MixSearch from '../List/MixSearch';
// headCells configuration for table columns
const headCells = [

    { id: 'id', numeric: true, disablePadding: false, label: 'ID' },
    { id: 'userName', numeric: false, disablePadding: false, label: 'User Name' },
    { id: 'firstName', numeric: false, disablePadding: false, label: 'First Name' },
    { id: 'lastName', numeric: false, disablePadding: false, label: 'Last Name' },
    { id: 'email', numeric: false, disablePadding: false, label: 'Email' },
    { id: 'createDataAndTime', numeric: false, disablePadding: false, label: 'Create Date' },
    { id: 'country', numeric: false, disablePadding: false, label: 'Country' },
    { id: 'id', numeric: false, disablePadding: false, label: 'Action' },




];
// Create a data object from input fields
// id username  firstName lastName userName email create date  country 
function createData(id, userName, firstName, lastName, email, createDataAndTime, country, rest) {
    return { id, userName, firstName, lastName, email, createDataAndTime, country, ...rest };
}
// Component for pagination actions in the table

function TablePaginationActions(props) {
    const theme = useTheme();
    const { count, page, rowsPerPage, onPageChange } = props;

    const handlePageChange = (event, newPage) => {
        onPageChange(event, newPage);
    };

    return (
        <Box sx={{ flexShrink: 0, ml: 2.5 }}>
            <IconButton onClick={(event) => handlePageChange(event, 0)} disabled={page === 0} aria-label="first page">
                {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
            </IconButton>
            <IconButton onClick={(event) => handlePageChange(event, page - 1)} disabled={page === 0} aria-label="previous page">
                {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
            </IconButton>
            <IconButton
                onClick={(event) => handlePageChange(event, page + 1)}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="next page"
            >
                {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
            </IconButton>
            <IconButton
                onClick={(event) => handlePageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1))}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="last page"
            >
                {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
            </IconButton>
        </Box>
    );
}
// Component for the table head with sorting functionality

function EnhancedTableHead(props) {
    const { order, orderBy, onRequestSort } = props;

    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };

    return (


        <TableHead>
            <TableRow>
                {headCells.map((headCell, index) => (
                    <TableCell
                        key={index}
                        padding={headCell.disablePadding ? 'none' : 'normal'}
                        sortDirection={orderBy === headCell.id ? order : false}
                        variant="head"
                    >
                        <TableSortLabel active={orderBy === headCell.id} direction={orderBy === headCell.id ? order : 'asc'} onClick={createSortHandler(headCell.id)}>
                            {t(headCell.label).toUpperCase()}
                            {orderBy === headCell.id ? (
                                <Box component="span" sx={visuallyHidden}>
                                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                </Box>
                            ) : null}
                        </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}
// Main component for the DataGridTable

const UserList = () => {

    const { mode } = useParams();
    const reduser = useSelector((state) => state.manage);
    const [searchParams, setSearchParams] = useSearchParams();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedItem, setSelectedItem] = useState({ name: '', id: 0 });





    const location = useLocation();
    const state = location.state;
    const { showSuccess } = state || false;

    const data = reduser.object || {};
    const content = data.content || [];
    const dispatch = useDispatch();
    const navigation = useNavigate();

    useEffect(() => {
        dispatch(getMangeData({ mode, query: "" }));

        return () => {

            dispatch(resetStateColor())
        }


    }, [mode, dispatch]);

    useEffect(() => {
        dispatch(initialForm());
    }, [])




    useEffect(() => {
        dispatch(getMangeData({ mode, query: searchParams.toString() }))

    }, [searchParams.get("PageSize"), searchParams.get("PageNumber"), searchParams.get("SortOrder"), searchParams.get("SortBy") , searchParams.get("MixSearch")])





    // URL query parameters and their setters
    const page = Number(searchParams.get('PageNumber')) || 0;
    const rowsPerPage = Number(searchParams.get('PageSize')) || 5;
    const order = searchParams.get('SortOrder') || 'desc';
    const orderBy = searchParams.get('SortBy') || 'id';

    const setPage = (value) => {
        searchParams.set('PageNumber', value);
        setSearchParams(searchParams);
    };

    const setRowsPerPage = (value) => {
        searchParams.set('PageSize', value);
        setSearchParams(searchParams);
    };

    const setOrder = (value) => {
        searchParams.set('SortOrder', value);
        setSearchParams(searchParams);
    };

    const setOrderBy = (value) => {
        searchParams.set('SortBy', value);
        setSearchParams(searchParams);
    };

    const [dense, setDense] = useState(true);

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };



    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleChangeDense = (event) => {
        setDense(event.target.checked);
    };



    const handleOpenModal = (item) => {
        setSelectedItem(item)
        setIsModalOpen(true);
    };
    const handleCloseModal = () => {
        setIsModalOpen(false);
    };

    const handleDelete = () => {
        dispatch(deleteManageByIdRefrsh({ mode, id: selectedItem.id }))
        handleCloseModal();
    };



    const handelEdit = (item) => {
        dispatch(setSelectedUser(item));
        navigation(`/${mode}/addUser?id=${item.id}`)
    }
    const handelEditPassword = (item) => {
        navigation(`/${mode}/updatePassword?id=${item.id}`)
    }

    // Table data preparation
    const rows = content.map((e) => {

        return createData(e.id, e.userName, e.firstName, e.lastName, e.email, e.createDataAndTime, e.country, e);
    });



    const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - data.totalElements) : 0;

    if (reduser.hasError) {
        dispatch(showErrorToast(reduser.errorMessage));

    }

    if (reduser.loading === 'pending' || reduser.loading === 'idle') {
        return <Loader />
    }



    return (
        <Paper variant='p1'>
<Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
                <PageHeaderManage title={t(mode).toUpperCase()} />

                <Box sx={{ maxWidth: "35%" }}>
                    <MixSearch />
                </Box>
            </Box>
            <Box display='flex' marginBottom='20px' justifyContent='flex-end'>
                <Button variant="b2" onClick={() => navigation(`/${mode}/addUser`)} >Add New {mode}</Button>
            </Box>

            {showSuccess && <Alert sx={{ m: 2 }} severity="success">{`The ${mode.toLowerCase()}  has been saved successfully`}</Alert>}

            {reduser.deleted && <Alert sx={{ m: 2 }} severity="success">{`The ${mode.toLowerCase()}  has been deleted successfully`}</Alert>}

            <Box sx={{ width: '100%' }}>
                <Paper sx={{ width: '100%', mb: 2 }}>
                    <TableContainer>
                        <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle" size={dense ? 'small' : 'medium'}>
                            <EnhancedTableHead
                                order={order}
                                orderBy={orderBy}
                                onRequestSort={handleRequestSort}
                                rowCount={rows.length}
                            />
                            <TableBody>
                                {rows.map((row, index) => (
                                    <React.Fragment key={index}>
                                        <TableRow hover role="checkbox" tabIndex={-1} key={row.businessDayName}>
                                            <TableCell>{row.id}</TableCell>
                                            <TableCell>{row.userName}</TableCell>
                                            <TableCell>{row.firstName}</TableCell>
                                            <TableCell>{row.lastName}</TableCell>
                                            <TableCell>{row.email}</TableCell>
                                            <TableCell>{dayjs(row.createDataAndTime).format('DD/MM/YYYY')}</TableCell>
                                            <TableCell>{row.country ? row.country.name : "all"}</TableCell>

                                            <TableCell>
                                                <IconButton onClick={() => handelEdit(row)} aria-label="Edit" title='Edit'>
                                                    <ModeEditIcon color="info" />
                                                </IconButton>

                                                <IconButton onClick={() => handleOpenModal(row)} aria-label="Delete" title='Delete'>
                                                    <Delete color='error' />
                                                </IconButton>
                                                <IconButton onClick={() => handelEditPassword(row)} aria-label="Update Password" title='UpdatePassword'>
                                                    <RotateLeftIcon color="warning" />
                                                </IconButton>
                                            </TableCell>

                                        </TableRow>
                                    </React.Fragment>
                                ))}
                                {emptyRows > 0 && (
                                    <TableRow style={{ height: (dense ? 33 : 53) * emptyRows }}>
                                        <TableCell colSpan={5} />
                                    </TableRow>
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <TablePagination
                        rowsPerPageOptions={[5, 10, 25, 50]}
                        component="div"
                        count={data.totalElements}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                        SelectProps={{
                            inputProps: { 'aria-label': 'rows per page' },
                            native: true,
                        }}
                        ActionsComponent={TablePaginationActions}
                    />
                </Paper>
                <FormControlLabel control={<Switch checked={dense} onChange={handleChangeDense} />} label={t('Densepadding')} />


                {isModalOpen && (
                    <DeleteConfirmationModal
                        isOpen={isModalOpen}
                        onClose={handleCloseModal}
                        onDelete={handleDelete}
                    />
                )}
            </Box>
        </Paper>
    );
};

export default UserList;
