/* eslint-disable react-hooks/exhaustive-deps */
import { Autocomplete, Box, Checkbox, CircularProgress, FormControl, FormControlLabel, FormHelperText, FormLabel, InputLabel, MenuItem, Paper, Radio, RadioGroup, Select,  TextField, TextareaAutosize, Typography } from "@mui/material";
import PageHeader from "../../../components/PageHeader/PageHeader";
import FileUpload from "../../../components/FileUpload";
import { t } from "i18next";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { auctionsbooking, containers } from "../../../actions/formAction";
import { debounce } from 'lodash';
import { useCallback } from "react";
import { useFormikContext } from "formik";

const WareHouseLoadInfo = ({ isAbleEdit }) => {
    const dispatch = useDispatch();
    const formik = useFormikContext();

    const { container, isLoadingContainerData, isLoadingBookingData, booking } = useSelector((state) => state.form);

    const formReducser = useSelector((state) => state.form);




    const [mainData, setMainData] = useState({})

    const [containerData, setContainerData] = useState([])
    const [bookingData, setBookingData] = useState([])


    useEffect(() => {
        if (formReducser.loading === 'succeeded') {
            setMainData(formReducser.data)
        }

    }, [formReducser])



    useEffect(() => {
        if (isLoadingContainerData === 'succeeded') {
            setContainerData(container)
        }

    }, [isLoadingContainerData])




    useEffect(() => {
        if (isLoadingBookingData === 'succeeded') {
            setBookingData(booking)
        }

    }, [isLoadingBookingData])







    const [hasApiBeenCalled, setHasApiBeenCalled] = useState(false);

    const [hasApiBookingBeenCalled, setHasApiBookingBeenCalled] = useState(false);

    const [hasLoadedBooking, setHasLoadedBooking] = useState(false);

    const [hasLoaded, setHasLoaded] = useState(false);

    // const [selectedOption, setSelectedOption] = useState(formik.values?.container || {});
    const [selectedOption, setSelectedOption] = useState(formik.values?.container || null);



    const [selectedBookingOption, setSelectedBookingOption] = useState(formik.values?.booking || null);

    const handleInputChange = debounce((event, value) => {
        if (!hasLoaded) {
            setHasLoaded(true)
            return;
        }

        const bookingId = formik.values?.booking?.id || null
        const name = value !== 'undefined' ? value : ''

        if (bookingId !== null) {
            dispatch(containers({ name, bookingId }));

        }


    }, 700);




    const handleBookingChange = debounce((event, value) => {

        if (!hasLoadedBooking) {
            setHasLoadedBooking(true)
            return;
        }
        const name = value !== 'undefined' ? value : ''
        formik.setFieldValue('container', null);
        setContainerData([])
        dispatch(auctionsbooking({ name }));

    }, 700);





    const handleContainerDataOnFocus = useCallback(() => {
        if (!hasApiBeenCalled) {

            const bookingId = formik.values?.booking?.id || null



            if (bookingId !== null) {

                dispatch(containers({ name: '', bookingId }));
                setHasApiBeenCalled(true);
            }


        }
    }, [dispatch, hasApiBeenCalled]);




    const handleBookingDataOnFocus = useCallback(() => {
        if (!hasApiBookingBeenCalled) {
            dispatch(auctionsbooking({ name: '' }));
            setHasApiBookingBeenCalled(true);
            formik.setFieldValue('container', null);
            setContainerData([])
        }
    }, [dispatch, hasApiBookingBeenCalled]);



    const disabledWhereHouse = isAbleEdit ? false : formik?.values?.status.id !== 8;


    const disabledBooked =  isAbleEdit ? false : formik?.values?.status.id !== 7 && formik?.values?.status.id !== 2



    useEffect(() => {
        setSelectedOption(formik.values.container)
    }, [formik.values.container])




    useEffect(() => {

        setSelectedBookingOption(formik.values.booking)
    }, [formik.values.booking])







    return (
        <>

            {formik.values?.status?.id !== 1 && formik.values?.status?.id !== 6 ?

                <Box>

                    <PageHeader title={t('wareHouseLoadInfo')} width={"80%"} />
                    <Box display="grid" margin="64px 49px" gridTemplateColumns="repeat(4, 1fr)" gap="30px">
                        <FormControl disabled={disabledWhereHouse} sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center'
                        }}>
                            <FormLabel sx={{
                                marginRight: 2
                            }} >{t('title')}</FormLabel>
                            <RadioGroup
                                row
                                name="title"
                                value={formik.values.title}
                                onChange={() => formik.setFieldValue("title", !formik.values.title)}

                            >
                                <FormControlLabel value={true} control={<Radio />} label={t('yes')} />
                                <FormControlLabel value={false} control={<Radio />} label={t('no')} />
                            </RadioGroup>
                        </FormControl>
                        <FormControl disabled={disabledWhereHouse}>
                            <InputLabel>{t('titleType')}</InputLabel>
                            <Select
                                name="titleType"
                                error={formik.touched.titleType && Boolean(formik.errors.titleType)}


                                value={formik.values.titleType?.id || ''} // Use optional chaining and nullish coalescing

                                onChange={(event) => {
                                    const selectedValue = event.target.value;
                                    const selectedOption = mainData.titleTypeList.find((item) => item.id === selectedValue);
                                    formik.setFieldValue('titleType', selectedOption || null);

                                }}

                            >
                                {mainData.titleTypeList && mainData.titleTypeList.map(item => (
                                    <MenuItem key={item.id} value={item.id}>{item.name}</MenuItem>

                                ))}
                            </Select>
                            {formik.touched.titleType && formik.errors.titleType && (
                                <FormHelperText error>{formik.errors.titleType}</FormHelperText>
                            )}
                        </FormControl>

                        <FormControl disabled={disabledWhereHouse} >
                            <InputLabel>{t('titleProblem')}</InputLabel>
                            <Select
                                name='titleProblem'
                                error={formik.touched.titleProblem && Boolean(formik.errors.titleProblem)}
                                value={formik.values.titleProblem?.id || ''} // Use optional chaining and nullish coalescing
                                onChange={(event) => {
                                    const selectedValue = event.target.value;
                                    const selectedOption = mainData.titleProblemList.find((item) => item.id === selectedValue);
                                    formik.setFieldValue('titleProblem', selectedOption || null);

                                }}


                            >

                                {mainData.titleProblemList && mainData.titleProblemList.map(item => (
                                    <MenuItem value={item.id} key={item.id}>{item.name}</MenuItem>

                                ))}
                            </Select>

                            {formik.touched.titleProblem && formik.errors.titleProblem && (
                                <FormHelperText error>{formik.errors.titleProblem}</FormHelperText>
                            )}
                        </FormControl  >
                        <FormControl disabled={disabledWhereHouse} sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center'
                        }}>
                            <FormLabel sx={{
                                marginRight: 2
                            }} >{t('key')}</FormLabel>
                            <RadioGroup
                                row
                                aria-labelledby="demo-radio-buttons-group-label"
                                defaultValue={formik.values.key}

                                onChange={(e) => {
                                    formik.setFieldValue('key', !formik.values.key)
                                }}
                                name="key"
                            >
                                <FormControlLabel value={true} control={<Radio />} label={t('yes')} />
                                <FormControlLabel value={false} control={<Radio />} label={t('no')} />
                            </RadioGroup>
                        </FormControl>
                    </Box>

                    <Box display={'grid'} margin='49px'>
                        <TextareaAutosize disabled={disabledWhereHouse}
                            name='noteForTitle'
                            style={{
                                background: "#FFFFFF",
                                // boxShadow: '0px 10px 30px #00000029',
                                minHeight: "110px",
                                border: '1px solid #D9DCE1',
                                borderRadius: '10px',
                                padding: '10px',
                                resize: 'none'
                            }}
                            placeholder={t('noteForTitle')}
                            value={formik.values.noteForTitle}
                            onChange={formik.handleChange}
                        />
                    </Box>

                    <Box display="grid" margin="64px 49px" gridTemplateColumns="repeat(4, 1fr)" gap="30px">


                        <Box sx={{ display: "flex", alignItems: 'center', }}>
                            <Checkbox

                                checked={formik.values?.doNotLoadThisUnit || false}
                                disabled={disabledWhereHouse}

                                onChange={(e) => {
                                    formik.setFieldValue('doNotLoadThisUnit', e.target.checked)
                                }} name='doNotLoadThisUnit'
                                aria-label="check" sx={{ '& .MuiSvgIcon-root': { fontSize: 28 } }} />
                            <Typography>{t('doNotLoadThisUnit')}</Typography>
                        </Box>


                        <Box sx={{ display: "flex", alignItems: 'center' }}>
                            <Checkbox

                                checked={formik.values?.removeEngine || false}
                                disabled={disabledWhereHouse}

                                onChange={(e) => {
                                    formik.setFieldValue('removeEngine', e.target.checked)
                                }} name='removeEngine' aria-label="check" sx={{ '& .MuiSvgIcon-root': { fontSize: 28 }, paddingLeft: '5px' }} />
                            <Typography>{t('removeEngine')}</Typography>
                        </Box>
                    </Box>
                </Box>



                : ''}



            {formik.values?.status?.id !== 1 && formik.values?.status?.id !== 6 && formik.values?.status?.id !== 8 ?
                <Box>
                    <PageHeader title={t('wareHouseLoadInfo')} width={"80%"} />


                    <Paper variant='p3'>
                        <Box display="grid" margin="74px auto" gridTemplateColumns="repeat(3, 1fr)" gap="30px">
                            <Autocomplete disabled={disabledBooked}
                                disablePortal
                                id="combo-box-demo"
                                onFocus={handleBookingDataOnFocus}
                                value={selectedBookingOption !== null ? selectedBookingOption : ''}
                                onInputChange={handleBookingChange}
                                clearText="Clear"
                                onChange={(e, v) => {

                                    formik.setFieldValue('container', null);
                                    setContainerData([])
                                    if (!(!!v)) {
                                        formik.setFieldValue('booking', null);
                                        return;
                                    };
                                    const selected = bookingData.find((item) => item.id === v.id);
                                    formik.setFieldValue('booking', selected)



                                }}

                                options={bookingData}
                                getOptionLabel={(option, value) => {

                                    return option.number
                                }}

                                loading={isLoadingBookingData === 'pending'}
                                renderInput={(params) => <TextField {...params} label={t('bookingNumber')} InputProps={{
                                    ...params.InputProps,
                                    endAdornment: (
                                        <>
                                            {isLoadingBookingData === 'pending' ? <CircularProgress color="error" size={20} /> : null}
                                            {params.InputProps.endAdornment}
                                        </>
                                    ),
                                }} />}

                            />




                            <TextField
                                name='ssLineName'
                                disabled
                                label={t('SSLineName')}
                                variant="outlined"

                                value={selectedBookingOption?.ssLineName || ''}

                            />
                            <TextareaAutosize
                                disabled
                                name='generalNote'
                                style={{
                                    background: "#FFFFFF",
                                    minHeight: "55px",
                                    border: '1px solid #D9DCE1',
                                    borderRadius: '10px',
                                    padding: '10px',
                                    resize: 'none'
                                }}
                                placeholder={t('generalNote')}

                                value={
                                    selectedOption?.bookings &&
                                        selectedOption?.bookings.length > 0 &&
                                        selectedOption.bookings[0]?.note
                                        ? selectedOption.bookings[0]?.note
                                        : ""
                                }


                                onChange={formik.handleChange}
                            />

                            {/*  */}
                        </Box>








                        <Box display="grid" margin="74px auto" gridTemplateColumns="repeat(4, 1fr)" gap="30px">

                            <TextField
                                name='consignees'
                                label={t('consigneeName')}
                                variant="outlined" disabled
                                value={selectedOption?.consignee?.name || ''} />







                            <Autocomplete

                                disabled={selectedBookingOption === null || disabledBooked}
                                disablePortal
                                id="combo-box-demo"
                                onFocus={handleContainerDataOnFocus}
                                value={selectedOption !== null ? selectedOption : ''}
                                onInputChange={handleInputChange}
                                clearText="Clear"
                                onChange={(e, v) => {
                                    if (!(!!v)) {
                                        formik.setFieldValue('container', null);
                                        return;
                                    };
                                    const selected = containerData.find((item) => item.id === v.id);
                                    formik.setFieldValue('container', selected)
                                }}
                                options={containerData}


                                getOptionSelected={(option, value) => option.containerNumber === value.containerNumber}

                                getOptionLabel={(option) => option.containerNumber}

                                loading={isLoadingContainerData === 'pending'}
                                renderInput={(params) => <TextField {...params} label={t('containerNumber')} InputProps={{
                                    ...params.InputProps,
                                    endAdornment: (
                                        <>
                                            {isLoadingContainerData === 'pending' ? <CircularProgress color="error" size={20} /> : null}
                                            {params.InputProps.endAdornment}
                                        </>
                                    ),
                                }} />}

                            />


                            <FileUpload label={t('uploadBol')} disabled={true} formikName={'container.bolFIle'} uploadName="containerBolFile" />


                            <FormControl sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                                background: '#F8F8F8'

                            }}>
                                <FormLabel sx={{
                                    marginRight: 2,
                                }} >{t('containerSize')}</FormLabel>
                                <RadioGroup
                                    row
                                    value={formik.values?.container?.containerSize || ''}

                                    name="containerSize"
                                >
                                    <FormControlLabel disabled value={40} control={<Radio />} label={40} />
                                    <FormControlLabel disabled value={45} control={<Radio />} label={45} />
                                </RadioGroup>
                            </FormControl>

                        </Box>
                        <Box display="grid" margin="74px auto" gridTemplateColumns="repeat(4, 1fr)" gap="30px">
                            <Box sx={{
                                display: "flex",
                                alignItems: 'center',

                            }}>
                                <Checkbox
                                    disabled
                                    checked={selectedOption?.partsInContainer || false}
                                    onChange={formik.handleChange} name='partsInContainer' aria-label="check" sx={{ '& .MuiSvgIcon-root': { fontSize: 28 }, paddingLeft: '5px' }} />
                                <Typography>{t('partsInContainer')}</Typography>
                            </Box>

                            <Box sx={{
                                display: "flex",
                                alignItems: 'center',

                            }}>
                                <Checkbox
                                    disabled
                                    checked={selectedOption?.consolidateShipment || false}
                                    name='container.consolidateShipment'
                                    aria-label="check"
                                    sx={{ '& .MuiSvgIcon-root': { fontSize: 28 }, paddingLeft: '5px' }}
                                />
                                <Typography>{t('consolidateShipment')}</Typography>
                            </Box>
                            <Box sx={{
                                display: "flex",
                                alignItems: 'center',

                            }}>



                                <Checkbox
                                    disabled
                                    checked={selectedOption?.loaded3Cars || false}
                                    name='container.loaded3Cars'
                                    aria-label="check"
                                    sx={{ '& .MuiSvgIcon-root': { fontSize: 28 }, paddingLeft: '5px' }}
                                />


                                <Typography>{t('containerLoaded3Cars')}</Typography>
                            </Box>
                        </Box>
                        <Box display="grid" margin="74px auto" gridTemplateColumns="repeat(4, 1fr)" gap="30px">

                            <TextField name='eta' label={t('eta')} variant="outlined" disabled value={selectedBookingOption?.etaDate || ''} />
                            <TextField name='receivedReleaseDate' label={t('receivedReleaseDate')} variant="outlined" disabled value={selectedOption?.receivedRelease || ''} />
                            <TextField name='deliveredDateToFZ' label={t('deliveredDateToFZ')} variant="outlined" disabled value={selectedOption?.delivered || ''} />
                            <TextField name='invoiceShippingNo' label={t('QBInvoiceShippingNo')} variant="outlined" value={formik.values.invoiceShippingNo} onChange={formik.handleChange} />

                        </Box>

                    </Paper>
                </Box>
                : ''}
            <PageHeader title={t('ContractInformation')} width={"80%"} />

            <Box display="grid" margin="64px 49px" gridTemplateColumns="repeat(4, 1fr)" gap="30px">
                <TextField name='contractNumber' label={t('contractNumberFZ')} variant="outlined" value={formik.values.contractNumber} onChange={formik.handleChange} />
                <FileUpload label={t('uploadContract')} uploadName="Contract" formikName='contractFile' />
                <FileUpload label={t('uploadCarfax')} uploadName="Carfax" formikName='carFax' />

                <Box sx={{
                    display: "flex",
                    alignItems: 'center'

                }}>
                    <Checkbox
                        checked={formik.values?.checkedInShowroom || false}

                        onChange={formik.handleChange} name='checkedInShowroom' aria-label="check" sx={{ '& .MuiSvgIcon-root': { fontSize: 28 }, paddingLeft: '5px' }} />
                    <Typography>{t('checkedInShowroom')}</Typography>

                </Box>

            </Box>

        </>
    )

}


export default WareHouseLoadInfo;