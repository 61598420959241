import React, { useState } from 'react';
import { useTheme } from '@mui/material/styles';
import { useNavigate, useSearchParams } from 'react-router-dom';
import {
  Box,
  Collapse,
  FormControlLabel,
  Paper,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Typography,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import CheckIcon from '@mui/icons-material/Check';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import CollectionsIcon from '@mui/icons-material/Collections';
import IconButton from '@mui/material/IconButton';
import LastPageIcon from '@mui/icons-material/LastPage';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import { visuallyHidden } from '@mui/utils';
import { t } from 'i18next';
import { useDispatch, useSelector } from 'react-redux';
import Loader from '../../../../components/Loader';
import PreviewPicture from '../../../../components/PreviewPic/PreviewPicture';
import HistoryIcon from '@mui/icons-material/History';
import { resetState } from '../../../../slices/vehicleInfoSlice';
import TravelExploreIcon from '@mui/icons-material/TravelExplore';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
// headCells configuration for table columns
const headCells = [
  { id: 'v.id', numeric: true, disablePadding: false, label: '' },
  { id: 'v.id', numeric: true, disablePadding: false, label: 'ID' },

  { id: 'purchaseDate', numeric: false, disablePadding: false, label: 'Purchased Date' },
  { id: 'auction', numeric: false, disablePadding: false, label: 'Auction' },
  { id: 'client', numeric: false, disablePadding: false, label: 'Client Name' },
  { id: 'lot', numeric: true, disablePadding: false, label: 'LOT' },
  { id: 'vin', numeric: false, disablePadding: false, label: 'VIN' },
  { id: 'model', numeric: false, disablePadding: false, label: 'Description' },
  { id: 'status', numeric: false, disablePadding: false, label: 'Status' },
  { id: 'orderId', numeric: true, disablePadding: false, label: 'Order Id' },
  { id: 'warehouse', numeric: false, disablePadding: false, label: 'W.H Name' },
  { id: 'title', numeric: false, disablePadding: false, label: 'Title' },
  { id: 'port', numeric: false, disablePadding: false, label: 'Port' },
  { id: 'destination', numeric: false, disablePadding: false, label: 'destination' },
  { id: 'v.id', numeric: false, disablePadding: false, label: 'Picture' },

  { id: 'v.id', numeric: false, disablePadding: false, label: 'Actions' },




];
// Create a data object from input fields

function createData(id, purchaseDate, auction, client, lot, vin, description, status, orderId, wherhouse, title, port, destination, picture, container ,  pickupLocation , key , color , buyerNumber,booking) {
  return { id, purchaseDate, auction, client, lot, vin, description, status, orderId, wherhouse, title, port, destination, picture, container , pickupLocation , key , color , buyerNumber, booking };
}
// Component for pagination actions in the table






const CollapseView = ({Row}) => {

  const data= Row?.row
  
  console.log("Row",Row)



  const bookingNumber = data?.booking?.number 
  const eta = data?.booking?.etaDate
  const port  = data?.port
  const pickupLocation = data?.pickupLocation
  const destination = data?.destination
  const d_date =  data?.container?.delivered
  const color = data?.color?.name ; 
  const buyerNumber = data?.buyerNumber?.name ; 





  const RowView = ({ textAlign, variant, value }) => (

    <Typography variant={variant} sx={{ textAlign: textAlign, borderBottomStyle: "solid", borderWidth: "thin", width: "100%" , color:'secondary.main' , minHeight:27}}>
      {value ? value : "***"}
    </Typography>

  )



  return <Box sx={{ width: "100%", display: "flex", gap: 2, justifyContent: "start",  m:2 , backgroundColor:'colorA.main' }}>

    <Box sx={{ display: "flex", width: "35%", m: 2 }}>



      <Box sx={{ display: "flex", width: "47%", flexDirection: "column", alignItems: "center", justifyContent: "start", gap: 1 }}>


        <RowView variant='button' textAlign="center" value="INFO" />


        <RowView variant='body1' textAlign="start" value="BOOKING" />

        <RowView variant='body1' textAlign="start" value="ETA" />


        <RowView variant='body1' textAlign="start" value="PORT" />



        <RowView variant='body1' textAlign="start" value="PICKUP LOCATION" />



        <RowView variant='body1' textAlign="start" value="DESTINATION" />







      </Box>

      <Box sx={{ width: "1px", backgroundColor: "secondary.main", mr: 1, ml: 1 }} />

      <Box sx={{ display: "flex", width: "47%", flexDirection: "column", alignItems: "start", justifyContent: "start", gap: 1 }}>

        <RowView variant='button' textAlign="center" value="DETAILS" />


        <RowView variant='body1' textAlign="start" value={bookingNumber} />

        <RowView variant='body1' textAlign="start" value={eta} />


        <RowView variant='body1' textAlign="start" value={port} />



        <RowView variant='body1' textAlign="start" value={pickupLocation}/>



        <RowView variant='body1' textAlign="start" value={destination}  />

      </Box>
    </Box>


    <Box sx={{ width: "1px", backgroundColor: "secondary.main", mt:4, mb:4}} />
    <Box sx={{ width: "1px", backgroundColor: "secondary.main", mt:4 , mb:4}} />


    <Box sx={{ display: "flex", width: "35%", m: 2 }}>



      <Box sx={{ display: "flex", width: "47%", flexDirection: "column", alignItems: "center", justifyContent: "start", gap: 1 }}>


        <RowView variant='button' textAlign="center" value="INFO" />


        <RowView variant='body1' textAlign="start" value="KEY" />

        <RowView variant='body1' textAlign="start" value="TITLE" />


        <RowView variant='body1' textAlign="start" value="DEDATE" />



        <RowView variant='body1' textAlign="start" value="COLOR" />










      </Box>

      <Box sx={{ width: "1px", backgroundColor: "secondary.main", mr: 1, ml: 1 }} />

      <Box sx={{ display: "flex", width: "47%", flexDirection: "column", alignItems: "start", justifyContent: "start", gap: 1 }}>

        <RowView variant='button' textAlign="center" value="DETAILS" />


        <RowView variant='body1' textAlign="start"  value={data.key ? <CheckIcon fontSize='small'  /> : <CloseIcon fontSize='small' />} />

        <RowView variant='body1' textAlign="start" value={data.title ? <CheckIcon fontSize='small' /> : <CloseIcon  fontSize='small'/>} />


        <RowView variant='body1' textAlign="start" value={d_date}/>



        <RowView variant='body1' textAlign="start" value={color} />




      </Box>
    </Box>
    <Box sx={{ width: "1px", backgroundColor: "secondary.main", mt:4, mb:4}} />
    <Box sx={{ width: "1px", backgroundColor: "secondary.main", mt:4 , mb:4}} />
    <Box sx={{ display: "flex", width: "35%", m: 2 }}>



<Box sx={{ display: "flex", width: "47%", flexDirection: "column", alignItems: "center", justifyContent: "start", gap: 1 }}>


  <RowView variant='button' textAlign="center" value="INFO" />


  <RowView variant='body1' textAlign="start" value="CONTAINER NUMBER" />

  <RowView variant='body1' textAlign="start" value="BUYER NUMBER" />


  <RowView variant='body1' textAlign="start" value="SS LINE" />



  <RowView variant='body1' textAlign="start" value="WAREHOUSE" />










</Box>

<Box sx={{ width: "1px", backgroundColor: "secondary.main", mr: 1, ml: 1 }} />

<Box sx={{ display: "flex", width: "47%", flexDirection: "column", alignItems: "start", justifyContent: "start", gap: 1 }}>

  <RowView variant='button' textAlign="center" value="DETAILS" />


  <RowView variant='body1' textAlign="start"  value={data?.container?.containerNumber  } />

  <RowView variant='body1' textAlign="start" value={buyerNumber} />


  <RowView variant='body1' textAlign="start" value={data?.booking?.ssLineName}/>



  <RowView variant='body1' textAlign="start" value={data?.wherhouse} />




</Box>
</Box>
  </Box>


}







function TablePaginationActions(props) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handlePageChange = (event, newPage) => {
    onPageChange(event, newPage);
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton onClick={(event) => handlePageChange(event, 0)} disabled={page === 0} aria-label="first page">
        {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton onClick={(event) => handlePageChange(event, page - 1)} disabled={page === 0} aria-label="previous page">
        {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
      </IconButton>
      <IconButton
        onClick={(event) => handlePageChange(event, page + 1)}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
      </IconButton>
      <IconButton
        onClick={(event) => handlePageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1))}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  );
}
// Component for the table head with sorting functionality

function EnhancedTableHead(props) {
  const { order, orderBy, onRequestSort } = props;

  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell, index) => (
          <TableCell
            key={index}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
            variant="head"
          >
            <TableSortLabel active={orderBy === headCell.id} direction={orderBy === headCell.id ? order : 'asc'} onClick={createSortHandler(headCell.id)}>
              {t(headCell.label).toUpperCase()}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}
// Main component for the DataGridTable

const VehiclesTable = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();

  const fetchData = useSelector((state) => state.fetchData);
  const [openPreviewPicture, setOpenPreviewPicture] = useState(false);
  const [pictureFile, setPictureFile] = useState([]);
  const [openArray, setOpenArray] = useState({ Name: [] });

  const dispatch = useDispatch();


  const data = fetchData.Object || {};



  const content = data.content || [];


  // URL query parameters and their setters
  const page = Number(searchParams.get('PageNumber')) || 0;
  const rowsPerPage = Number(searchParams.get('PageSize')) || 5;
  const order = searchParams.get('SortOrder') || 'desc';
  const orderBy = searchParams.get('SortBy') || 'id';

  const setPage = (value) => {
    searchParams.set('PageNumber', value);
    setSearchParams(searchParams);
  };

  const setRowsPerPage = (value) => {
    searchParams.set('PageSize', value);
    setSearchParams(searchParams);
  };

  const setOrder = (value) => {
    searchParams.set('SortOrder', value);
    setSearchParams(searchParams);
  };

  const setOrderBy = (value) => {
    searchParams.set('SortBy', value);
    setSearchParams(searchParams);
  };

  const [dense, setDense] = useState(true);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };



  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangeDense = (event) => {
    setDense(event.target.checked);
  };




  const handleClose = () => {
    setOpenPreviewPicture(false)
    setPictureFile([])
  };


  const handelOpen = (file) => {
    setPictureFile(file)
    setOpenPreviewPicture(true)

  }



  const handelEdit = (id) => {
    dispatch(resetState());
    navigate(`/VehicleaddEdit?vehicleId=${id}&backLink=` + searchParams.toString().replaceAll("&", "*"))
  }



  const handelVewContainer = (id) => {
    navigate(`/container/containerlist?Container=${id}`)
  }


  const handelVewLogs = (id) => {
    navigate(`/log?VehiclesId=${id}`)
  }


  // Table data preparation
  const rows = content.map((e) => {

    console.log("e111111",e)


    const dispatchInformations = e.dispatchInformations;
    const towingInformation = e.towingInformation;


    let whereHouse = '';
    let port = '';
    let destination = '';
    let vehicleType = ''
    let make = '';
    let model = '';
    let orderId = '';

    if (dispatchInformations.length > 0) {

      if (dispatchInformations[0].warehouse !== null) {
        whereHouse = dispatchInformations[0].warehouse.name;
      }


      if (dispatchInformations[0].port !== null) {
        port = dispatchInformations[0].port.name;
      }


      if (dispatchInformations[0].destination !== null) {
        destination = dispatchInformations[0].destination.name;
      }

      if (dispatchInformations[0].orderId !== null) {
        orderId = dispatchInformations[0].orderId;
      }

    }

    if (towingInformation.length > 0) {

      if (towingInformation[0].vehicleType !== null) {


        vehicleType = towingInformation[0].vehicleType.name;
      }
    }

    if (e.make != null) {
      make = e.make.name;
    }

    if (e.model != null) {
      model = e.model.name;
    }


    const description = `${e.year} ${make} ${model} ${vehicleType}`


    return createData(e.id, e.purchaseDate, e.auctions.name, e.client.name, e.lot, e.vin, description, e.status.name, orderId, whereHouse, e.title, port, destination, e.warehousePictures,  e.container, e.pickupLocation , e.key , e.color , e.buyerNumber, e?.booking
    );
  });


  const openCollapse = (index) => {


    if (checkIOpen(index)) {
      const value = openArray.Name.indexOf(index)
      const lastArray = openArray.Name;
      lastArray.splice(value, 1); // 2nd parameter means remove one item only
      setOpenArray({ Name: lastArray })


    } else {

      const lastArray = openArray.Name;
      lastArray.push(index);

      setOpenArray({ Name: lastArray })

    }



  }


  const checkIOpen = (index) => {


    let resutt;
    const value = openArray.Name.indexOf(index)
    if (value > -1) {


      resutt = true;
    } else {

      resutt = false;

    }



    return resutt;

  }

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - data.totalElements) : 0;



  if (data.loading === 'pending' || data.loading === 'idle') {
    return <Loader />
  }



  return (
    <Box sx={{ width: '100%' }}>
      <Paper sx={{ width: '100%', mb: 2 }}>
        <TableContainer>
          <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle" size={dense ? 'small' : 'medium'}>
            <EnhancedTableHead
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              rowCount={rows.length}
            />
            <TableBody>
              {rows.map((row, index) => (
                <React.Fragment key={index}>
                  <TableRow sx={{backgroundColor :  index % 2 === 0 ? '#F5F5F5' :'secondary.main' }}  tabIndex={-1} key={index}>
                    <TableCell>

                      <IconButton
                        aria-label="expand row"
                        size="small"
                        onClick={() => openCollapse(index)}
                      >
                        {checkIOpen(index) ? <KeyboardArrowUpIcon/> : <KeyboardArrowDownIcon />}
                      </IconButton>



                    </TableCell>



                    <TableCell>


                      {row.id}


                    </TableCell>
                    <TableCell  >{row.purchaseDate}</TableCell>
                    <TableCell >{row.auction}</TableCell>
                    <TableCell >{row.client}</TableCell>
                    <TableCell >{row.lot}</TableCell>
                    <TableCell>{row.vin}</TableCell>
                    <TableCell>{row.description}</TableCell>
                    <TableCell>{row.status}</TableCell>
                    <TableCell >{row.orderId}</TableCell>
                    <TableCell >{row.wherhouse}</TableCell>
                    <TableCell>{row.title ? <CheckIcon /> : <CloseIcon />}</TableCell>
                    <TableCell >{row.port}</TableCell>
                    <TableCell >{row.destination}</TableCell>

                    <TableCell>

                      {row.picture.length !== 0 ?
                        <IconButton onClick={() => handelOpen(row.picture)} aria-label="view" title="view">
                          <CollectionsIcon />
                        </IconButton>

                        : <CloseIcon />}

                    </TableCell>



                    <TableCell >
                      <Box sx={{ display: "flex" }}>
                        <IconButton onClick={() => handelEdit(row.id)} aria-label="Edit" title='Edit'>
                          <ModeEditIcon />
                        </IconButton>
                        {row.container &&
                          <IconButton alt="logs" onClick={() => handelVewContainer(row.container.id)} aria-label="View Container info" title="View Container">
                            <TravelExploreIcon />
                          </IconButton>
                        }
                        <IconButton alt="logs" onClick={() => handelVewLogs(row.id)} aria-label="History Logs" title="History Logs">
                          <HistoryIcon  />
                        </IconButton>




                      </Box>
                    </TableCell>

                  </TableRow>









                  <TableRow>
                    <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={16}>
                      <Collapse in={checkIOpen(index)} timeout="auto" unmountOnExit>
                        <CollapseView Row={{row}} />
                      </Collapse>
                    </TableCell>



                  </TableRow>



                </React.Fragment>
              ))}
              {emptyRows > 0 && (
                <TableRow style={{ height: (dense ? 33 : 53) * emptyRows }}>
                  <TableCell colSpan={5} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25, 50]}
          component="div"
          count={data.totalElements}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          SelectProps={{
            inputProps: { 'aria-label': 'rows per page' },
            native: true,
          }}
          ActionsComponent={TablePaginationActions}
        />
      </Paper>
      <FormControlLabel control={<Switch checked={dense} onChange={handleChangeDense} />} label={t('Densepadding')} />


      {openPreviewPicture && (
        <PreviewPicture
          open={openPreviewPicture}
          handleClose={handleClose}
          label={'PreviewPicture'}
          file={pictureFile}
        />
      )}


    </Box>
  );
};

export default VehiclesTable;
