export const apiRoute = {
    login: "api/account/login",
    upload: "api/upload/",
    verificationValues: "api/verificationValues",
    auctionsLocationsBuyerNumber: "/api/verificationValues/auctionsLocations&BuyerNumber?auctionId=",
    models: "/api/verificationValues/models?makeId=",
    container: "/api/container/search?term=",
    booking: "/api/booking/search?term=",
    addVehicle: "/api/vehicle",
    getVehicleInfo: "/api/vehicle/",
    getFetchData: "/api/vehicle/page",
    getFetchDataExcel: "/api/vehicle/excel",
    getFetchExcelContainer: "/api/container/excel?Container=",
    getContainerCount: "/api/container/count",

    getVehicleCount: "/api/vehicle/count",
    getManage: '/api/',
    getUserData: 'api/user/all',
    getLogsData: 'api/log/page',
    updateDeleteAddManage: "/api/",
    logout: "/api/account/logout",
    changeAccountPassword: "/api/account/changePassword",
    changeAccountPasswordClient:"/api/account/changePasswordClient",
    consignee: "/api/consignee/search?term=",
    client: "/api/client/search?term=",

    clientViewInitial: "/api/clientView/verificationValues",
    vehiclesMobile: "/api/clientView/vehiclesMobile"



}


export const baseUrl= "http://api.cms.gojoshipping.com";

export const baseUrlTest  = "http://api.cms.gojoshipping.com:6564";