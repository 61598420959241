/* eslint-disable react-hooks/exhaustive-deps */
import { t } from "i18next"
import PageHeader from "../../../components/PageHeader/PageHeader"
import { Box, Button, Checkbox, FormControl, InputLabel, MenuItem, Select, TextField, Typography } from "@mui/material"
import { useEffect, useState } from "react";
import FileUpload from "../../../components/FileUpload";
import DeleteIcon from '@mui/icons-material/Delete';
import { useSelector } from "react-redux";
import { useFormikContext } from "formik";
const DispatchInfo = ({isAbleEdit}) => {


    const formik = useFormikContext();



    const formReducser = useSelector((state) => state.form);


    const [mainData, setMainData] = useState({})

    useEffect(() => {
        if (formReducser.loading === 'succeeded') {
            setMainData(formReducser.data)
        }

    }, [formReducser])




    const initialRecord = {

        id: 0,
        orderId: '',
        warehouse: null,
        port: null,
        destination: null,
        driverBolFile: [],

    }



    useEffect(()=>{

        if (formik?.values?.dispatchInformation.length === 0 &&  formik?.values?.status.id === 6 ){
            handleDuplicateRecord();
        }


    },[formik.values])


    const disabled = isAbleEdit ? false : formik?.values?.status.id !== 6;


    const handleDuplicateRecord = () => {
        formik.setFieldValue("dispatchInformation", [...formik.values.dispatchInformation, initialRecord])

    };
    const handleDeleteRecord = (index) => {
        const updatedRecords = [...formik.values.dispatchInformation];
        updatedRecords.splice(index, 1);
        formik.setFieldValue("dispatchInformation", updatedRecords);

    }
    return (
        <>
            <PageHeader title={t('dispatchInfo')} />
            <Box display="grid" margin="64px 49px" gridTemplateColumns="repeat(2, 1fr) " gap="30px">
                <Box sx={{
                    display: 'flex',
                    gap: "30px"
                }}>
                    <Box sx={{
                        display: "flex",
                        alignItems: 'center',
                    }}>
                        <Checkbox disabled={disabled} onChange={formik.handleChange}
                        
                        
                        checked={formik.values?.buyerDelivery || false}

                        
                        name='buyerDelivery' aria-label="check" sx={{ '& .MuiSvgIcon-root': { fontSize: 28 } }} />
                        <Typography>{t('buyerDelivery')}</Typography>

                    </Box>
                    <Box sx={{
                        display: "flex",
                        alignItems: 'center',
                    }}>
                        <Checkbox   disabled={disabled}
                        checked={formik.values?.partsWithCarInAuction || false}

                        name="partsWithCarInAuction" onChange={formik.handleChange} aria-label="check" sx={{ '& .MuiSvgIcon-root': { fontSize: 28 } }} />
                        <Typography>{t('partsWithCarInAuction')}</Typography>
                    </Box>
                </Box>

                <Box display='grid' justifyContent='end'>
                    <Button  disabled={disabled} variant='b2' sx={{ borderRadius: '10px' }} onClick={handleDuplicateRecord}>Add New Record</Button>
                </Box>

            </Box>



            {formik.values.dispatchInformation.map((item, index) => {
                return (
                    <Box key={index} display="grid" margin="64px 49px" gridTemplateColumns={"repeat(5, 1fr) 20px"} gap="20px">
                        <TextField  disabled={disabled} value={formik.values.dispatchInformation[index].orderId} onChange={formik.handleChange} name={`dispatchInformation.${index}.orderId`} label={t('orderId')} variant="outlined" />
                        <FormControl   disabled={disabled}>
                            <InputLabel>{t('warehouseName')}</InputLabel>
                            <Select
                                name={`dispatchInformation.${index}.warehouse`}
                                value={formik.values.dispatchInformation[index].warehouse?.id || ''} // Use optional chaining and nullish coalescing
                                onChange={(event) => {
                                    const selectedValue = event.target.value;
                                    const selectedOption = mainData.warehouseList.find((item) => item.id === selectedValue);
                                    formik.setFieldValue(`dispatchInformation.${index}.warehouse`, selectedOption || null)

                                }}


                            >
                                {mainData.warehouseList && mainData.warehouseList.map(item => (
                                    <MenuItem value={item.id} key={item.id}>{item.name}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>

                        <FormControl  disabled={disabled} >
                            <InputLabel>{t('port')}</InputLabel>
                            <Select
                                name={`dispatchInformation.${index}.port`}

                                value={formik.values.dispatchInformation[index].port?.id || ''} // Use optional chaining and nullish coalescing
                                onChange={(event) => {
                                    const selectedValue = event.target.value;
                                    const selectedOption = mainData.portList.find((item) => item.id === selectedValue);
                                    formik.setFieldValue(`dispatchInformation.${index}.port`, selectedOption || null)

                                }}





                            >
                                {mainData.portList && mainData.portList.map(item => (
                                    <MenuItem value={item.id} key={item.name}>{item.name}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>

                        <FormControl  disabled={disabled}>
                            <InputLabel>{t('destination')}</InputLabel>
                            <Select
                                name={`dispatchInformation.${index}.destination`}

                                value={formik.values.dispatchInformation[index].destination?.id || ''} // Use optional chaining and nullish coalescing
                                onChange={(event) => {
                                    const selectedValue = event.target.value;
                                    const selectedOption = mainData.destinationList.find((item) => item.id === selectedValue);
                                    formik.setFieldValue(`dispatchInformation.${index}.destination`, selectedOption || null)

                                }}

                            >
                                {mainData.destinationList && mainData.destinationList.map(item => (
                                    <MenuItem value={item.id} key={item.id}>{item.name}</MenuItem>

                                ))}
                            </Select>
                        </FormControl>
                        <FileUpload  disabled={disabled}  label={t('driverBOL')} uploadName="BOL" formikName={`dispatchInformation.${index}.driverBolFile`} />
                        <Box sx={{visibility:index !== 1 ||  disabled ?'hidden' : 'visible'}} display='flex' justifyContent={'center'} alignItems={'center'} onClick={() => handleDeleteRecord(index)}>

                            <DeleteIcon sx={{
                                color: 'red',
                                fontSize: '20px'
                            }} />
                        </Box>

                    </Box>
                )
            })}
        </>
    )
}

export default DispatchInfo;