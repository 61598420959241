/* eslint-disable no-mixed-operators */
/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Button, Paper, TextField, TextareaAutosize } from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams, useSearchParams } from "react-router-dom";
import { addManageRefrsh, updateManageByIdRefrsh } from "../../actions/mangeAction";
import CustomToast from "../CustomToast/CustomToast";
import { resetError } from "../../slices/manageSlice";
import { t } from "i18next";
import PageHeaderManage from "../PageHeader/PageHeaderManage";
import CustomDatePicker2 from "../CustomDatePicker/CustomDatePicker2";



const AddBooking = () => {
    const dispatch = useDispatch();
    const { mode } = useParams();
    const [searchParams] = useSearchParams()
    const parentId = searchParams.get('parentId');
    const location = useLocation();
    const state = location.state;







    const { id, note, ssLineName, number, etaDate } = state || {};
    const [value, setValue] = useState({
        note: '',
        ssLineName: '',
        number: '',
        etaDate: null,


    });
    const [error, setError] = useState("");

    const { updated, added, hasError, errorMessage } = useSelector((state) => state.manage)
    const navigation = useNavigate();

    useEffect(() => {
    }, [value])
    useEffect(() => {
        // this for edit
        setValue({
            note, ssLineName, number, etaDate
        });
    }, [state]);



    const handleSave = () => {
        if (id) {
            dispatch(updateManageByIdRefrsh({ mode, id, data: { ssLineName: value.ssLineName, note: value.note, number: value.number, etaDate: value.etaDate } }));
            return;
        }
        dispatch(addManageRefrsh({ mode, data: { ssLineName: value.ssLineName, note: value.note, number: value.number, etaDate: value.etaDate }, parentId }));


    }

    const handleBack = () => {

        navigation(`/${mode}/bookingList` + location.search, {
            state: {
                showSuccess: false
            }
        })

    }
    const handleToastClose = () => {
        dispatch(resetError())
    };
    if (hasError) {
        return (<>
            <CustomToast open={hasError} message={errorMessage} onClose={handleToastClose} />
        </>)

    }
    if (updated || added && !hasError) {
        navigation(`/${mode}/bookingList`, {
            state: {
                showSuccess: true,
                name: value.number
            }
        })
    }


    const handEditEtaDate = (value) => {
        setValue(pre => ({
            ...pre,
            etaDate: value
        }))

    }






    const isButtonDisabled = value.number === '' || value.number === null || value.number === undefined || error



    const handleKeyDown = (event) => {
        if (event.key === 'Enter' && !isButtonDisabled) {
            handleSave();
        }
      };







    return (

        <Paper variant="p1" >

            <PageHeaderManage title={t(mode).toUpperCase()} />
            <div onKeyDown={handleKeyDown}>

            <Box display={'grid'} gap={2} gridTemplateColumns="repeat(3, 1fr)"  >


                <TextField
                    label={t('number')}
                    variant="outlined"
                    onChange={(e) => setValue(pre => ({
                        ...pre,
                        number: e.target.value
                    }))}

                    value={value.number}
                />


                <TextField
                    label={t('ssLineName')}
                    variant="outlined"
                    onChange={(e) => setValue(pre => ({
                        ...pre,
                        ssLineName: e.target.value
                    }))}

                    value={value.ssLineName}
                />
                <CustomDatePicker2 value={value.etaDate} setValue={handEditEtaDate} label={t('EtaDate')} name='etaDate' disableFuture={true} setError={setError} error={error} />


            </Box>
            </div>

            <Box display={'grid'} gridTemplateColumns="repeat(1, 1fr)" marginY={'20px'}>
                <TextareaAutosize
                    name='note'
                    style={{
                        background: "#FFFFFF",
                        // boxShadow: '0px 10px 30px #00000029',
                        minHeight: "110px",
                        border: '1px solid #D9DCE1',
                        borderRadius: '10px',
                        padding: '10px',
                        resize: 'none'
                    }}
                    placeholder={t('note')}
                    value={value.note}

                    onChange={(e) => setValue(pre => ({
                        ...pre,
                        note: e.target.value
                    }))}
                />
            </Box>

            <Box display='flex' alignItems='center' justifyContent="center" >
                <Button variant="b2" sx={{
                    marginInline: '20px'
                }} onClick={handleBack} >Cancel</Button>
                <Button variant="b1" onClick={handleSave} disabled={isButtonDisabled} >Save</Button>


            </Box>

        </Paper >

    )
}

export default AddBooking;