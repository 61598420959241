/* eslint-disable react-hooks/exhaustive-deps */
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';
import WhereToVoteIcon from '@mui/icons-material/WhereToVote';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import {
    Alert,
    Box,
    Button,
    Checkbox,
    Collapse,
    Divider,
    FormControlLabel,
    Paper,
    Switch,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    TableSortLabel,
    Typography,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import CheckIcon from '@mui/icons-material/Check';
import IconButton from '@mui/material/IconButton';
import { useTheme } from '@mui/material/styles';
import { visuallyHidden } from '@mui/utils';
import { t } from 'i18next';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { deleteManageByIdRefrsh, getMangeData, setDeliveredByIdRefrsh } from '../../actions/mangeAction';
import { resetAddedDeleted, resetState, resetState as resetStateColor } from '../../slices/manageSlice';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

import { Delete } from "@mui/icons-material";
import DeleteConfirmationModal from '../DeleteConfirmationModal/DeleteConfirmationModal';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import PageHeaderManage from '../PageHeader/PageHeaderManage';
import ContainerDropdownMenu from './searchOptionComponents/DropdownMenu';
import { resetState as resetVehicleInfo } from '../../slices/vehicleInfoSlice';

import CollectionsIcon from '@mui/icons-material/Collections';

import Progress from '../Loader/Progress ';
import PreviewPicture from '../PreviewPic/PreviewPicture';
import GenerateExcelButton from './searchOptionComponents/GenerateExcelButton';
import CountView from './searchOptionComponents/CountView';
import ArrivedModal from './ArrivedModal';
// headCells configuration for table columns
const headCells = [

    { id: '#', numeric: true, disablePadding: false, label: 'checkAll' },

    { id: 'c.id', numeric: true, disablePadding: false, label: 'ID' },
    { id: 'containerNumber', numeric: false, disablePadding: false, label: 'containerNumber' },
    { id: 'consignee', numeric: false, disablePadding: false, label: 'consignee' },
    { id: 'booking.number', numeric: false, disablePadding: false, label: 'booking_number' },

    { id: 'booking.ssLineName', numeric: false, disablePadding: false, label: 'ssLineName' },
    { id: 'etaDate', numeric: false, disablePadding: false, label: 'etaDate' },
    { id: 'receivedRelease', numeric: false, disablePadding: false, label: 'received_Release' },
    { id: 'delivered', numeric: false, disablePadding: false, label: 'delivered' },
    { id: 'partsInContainer', numeric: false, disablePadding: false, label: 'partsInContainer' },
    { id: 'consolidateShipment', numeric: false, disablePadding: false, label: 'consolidate_Shipment' },
    { id: 'loaded3Cars', numeric: false, disablePadding: false, label: 'loaded3Cars' },
    { id: 'containerSize', numeric: false, disablePadding: false, label: 'containerSize' },
    { id: 'c.id', numeric: false, disablePadding: false, label: 'Actions' },

    { id: 'c.id', numeric: false, disablePadding: false, label: '' },



];
// Create a data object from input fields

function createData(id, containerNumber, consignee, bookingNumber, ssLineName, etaDate, receivedRelease, delivered, partsInContainer, consolidateShipment, loaded3Cars, containerSize, vehicleList
) {
    return {
        id, containerNumber, consignee, bookingNumber, ssLineName, etaDate, receivedRelease, delivered, partsInContainer, consolidateShipment, loaded3Cars, containerSize, vehicleList
    };
}
// Component for pagination actions in the table

function TablePaginationActions(props) {
    const theme = useTheme();
    const { count, page, rowsPerPage, onPageChange } = props;

    const handlePageChange = (event, newPage) => {
        onPageChange(event, newPage);
    };

    return (
        <Box sx={{ flexShrink: 0, ml: 2.5 }}>
            <IconButton onClick={(event) => handlePageChange(event, 0)} disabled={page === 0} aria-label="first page">
                {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
            </IconButton>
            <IconButton onClick={(event) => handlePageChange(event, page - 1)} disabled={page === 0} aria-label="previous page">
                {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
            </IconButton>
            <IconButton
                onClick={(event) => handlePageChange(event, page + 1)}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="next page"
            >
                {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
            </IconButton>
            <IconButton
                onClick={(event) => handlePageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1))}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="last page"
            >
                {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
            </IconButton>
        </Box>
    );
}
// Component for the table head with sorting functionality

function EnhancedTableHead(props) {
    const { order, orderBy, onRequestSort , handCheckAll  , slectedId} = props;


    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };

    return (


        <TableHead>
            <TableRow>
                {headCells.map((headCell, index) => (

                    (headCell.label === "checkAll" ?

                        <TableCell
                            key={index}
                            padding={headCell.disablePadding ? 'none' : 'normal'}
                            variant="head"
                        >
                            <Checkbox
                                color="primary"
                                onClick={()=>handCheckAll()}
                                checked = {slectedId.length !== 0}
                       

                            />
                        </TableCell>



                        :


                        <TableCell
                            key={index}
                            padding={headCell.disablePadding ? 'none' : 'normal'}
                            sortDirection={orderBy === headCell.id ? order : false}
                            variant="head"
                        >
                            <TableSortLabel active={orderBy === headCell.id} direction={orderBy === headCell.id ? order : 'asc'} onClick={createSortHandler(headCell.id)}>
                                {t(headCell.label).toUpperCase()}
                                {orderBy === headCell.id ? (
                                    <Box component="span" sx={visuallyHidden}>
                                        {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                    </Box>
                                ) : null}
                            </TableSortLabel>
                        </TableCell>

                    )



                ))}
            </TableRow>
        </TableHead>
    );
}
// Main component for the DataGridTable



const TableVehicle = (pros) => {


    const Data = pros.data;

    const navigate = useNavigate();
    const [pictureFile, setPictureFile] = useState([]);

    const dispatch = useDispatch();

    const [openPreviewPicture, setOpenPreviewPicture] = useState(false);

    const handleClose = () => {
        setOpenPreviewPicture(false)
        setPictureFile([])
    };


    const handelEdit = (id) => {
        dispatch(resetVehicleInfo());

        navigate(`/VehicleaddEdit?vehicleId=${id}`)
    }


    const handelOpen = (file) => {
        setPictureFile(file)
        setOpenPreviewPicture(true)

    }

    return (

        <Box>

            <TableContainer component={Paper}  >

                <Table aria-label="collapsible table">
                    <TableHead                           >
                        <TableRow sx={{ backgroundColor: 'primary.main' }}>
                            <TableCell sx={{ color: 'secondary.main' }} variant='head'>ID</TableCell>
                            <TableCell sx={{ color: 'secondary.main' }} variant='head'>Purchased Date</TableCell>
                            <TableCell sx={{ color: 'secondary.main' }} variant='head'>Auction</TableCell>
                            <TableCell sx={{ color: 'secondary.main' }} variant='head'>Client Name</TableCell>
                            <TableCell sx={{ color: 'secondary.main' }} variant='head'>LOT</TableCell>
                            <TableCell sx={{ color: 'secondary.main' }} variant='head'>VIN</TableCell>
                            <TableCell sx={{ color: 'secondary.main' }} variant='head'>Description</TableCell>
                            <TableCell sx={{ color: 'secondary.main' }} variant='head'>Picture</TableCell>
                            <TableCell sx={{ color: 'secondary.main' }} variant='head'>View</TableCell>


                        </TableRow>
                    </TableHead>
                    <TableBody>

                        {Data?.map(((items, index) => {

                            let vehicleType = ''
                            let make = '';
                            let model = '';

                            const description = `${items.year} ${make} ${model} ${vehicleType}`

                            return (
                                <TableRow key={`SupActions-${index}}`}
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 }, backgroundColor: index % 2 === 0 ? '#F5F5F5' : 'secondary.main' }}
                                >

                                    <TableCell >{items.id}</TableCell>
                                    <TableCell >{items.purchaseDate}</TableCell>
                                    <TableCell >{items.auction?.name}</TableCell>
                                    <TableCell >{items.client?.name}</TableCell>
                                    <TableCell >{items.lot}</TableCell>
                                    <TableCell >{items.vin}</TableCell>
                                    <TableCell>{description}</TableCell>

                                    <TableCell> <IconButton onClick={() => handelOpen(items.warehousePictures)} aria-label="view" title="view">
                                        <CollectionsIcon />
                                    </IconButton>
                                    </TableCell>
                                    <TableCell> <IconButton onClick={() => handelEdit(items.id)} aria-label="Edit" title='Edit'>
                                        <ModeEditIcon />
                                    </IconButton>
                                    </TableCell>


                                </TableRow>
                            )
                        }



                        ))}



                    </TableBody>
                </Table>
            </TableContainer>
            {openPreviewPicture && (
                <PreviewPicture
                    open={openPreviewPicture}
                    handleClose={handleClose}
                    label={'PreviewPicture'}
                    file={pictureFile}
                />
            )}
        </Box >

    )









}


const ContainerList = () => {

    const { mode } = useParams();
    const reduser = useSelector((state) => state.manage);
    const [searchParams, setSearchParams] = useSearchParams();
    const [isModalOpen, setIsModalOpen] = useState(false);


    const [selectedArrived, setSelectedArrived] = useState(null);


    const [selectedItem, setSelectedItem] = useState({ name: '', id: 0 });
    const navigate = useNavigate();
    const [loading, setLoading] = useState("idle");

    const [openArray, setOpenArray] = useState({ Name: [] });

    const location = useLocation();
    const state = location.state;
    const { name } = state || false;

    const data = reduser.object || {};
    const content = data.content || [];
    const added = reduser.added;
    const deleted = reduser.deleted;
    const dispatch = useDispatch();
    const navigation = useNavigate();
    const [showSuccessAlert, setShowSuccessAlert] = useState(added)
    const [showDeletedAlert, setShowDeletedAlert] = useState(deleted)

    const [slectedId, setSlectedId] = useState([])





    useEffect(() => {

        setLoading(reduser.loading)

    }, [reduser.loading]);



    useEffect(() => {
        dispatch(getMangeData({ mode, query: searchParams.toString() }));

        return () => {

            dispatch(resetStateColor());
            dispatch(resetAddedDeleted())

        }


    }, [mode, dispatch]);

    useEffect(() => {
        setShowSuccessAlert(added);
        setShowDeletedAlert(deleted)
    }, [added, deleted])







    

    const handleCheckedClick = (e, id) => {
        const isChecked = e.target.checked;

        if (isChecked) {
            // If checked, add the ID to the selected list
            setSlectedId([...slectedId, id]);
        } else {
            // If unchecked, remove the ID from the selected list
            setSlectedId(slectedId.filter(selectedId => selectedId !== id));
        }


    }



    useEffect(() => {
        dispatch(getMangeData({ mode, query: searchParams.toString() }))

    }, [searchParams.get("PageSize"), searchParams.get("PageNumber"), searchParams.get("SortOrder"), searchParams.get("SortBy"), searchParams.get("MixSearch")])




    const handelFetchData = () => {

        dispatch(getMangeData({ mode, query: searchParams.toString() }))


    }



    const checkIOpen = (index) => {


        let resutt;
        const value = openArray.Name.indexOf(index)
        if (value > -1) {


            resutt = true;
        } else {

            resutt = false;

        }



        return resutt;

    }



    const openCollapse = (index) => {


        if (checkIOpen(index)) {
            const value = openArray.Name.indexOf(index)
            const lastArray = openArray.Name;
            lastArray.splice(value, 1); // 2nd parameter means remove one item only
            setOpenArray({ Name: lastArray })


        } else {

            const lastArray = openArray.Name;
            lastArray.push(index);

            setOpenArray({ Name: lastArray })

        }



    }

    // URL query parameters and their setters
    const page = Number(searchParams.get('PageNumber')) || 0;
    const rowsPerPage = Number(searchParams.get('PageSize')) || 5;
    const order = searchParams.get('SortOrder') || 'desc';
    const orderBy = searchParams.get('SortBy') || 'c.id';

    const setPage = (value) => {
        searchParams.set('PageNumber', value);
        setSearchParams(searchParams);
    };

    const setRowsPerPage = (value) => {
        searchParams.set('PageSize', value);
        setSearchParams(searchParams);
    };

    const setOrder = (value) => {
        searchParams.set('SortOrder', value);
        setSearchParams(searchParams);
    };

    const setOrderBy = (value) => {
        searchParams.set('SortBy', value);
        setSearchParams(searchParams);
    };

    const [dense, setDense] = useState(true);

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };



    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleChangeDense = (event) => {
        setDense(event.target.checked);
    };



    const handleOpenModal = (item) => {
        setSelectedItem(item)
        setIsModalOpen(true);
    };
    const handleCloseModal = () => {
        setIsModalOpen(false);
    };

    const handleDelete = () => {
        dispatch(deleteManageByIdRefrsh({ mode, id: selectedItem.id, query: searchParams.toString() }))
        handleCloseModal();
    };



    const handelEdit = (id) => {
        dispatch(resetState())
        navigate(`/container/addContainer?itemId=${id}&backLink=` + searchParams.toString().replaceAll("&", "*"))
    }



    const handleOpenArrived = (item) => {
        setSelectedArrived(item);
    };


    const handleCloseArrived = () => {
        setSelectedArrived(null);
    };

    const handleSaveArrive = (value) => {

        dispatch(setDeliveredByIdRefrsh({ mode, id: selectedArrived.id, deliveredDate: value, query: searchParams.toString() }))

        setSelectedArrived(null);


    }


    // Table data preparation
    const rows = content.map((data) => {


        const e = data.container;

        const ssLineName = e?.booking?.ssLineName
        const bookingNumber = e?.booking?.number
        const etaDate = e?.booking?.etaDate

        const consignee = e?.consignee?.name


        return createData(e?.id, e?.containerNumber, consignee, bookingNumber, ssLineName, etaDate, e?.receivedRelease, e?.delivered, e?.partsInContainer, e?.consolidateShipment, e?.loaded3Cars, e?.containerSize, data?.vehicleList

        );
    });



    const handCheckAll = () => {
        if (slectedId.length === 0) {
            const allRowIds = rows.map((row) => row.id);
            setSlectedId(allRowIds);
        } else {
            setSlectedId([]);
        }
    }
    

    const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - data.totalElements) : 0;







    return (

        <>

            <CountView />


            <Paper variant='p1'>
                <PageHeaderManage title={t(mode).toUpperCase()} />


                <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                    <ContainerDropdownMenu />

                    <Box display='flex' sx={{ gap: 2, maxHeight: 40 }} marginBottom='20px' justifyContent='flex-end'>
                        <Button variant='b2' onClick={handelFetchData} sx={{ minWidth: '200px' }}>{t('FetchData')} </Button>
                        <Button variant="b2" onClick={() => navigation(`/${mode}/addContainer`)} >Add New {mode}</Button>
                        <GenerateExcelButton slectedId={slectedId} />



                    </Box>
                </Box>


                {showSuccessAlert && <Alert onClose={() => {
                    setShowSuccessAlert(pre => !pre);
                    dispatch(resetAddedDeleted())
                }} sx={{ m: 2 }} severity="success">{`The ${mode.toLowerCase()} ${name}  has been saved successfully`}</Alert>}

                {showDeletedAlert && <Alert onClose={() => {
                    setShowDeletedAlert(pre => !pre)
                    dispatch(resetAddedDeleted())
                }} sx={{ m: 2 }} severity="success">{`The ${mode.toLowerCase()} ${selectedItem.name}  has been deleted successfully`}</Alert>}

                {loading === 'pending' || loading === 'idle' ? <Progress Msg='Lodding ...' /> :

                    <Box sx={{ width: '100%' }}>
                        <Paper sx={{ width: '100%', mb: 2 }}>


                            <TableContainer>
                                <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle" size={dense ? 'small' : 'medium'}>
                                    <EnhancedTableHead
                                        order={order}
                                        orderBy={orderBy}
                                        onRequestSort={handleRequestSort}
                                        rowCount={rows.length}
                                        handCheckAll = {handCheckAll}
                                        slectedId = {slectedId}
                                    />
                                    <TableBody>



                                        {rows.map((row, index) => (
                                            <React.Fragment key={index}>
                                                <TableRow sx={{ backgroundColor: index % 2 === 0 ? '#F5F5F5' : 'secondary.main' }} hover role="checkbox" tabIndex={-1} key={row.businessDayName}>



                                                    <TableCell>


                                                        <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "row", gap: 1 }}>
                                                            <Checkbox
                                                                color="primary"
                                                                onClick={(e) => handleCheckedClick(e, row.id)}
                                                                checked={slectedId.includes(row.id)}

                                                            />

                                                            <IconButton
                                                                aria-label="expand row"
                                                                size="small"
                                                                onClick={() => openCollapse(index)}
                                                            >
                                                                {checkIOpen(index) ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                                                            </IconButton>

                                                        </Box>


                                                    </TableCell>


                                                    <TableCell>{row.id}</TableCell>

                                                    <TableCell>{row.containerNumber}</TableCell>
                                                    <TableCell>{row.consignee}</TableCell>
                                                    <TableCell>{row.bookingNumber}</TableCell>
                                                    <TableCell>{row.ssLineName}</TableCell>
                                                    <TableCell>{row.etaDate}</TableCell>
                                                    <TableCell>{row.receivedRelease}</TableCell>
                                                    <TableCell>{row.delivered}</TableCell>
                                                    <TableCell>{row.partsInContainer ? <CheckIcon /> : <CloseIcon />}</TableCell>
                                                    <TableCell>{row.consolidateShipment ? <CheckIcon /> : <CloseIcon />}</TableCell>
                                                    <TableCell>{row.loaded3Cars ? <CheckIcon /> : <CloseIcon />}</TableCell>
                                                    <TableCell>{row.containerSize}</TableCell>

                                                    <TableCell >
                                                        <IconButton onClick={() => handelEdit(row.id)} aria-label="Edit" title='Edit'>
                                                            <ModeEditIcon color="info" />
                                                        </IconButton>
                                                        {!row.delivered ?
                                                            <IconButton onClick={() => handleOpenArrived(row)} aria-label="Arrived" title='Arrived'>
                                                                <WhereToVoteIcon color='warning' />
                                                            </IconButton>
                                                            : ""
                                                        }

                                                    </TableCell>



                                                    <TableCell >


                                                        <IconButton onClick={() => handleOpenModal(row)} aria-label="Delete" title='Delete'>
                                                            <Delete color='error' />
                                                        </IconButton>
                                                    </TableCell>

                                                </TableRow>

                                                <TableRow>
                                                    <TableCell sx={{ paddingBottom: 0, paddingTop: 0 }} colSpan={15}>
                                                        <Collapse in={checkIOpen(index)} timeout="auto" unmountOnExit>


                                                            <Box sx={{ m: 2, width: "75%" }}>
                                                                <Typography variant="subtitle1" gutterBottom component="div">
                                                                    {t("Vehicles")} :
                                                                </Typography>
                                                                <Divider sx={{ margin: '11px' }} />

                                                                <TableVehicle data={row.vehicleList} />

                                                            </Box>



                                                        </Collapse>
                                                    </TableCell>
                                                </TableRow>


                                            </React.Fragment>
                                        ))}
                                        {emptyRows > 0 && (
                                            <TableRow style={{ height: (dense ? 33 : 53) * emptyRows }}>
                                                <TableCell colSpan={5} />
                                            </TableRow>
                                        )}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            <TablePagination
                                rowsPerPageOptions={[5, 10, 25, 50]}
                                component="div"
                                count={data.totalElements}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                onPageChange={handleChangePage}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                                SelectProps={{
                                    inputProps: { 'aria-label': 'rows per page' },
                                    native: true,
                                }}
                                ActionsComponent={TablePaginationActions}
                            />


                        </Paper>
                        <FormControlLabel control={<Switch checked={dense} onChange={handleChangeDense} />} label={t('Densepadding')} />



                        {selectedArrived && (
                            <ArrivedModal selected={selectedArrived} handelClose={handleCloseArrived} handelSave={handleSaveArrive} />

                        )}


                        {isModalOpen && (
                            <DeleteConfirmationModal
                                isOpen={isModalOpen}
                                onClose={handleCloseModal}
                                onDelete={handleDelete}
                            />
                        )}
                    </Box>

                }

            </Paper>

        </>
    );
};

export default ContainerList;
