/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { Box } from '@mui/material';
import dayjs from 'dayjs';

export default function LogsDatePickerFrom() {
  const location = useLocation();
  const navigate = useNavigate();
  const searchParams = new URLSearchParams(location.search);

  const defaultValue = (value) => {
    const date = dayjs(value);
    return date.isValid() ? date : null;
  };

  const [selectedDate, setSelectedDate] = useState(
    defaultValue(searchParams.get('DateStart'))
  );

  const updateURLQuery = () => {
    if (!selectedDate || selectedDate === 'Invalid Date') {
      searchParams.delete('DateStart');
    } else {
      searchParams.set('DateStart', selectedDate.format('YYYY-MM-DD'));
    }

    navigate({ search: searchParams.toString() });
  };

  useEffect(() => {
    updateURLQuery();
  }, [selectedDate]);

  const handleChange = (newValue) => {
    setSelectedDate(defaultValue(newValue));
  };

  return (
    <Box>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <Box display="flex" flexDirection="column">
          <DatePicker
            value={selectedDate}
            onChange={handleChange}
            label="From Range"
            format="YYYY-MM-DD"
          />
        </Box>
      </LocalizationProvider>
    </Box>
  );
}
