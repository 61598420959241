import { createSlice } from '@reduxjs/toolkit'
import { PURGE } from 'redux-persist';
import { loginUser, logoutUser, updatePassword, updatePasswordClient } from '../actions/userAction';
const initialState = {
    loading: false,
    userInfo: {}, // for user object
    userToken: null, // for storing the JWT
    error: null,
    success: false, // for monitoring the registration process.,
    isAuthenticate: false,
    passwordUpdated: '',
    accountType : '',
}
export const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        login: (state, action) => {
            state.name = "";
            state.isAuthenticate = true;
        },
        logout: (state, action) => {
            state.name = "";
            state.isAuthenticate = false;
        },
        resetError: (state) => {
            state.error = null
        },
        resetUpdated: (state) => {
            state.error = null;
            state.passwordUpdated = ''
        }

    },
    extraReducers: (builder) => {
        builder.addCase(PURGE, (state) => {
            return initialState;
        })
            .addCase(loginUser.pending, (state) => {
                state.loading = true
                state.error = null;
                state.passwordUpdated = ''
                state.accountType = null;
                state.isAuthenticate = false;
            }).addCase(loginUser.fulfilled, (state, { payload }) => {
                state.loading = false
                state.success = true // registration successful
                state.passwordUpdated = ''
                state.isAuthenticate = true;
                state.userInfo = payload
                state.accountType = payload.accountType ;
            }).addCase(loginUser.rejected, (state, { payload }) => {
                state.loading = false
                state.error = payload
                state.passwordUpdated = ''
                state.accountType = null;
                state.isAuthenticate = false;


            })
            //
            .addCase(logoutUser.pending, (state) => {
                state.loading = true
                state.error = null
                state.passwordUpdated = ''

            }).addCase(logoutUser.fulfilled, (state) => {
                return initialState;
            }).addCase(logoutUser.rejected, (state, { payload }) => {
                state.loading = false
                state.error = payload;
                state.passwordUpdated = ''

            })
            //updatePassword
            .addCase(updatePassword.pending, (state) => {
                state.loading = true
                state.error = null;
                state.passwordUpdated = ''

            }).addCase(updatePassword.fulfilled, (state, { payload }) => {
                state.passwordUpdated = payload['message']

            }).addCase(updatePassword.rejected, (state, { payload }) => {
                state.loading = false
                state.error = payload;
                state.passwordUpdated = ''
            })


            .addCase(updatePasswordClient.pending, (state) => {
                state.loading = true
                state.error = null;
                state.passwordUpdated = ''

            }).addCase(updatePasswordClient.fulfilled, (state, { payload }) => {
                state.passwordUpdated = payload['message']

            }).addCase(updatePasswordClient.rejected, (state, { payload }) => {
                state.loading = false
                state.error = payload;
                state.passwordUpdated = ''
            })





    }
})

// Action creators are generated for each case reducer function
export const { login, logout, resetError, resetUpdated } = userSlice.actions

export default userSlice.reducer