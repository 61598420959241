import React from 'react';
import { AppBar, Toolbar, IconButton, Typography, Drawer, List,  ListItemIcon, ListItemText, Box, ListItemButton } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import AccountCircle from '@mui/icons-material/AccountCircle';
import HomeIcon from '@mui/icons-material/Home';
import { Outlet, useNavigate } from 'react-router-dom';
import LogoutIcon from '@mui/icons-material/Logout';
import { useDispatch, useSelector } from 'react-redux';
import { logoutUser } from '../../actions/userAction';
import { persistor } from '../../store/store';
import PasswordIcon from '@mui/icons-material/Password';
import logo from '../../assets/images/logo/logo_white.png'




function NavBarClient({ userName }) {

    const user = useSelector((state) => state.user.userInfo.client
    );

    

    const [mobileOpen, setMobileOpen] = React.useState(false);

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };


    const handleLogout = async () => {
        await dispatch(logoutUser());
        await persistor.purge();
        navigate('/login');
    }







    const drawer = (
        <div>
            <List>
                <ListItemButton onClick={()=>{navigate('/login')}}  >
                    <ListItemIcon ><HomeIcon /></ListItemIcon>
                    <ListItemText primary="Home" />
                </ListItemButton >
                <ListItemButton   onClick={()=>{navigate('/changePassword') ; handleDrawerToggle()}}  >
                    <ListItemIcon><PasswordIcon /></ListItemIcon>
                    <ListItemText primary="Change Password" />
                </ListItemButton >
                <ListItemButton onClick={handleLogout}>
                    <ListItemIcon  ><LogoutIcon /></ListItemIcon>
                    <ListItemText primary="Logout" />
                </ListItemButton >
            </List>
        </div>
    );

    return (
        <Box sx={{ width: "100wh", overflow: "hidden" }}>
            <AppBar position="fixed">
                <Toolbar>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        edge="start"
                        onClick={handleDrawerToggle}
                        sx={{ mr: 2 }}
                    >
                        <MenuIcon />
                    </IconButton>
                    <Box sx={{ flexGrow: 1, pt: 1 }}>
                        <img src={logo} alt='gojoLogo' width={140} />

                    </Box>

                    <Box sx={{display:"flex"}}>
                        <Box sx={{ maxWidth: "100px", display: "flex", flexWrap: "wrap", justifyContent: "center", alignContent: "center" }}>
                            <Typography variant="subtitle1" component="span" sx={{ marginRight: 2 }}>
                                Welcome,
                            </Typography>
                            <Typography variant="subtitle2" component="span" sx={{ marginRight: 2 }}>
                                {user?.userName?.toUpperCase()}
                            </Typography>
                        </Box>

                        <IconButton
                            edge="end"
                            aria-label="account of current user"
                            color="inherit"
                        >
                            <AccountCircle />
                        </IconButton>
                    </Box>
                </Toolbar>
            </AppBar>
            <Drawer
                variant="temporary"
                open={mobileOpen}
                onClose={handleDrawerToggle}
            >
                {drawer}
            </Drawer>
            <Box sx={{ marginTop: 8, p: 2 }}>
                <Outlet />

            </Box>

        </Box>
    );
}

export default NavBarClient;
