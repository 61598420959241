import { Typography } from "@mui/material";


const PageHeaderManage = ({ title, width }) => {
    return (
        <div style={{
            display: "flex",
            alignItems: 'center',
            marginBottom: 20

        }}>
            <div style={{ backgroundColor: 'red', width: 11, height: 24, marginRight: 29 }} />
            <Typography variant="p" component="p" fontWeight='bolder' >
                {title}
            </Typography>

      

        </div>
    );
}

export default PageHeaderManage