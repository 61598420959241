/* eslint-disable react-hooks/exhaustive-deps */
import { t } from "i18next";
import PageHeader from "../../../components/PageHeader/PageHeader";
import { Autocomplete, Box, Checkbox, FormControl, FormHelperText, InputLabel, MenuItem, Select, TextField, Typography } from "@mui/material";
import CustomDatePicker from "../../../components/CustomDatePicker/CustomDatePicker";
import Loader from "../../../components/Loader";
import FileUpload from "../../../components/FileUpload";
import { useFormikContext } from "formik";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { auctionsLocationsBuyerNumber, carModels } from '../../../actions/formAction';


const NewVehicleInfo = ({ isAbleEdit }) => {
    const formik = useFormikContext();
    const dispatch = useDispatch();

    const formReducser = useSelector((state) => state.form);

    const [mainData, setMainData] = useState({})
    const [auctionLocationsAndBuyerNumber, setAuctionLocationsAndBuyerNumber] = useState({})
    const [carModelsData, setCarModelsData] = useState({})


    useEffect(() => {
        if (formReducser.loading === 'succeeded') {

            setMainData(formReducser.data)
        }

    }, [formReducser])






    const disabled = isAbleEdit ? false : formik?.values?.status.id !== 1;







    useEffect(() => {
        const isLoadingSucceeded = formReducser.auctionLocationsAndBuyerNumber.isLoadingAuctionDetails === 'succeeded';

        if (isLoadingSucceeded) {
            const updatedBuyerNumberList = formReducser.auctionLocationsAndBuyerNumber.buyerNumberList.filter(e => !e.deleted || (formik.values.buyerNumber?.deleted && e.id === formik.values.buyerNumber.id));
            const updatedAuctionLocationsList = formReducser.auctionLocationsAndBuyerNumber.auctionLocationsList.filter(e => !e.deleted || (formik.values.auctionLocation?.deleted && e.id === formik.values.auctionLocation.id));
            setAuctionLocationsAndBuyerNumber({
                auctionLocationsList: updatedAuctionLocationsList,
                buyerNumberList: updatedBuyerNumberList
            });
        }
    }, [formReducser.auctionLocationsAndBuyerNumber.isLoadingAuctionDetails]);










    useEffect(() => {
        if (formReducser.carModels.isLoadingCarModels === 'succeeded') {

            const updateCarModels = formReducser.carModels?.models?.filter(e => !e.deleted || (formik.values.model?.deleted && e.id === formik.values.model.id))

            setCarModelsData({
                isLoadingCarModels: formReducser.carModels?.isLoadingCarModels,
                models: updateCarModels
            });


        }
    }, [formReducser.carModels.isLoadingCarModels])




    useEffect(() => {
        if (formik.values.auction !== null) {
            if (formik.values.auction.id) {
                dispatch(auctionsLocationsBuyerNumber({ id: formik.values.auction.id }))
            }
        }

    }, [formik.values.auction, dispatch])





    useEffect(() => {
        const make = formik.values.make;
        if (formik.values.make !== null) {
            if (make.id) {
                dispatch(carModels({ id: make.id }))
            }
        }

    }, [formik.values.make, dispatch])






    return (
        <>

            <PageHeader title={t('newVehicleInformation')} />
            <Box sx={{
                margin: '64px 49px',
                display: "grid",
                gridTemplateColumns: 'repeat(4, 1fr)',
                gap: '30px'
            }}>
                <CustomDatePicker disabled={disabled} label={t('purchaseDate')} name='purchaseDate' disableFuture={true} />
                <Autocomplete disabled={disabled}
                    value={formik.values.auction || null}
                    onChange={(event, newValue) => {
                        formik.setFieldValue('auction', newValue);
                        formik.setFieldValue('buyerNumber', null);
                    }}
                    onBlur={formik.handleBlur}
                    options={mainData.auctionsList || []}
                    getOptionLabel={(option) =>
                        option.deleted ? `${option.name} (Deleted)` : option.name
                    }
                    renderInput={(params) => (
                        <>
                            <TextField
                                {...params}
                                label={t('auctionName')}
                                error={formik.touched.auction && Boolean(formik.errors.auction)}
                            />
                            {formik.touched.auction && formik.errors.auction && (
                                <FormHelperText error>{formik.errors.auction}</FormHelperText>
                            )}
                        </>
                    )}
                />
                {formReducser.auctionLocationsAndBuyerNumber.isLoadingAuctionDetails === 'pending' && <Loader />}
                <Autocomplete disabled={disabled}
                    value={formik.values.buyerNumber || null}
                    onChange={(event, newValue) => {
                        formik.setFieldValue('buyerNumber', newValue);
                    }}
                    options={auctionLocationsAndBuyerNumber.buyerNumberList || []}
                    getOptionLabel={(option) => option.name}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label={t('buyerNumber')}
                            error={formik.touched.buyerNumber && Boolean(formik.errors.buyerNumber)}
                            helperText={formik.touched.buyerNumber && formik.errors.buyerNumber}
                        />
                    )}
                />

                <Autocomplete disabled={disabled}
                    value={formik.values.client || null}
                    onChange={(event, newValue) => {
                        formik.setFieldValue('client', newValue);
                    }}
                    options={mainData.clientList || []}
                    getOptionLabel={(option) => option.name}
                    renderInput={(params) => (
                        <TextField {...params} label={t("clientName")}

                            error={formik.touched.client && Boolean(formik.errors.client)}
                            helperText={formik.touched.client && formik.errors.client}


                        />
                    )}
                />




            </Box>
            <Box display="grid" margin="64px 49px" gridTemplateColumns="repeat(4, 1fr)" gap="30px" >
                <Box display='flex' flexDirection='column'>
                    <TextField disabled={disabled} label={t('lot')} variant="outlined" name="lot" value={formik.values.lot}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={formik.touched.lot && Boolean(formik.errors.lot)}

                    />
                    {formik.touched.lot && formik.errors.lot && (
                        <FormHelperText error>{formik.errors.lot}</FormHelperText>
                    )}
                </Box>
                <Box display='flex' flexDirection='column'>

                    <TextField disabled={disabled} label={t('vin')} variant="outlined" name="vin"
                        value={formik.values.vin}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={formik.touched.vin && Boolean(formik.errors.vin)}
                    />
                    {formik.touched.vin && formik.errors.vin && (
                        <FormHelperText error>{formik.errors.vin}</FormHelperText>
                    )}
                </Box>
                <Box display='flex' flexDirection='column'>

                    <TextField disabled={disabled} label={t('year')} variant="outlined" name="year"
                        value={formik.values.year}
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                        error={formik.touched.year && Boolean(formik.errors.year)}
                        inputProps={{
                            maxLength: 4,

                        }}
                    />
                    {formik.touched.year && formik.errors.year && (
                        <FormHelperText error>{formik.errors.year}</FormHelperText>
                    )}
                </Box>
                <Autocomplete disabled={disabled}
                    value={formik.values.make || null}
                    onChange={(event, newValue) => {
                        formik.setFieldValue('make', newValue);
                        formik.setFieldValue('model', null);
                    }}
                    options={mainData.makeList || []}
                    getOptionLabel={(option) => option.name}
                    renderInput={(params) => (
                        <>
                            <TextField
                                {...params}
                                label={t('make')}
                                error={formik.touched.make && Boolean(formik.errors.make)}
                            />
                            {formik.touched.make && formik.errors.make && (
                                <FormHelperText error>{formik.errors.make}</FormHelperText>
                            )}
                        </>
                    )}
                />
            </Box>
            <Box display="grid" margin="64px 49px" gridTemplateColumns="repeat(4, 1fr)" gap="30px">
                {formReducser.carModels.isLoadingCarModels === 'pending' && <Loader />}

                <Autocomplete
                    disabled={(carModelsData.models && carModelsData.models.length === 0) || disabled}
                    value={formik.values.model || null}
                    onChange={(event, newValue) => {
                        formik.setFieldValue('model', newValue);
                    }}
                    options={carModelsData.models || []}
                    getOptionLabel={(option) => option.name}
                    renderInput={(params) => (
                        <>
                            <TextField
                                {...params}
                                label={t('model')}
                                error={formik.touched.model && Boolean(formik.errors.model)}
                            />
                            {formik.touched.model && formik.errors.model && (
                                <FormHelperText error>{formik.errors.model}</FormHelperText>
                            )}
                        </>
                    )}
                />
                <Autocomplete disabled={disabled}
                    value={formik.values.color || null}
                    onChange={(event, newValue) => {
                        formik.setFieldValue('color', newValue);
                    }}
                    options={mainData.colorList || []}
                    getOptionLabel={(option) => option.name}
                    renderInput={(params) => (
                        <>
                            <TextField
                                {...params}
                                label={t('color')}
                                error={formik.touched.color && Boolean(formik.errors.color)}
                            />
                            {formik.touched.color && formik.errors.color && (
                                <FormHelperText error>{formik.errors.color}</FormHelperText>
                            )}
                        </>
                    )}
                />

                <Autocomplete disabled={disabled}
                    value={formik.values.fuelType || null}
                    onChange={(event, newValue) => {
                        formik.setFieldValue('fuelType', newValue);
                    }}
                    options={mainData.fuelTypeList || []}
                    getOptionLabel={(option) => option.name}
                    renderInput={(params) => (
                        <>
                            <TextField
                                {...params}
                                label={t('fuelType')}
                                error={formik.touched.fuelType && Boolean(formik.errors.fuelType)}
                            />
                            {formik.touched.fuelType && formik.errors.fuelType && (
                                <FormHelperText error>{formik.errors.fuelType}</FormHelperText>
                            )}
                        </>
                    )}
                />








                <Box sx={{ display: "flex", flexDirection: "row", alignItems: "start", justifyContent: "start", width: "100%", gap: 1 }}>

                    <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}>

                        <Box >
                            <Checkbox disabled={disabled} value={formik.values.offsite} checked={formik.values?.offsite || false} onChange={formik.handleChange} name='offsite' aria-label="check" sx={{ '& .MuiSvgIcon-root': { fontSize: 28 } }} />

                        </Box>
                        <Typography>{t('offsite')}</Typography>

                    </Box>



                    <Autocomplete fullWidth disabled={disabled}
                        value={formik.values.pickupLocation || null}
                        onChange={(event, newValue) => {

                            formik.setFieldValue('pickupLocation', newValue);

                        }}
                        options={mainData?.pickupLocationList?.map(item => item.name) || []}
                        getOptionLabel={(option) => option}
                        renderInput={(params) => (
                            <>
                                <TextField
                                    {...params}
                                    label={t('pickupLocation')}
                                    error={formik.touched.pickupLocation && Boolean(formik.errors.pickupLocation)}
                                />
                                {formik.touched.pickupLocation && formik.errors.pickupLocation && (
                                    <FormHelperText error>{formik.errors.pickupLocation}</FormHelperText>
                                )}
                            </>
                        )}
                    />




                </Box>









                {/* deleted  auctionLocation  */}
                <FormControl sx={{ display: "none" }}>
                    <InputLabel>{t('auctionLocation')}</InputLabel>
                    <Select
                        disabled={(auctionLocationsAndBuyerNumber.auctionLocationsList && auctionLocationsAndBuyerNumber.auctionLocationsList.length === 0) || disabled}
                        value={formik.values.auctionLocation?.id || ''} // Use optional chaining and nullish coalescing
                        name='auctionLocation'
                        error={formik.touched.auctionLocation && Boolean(formik.errors.auctionLocation)}
                        onChange={(event) => {
                            const selectedValue = event.target.value;
                            const selectedOption = auctionLocationsAndBuyerNumber.auctionLocationsList.find((item) => item.id === selectedValue);
                            formik.setFieldValue('auctionLocation', selectedOption || null);
                        }}


                    >
                        {auctionLocationsAndBuyerNumber.auctionLocationsList && auctionLocationsAndBuyerNumber.auctionLocationsList.map(item => (
                            <MenuItem value={item.id} key={item.id}>{item.name}</MenuItem>
                        ))}
                    </Select>
                </FormControl>


            </Box>
            <Box display="grid" margin="64px 49px" gridTemplateColumns="repeat(4, 1fr)" gap="30px"  >


                <TextField disabled={disabled} value={formik.values.invoiceSalesNo} onChange={formik.handleChange} name='invoiceSalesNo' label={t("invoiceSalesNo")} variant="outlined" />
                <TextField disabled={disabled} value={formik.values.carPrice} onChange={formik.handleChange} inputProps={{ type: "number", min: "0.00", max: "10000000.00", step: "0.01" }} name='carPrice' label={t('carPrice')} variant="outlined" />
                <TextField disabled={disabled} value={formik.values.shippingPrice} inputProps={{ type: "number", min: "0.00", max: "10000000.00", step: "0.01" }} onChange={formik.handleChange} name='shippingPrice' label={t('shippingPrice')} variant="outlined" />


                {formik.values.offsite ?


                    <TextField disabled={disabled} sx={{ visibility: !formik.values.offsite ? 'hidden' : 'visible' }} error={formik.touched.pickupNewLocation && Boolean(formik.errors.pickupNewLocation)} fullWidth value={formik.values.pickupNewLocation} onChange={formik.handleChange} name='pickupNewLocation' label={t('Other Location')} variant="outlined" />


                    : ''}


            </Box>


            <Box display="grid" margin="64px 49px" gridTemplateColumns="repeat(4, 1fr)" gap="30px">
                <FileUpload disabled={disabled} label={t('purchasePricePDF')} formikName='purchasePriceFile' uploadName="Purchase" accept=".pdf" />
                <FileUpload disabled={disabled} label={t('shippingPriceVoice')} uploadName="ShippingPrice" formikName='shippingPriceFile' accept="audio/*" formik={formik} />
                <Box sx={{
                    display: 'flex',
                    alignItems: 'center',
                }}>
                    <Checkbox disabled={disabled} checked={formik.values.clientPaymentToAuction || false} onChange={formik.handleChange} name='clientPaymentToAuction' aria-label="check" sx={{ '& .MuiSvgIcon-root': { fontSize: 28 } }} />
                    <Typography>{t('clientPay')}</Typography>

                </Box>
                <Box sx={{
                    display: 'flex',
                    alignItems: 'center',
                }}>
                    <Checkbox disabled={disabled} checked={formik.values.storageFees || false} onChange={formik.handleChange} name="storageFees" aria-label="check" sx={{ '& .MuiSvgIcon-root': { fontSize: 28 } }} />
                    <Typography>{t('lateOrStorageFeesClientResponsibility')}</Typography>
                </Box>
            </Box>
        </>
    )
}

export default NewVehicleInfo;