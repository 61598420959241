import { Box, Divider, Paper } from '@mui/material'
import React from 'react'
import {  useNavigate } from 'react-router-dom';
import { ReactComponent as BookingLogo } from '../../assets/images/booking.svg';
import { ReactComponent as ContainerLogo } from '../../assets/images/Container.svg';
import { ReactComponent as AuctionLogo } from '../../assets/images/auction.svg';
import { ReactComponent as BuyerLogo } from '../../assets/images/customers.svg';
import { ReactComponent as MakeLogo } from '../../assets/images/make.svg';
import { ReactComponent as ModelLogo } from '../../assets/images/model.svg';
import { ReactComponent as ColorLogo } from '../../assets/images/color.svg';
import { ReactComponent as FuelLogo } from '../../assets/images/fule.svg';
import { ReactComponent as WarehousesLogo } from '../../assets/images/wharhouse.svg';
import { ReactComponent as PortsLogo } from '../../assets/images/port.svg';
import { ReactComponent as DestinationLogo } from '../../assets/images/dest.svg';
import { ReactComponent as VehiclesLogo } from '../../assets/images/type.svg';
import { ReactComponent as TitleLogo } from '../../assets/images/title.svg';
import { ReactComponent as TitleProblemLogo } from '../../assets/images/titleproplem.svg';
import { ReactComponent as MobileLogo } from '../../assets/images/mobile.svg';
import { ReactComponent as UsersLogo } from '../../assets/images/users.svg';
import GpsFixedIcon from '@mui/icons-material/GpsFixed';
import PeopleOutlineIcon from '@mui/icons-material/PeopleOutline';
const Management = () => {
    const navigate = useNavigate();
    const items = [
        {
            icon: <BookingLogo sx={{
                fontSize: '70px'
            }} />,
            name: 'Booking Number',
            link1: 'Booking No List',
            link1Action: () => navigate('/booking/bookingList'),


        },
        {
            icon: <ContainerLogo sx={{
                fontSize: '70px'
            }} />,
            name: 'Containers',
            link1: 'Containers List',
            link1Action: () => navigate('/container/containerlist'),


        },

        {
            icon: <AuctionLogo sx={{
                fontSize: '70px'
            }} />,
            name: 'Auction',
            link1: 'Auctions List',
            link1Action: () => navigate('/auctions/view'),

        },
        {
            icon: <BuyerLogo sx={{
                fontSize: '70px'
            }} />,
            name: 'Buyer Number',
            link1: 'Buyer Number List',
            link1Action: () => navigate('/buyerNumber/list'),
        },
        {
            icon: <MakeLogo sx={{
                fontSize: '70px'
            }} />,
            name: 'Make',
            link1: 'Make List',
            link1Action: () => navigate('/make/view'),

        },
        {
            icon: <ModelLogo sx={{
                fontSize: '70px'
            }} />,
            name: 'Models',
            link1: 'Models List',
            link1Action: () => navigate('/model/list'),


        },
        {
            icon: <ColorLogo sx={{
                fontSize: '70px'
            }} />,
            name: 'Color',
            link1: 'Color List',
            link1Action: () => navigate('/color/view'),


        },
        {
            icon: <FuelLogo sx={{
                fontSize: '70px'
            }} />,
            name: 'Fuel Type',
            link1: 'Fuel Type List',
            link1Action: () => navigate('/fuelType/view'),

        },
        {
            icon: <AuctionLogo sx={{
                fontSize: '70px'
            }} />,
            name: 'Auction Location',
            link1: 'Auction Location Lists',
            link1Action: () => navigate('/auctionLocations/list'),

        },
        {
            icon: <WarehousesLogo sx={{
                fontSize: '70px'
            }} />,
            name: 'Warehouses',
            link1: 'Warehouses List',
            link1Action: () => { },
            // eslint-disable-next-line no-dupe-keys
            link1Action: () => navigate('/warehouse/view'),




        },
        {
            icon: <PortsLogo sx={{
                fontSize: '70px'
            }} />,
            name: 'Ports',
            link1: 'Ports List',
            link1Action: () => navigate('/port/view'),

        },
        {
            icon: <DestinationLogo sx={{
                fontSize: '70px'
            }} />,
            name: 'Destination',
            link1: 'Destination List',
            link1Action: () => navigate('/destination/view'),
        },
        {
            icon: <VehiclesLogo sx={{
                fontSize: '70px'
            }} />,
            name: 'Vehicles Type',
            link1: 'Vehicles Type List',
            link1Action: () => { },
            // eslint-disable-next-line no-dupe-keys
            link1Action: () => navigate('/vehicleType/view'),

        },
        {
            icon: <TitleLogo sx={{
                fontSize: '70px'
            }} />,
            name: 'Title Type',
            link1: 'Title Type List',
            link1Action: () => navigate('/titleType/view'),

        },
        {
            icon: <TitleProblemLogo sx={{
                fontSize: '70px'
            }} />,
            name: 'Title Problem',
            link1: 'Title Problem List',
            link1Action: () => navigate('/titleProblem/view'),

        },
        {
            icon: <PeopleOutlineIcon sx={{
                fontSize: '70px'
            }} />,
            name: 'Client',
            link1: 'Client List',
            link1Action: () => navigate('/client/clientList'),

        },
        // {
        //     icon: <BrandLogo sx={{
        //         fontSize: '70px'
        //     }} />,
        //     name: 'Brand',
        //     link1: 'New Brand',
        //     link1Action: () => { },
        //     link2: 'Brand List',
        //     link2Action: () => { },
        // },
        // {
        //     icon: <SSLogo sx={{
        //         fontSize: '70px'
        //     }} />,
        //     name: 'SS Line',
        //     link1: 'New SS Line',
        //     link1Action: () => { },
        //     link2: 'SS Line List',
        //     link2Action: () => { },
        // },

        {
            icon: <MobileLogo sx={{
                fontSize: '70px'
            }} />,
            name: 'Consignee List',
            link1: 'Consignee List',
            link1Action: () => navigate('/consignee/view'),
        },
        {
            icon: <UsersLogo sx={{
                fontSize: '70px'
            }} />,
            name: 'Users',
            link1: 'Users List',
            link1Action: () => navigate('/user/userList'),
        },
        {
            icon: <GpsFixedIcon sx={{
                fontSize: '70px'
            }} />,
            link1: 'Pickup Location',
            link1Action: () => navigate('/pickupLocation/pickup'),
        }
    ]
    return (
        <Paper variant="p1">
            <Box display='grid' gap="40px"
                sx={{
                    gridTemplateColumns: ['repeat(1, 1fr)', 'repeat(2, 1fr)', 'repeat(3, 1fr)'],
                }}
            >
                {items.map(item => (
                    <Box borderRadius='10px' border='1px solid #707070' display='flex'>
                        <Box padding='20px' width="120px">
                            <Box textAlign='center'>
                                {item.icon}

                            </Box>
                            <h5 style={{
                                margin: 0
                            }}>{item.name}</h5>
                        </Box>
                        <Divider orientation='vertical' />
                        <Box display='flex' flexGrow={1} justifyContent={'center'} alignItems='center'>
                            <Box onClick={item.link1Action} style={{
                                color: 'red',
                                padding: 20,
                                textDecoration: 'underLine',
                                cursor: 'pointer'

                            }}>{item.link1}</Box>
                        </Box>

                    </Box>
                ))}
            </Box>
        </Paper>
    )
}

export default Management