import React from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

// Import all necessary components
import NoMatch from './components/404';
import NavBar from './components/NavBar';
import Login from './pages/Login/Login';
import Dashboard from './pages/Dashboard';
import AddNewVehicles from './pages/AddNewVehicles/AddNewVehicles';
import Management from './pages/Managment';
import VehiclesList from './pages/VehiclesList';
import Log from './pages/Log';
import ListV2 from './components/List/ListV2';
import Add from './components/List/Add';
import HasParentList from './components/HasParentList';
import PickupLocationList from './components/PickupLocationList';
import AddPickUp from './components/PickupLocationList/Add';
import ContainerList from './components/ContainerList';
import AddContainer from './components/ContainerList/AddContainer';
import UserList from './components/UserList';
import AddUser from './components/UserList/AddUser';
import ChangePassword from './components/UserList/ChangePassword';
import AccountChangePassword from './components/Account/AccountChangePassword';
import BookingList from './components/BookingList/BookingList';
import AddBooking from './components/BookingList/AddBooking';
import ClientList from './components/Client/ClientList';
import AddClient from './components/Client/AddClient';
import ClientChangePassword from './components/Client/ChangePassword';
import Progress from './components/Loader/Progress ';
import ClientDashboard from './pages/Client/ClientDashboard';
import ClientHome from './pages/Client/ClientHome';
import ChangePasswordClient from './pages/Client/ChangePasswordClient';

// MasterRoute component to handle routing based on user authentication and roles
function MasterRoute() {
    // Use Redux useSelector hook to access the authentication state and account type
    const { isAuthenticate, accountType, loading } = useSelector((state) => state.user);


    if (loading) {
        <Progress />
    }


    // Admin Routes - All routes accessible by admin users
    const AdminRoutes = (
        <Routes>
            <Route path='/' element={<NavBar />}>
                <Route index element={<Dashboard />} />
                <Route path='/VehicleaddEdit' element={<AddNewVehicles />} />
                <Route path='/management' element={<Management />} />
                <Route path='/vehiclesList' element={<VehiclesList />} />
                <Route path='/log' element={<Log />} />
                <Route path='/:mode/view' element={<ListV2 />} />
                <Route path='/:mode/add' element={<Add />} />
                <Route path='/:mode/list' element={<HasParentList />} />
                <Route path='/:mode/pickup' element={<PickupLocationList />} />
                <Route path='/:mode/addPickup' element={<AddPickUp />} />
                <Route path='/:mode/containerlist' element={<ContainerList />} />
                <Route path='/:mode/addContainer' element={<AddContainer />} />
                <Route path='/:mode/userList' element={<UserList />} />
                <Route path='/:mode/addUser' element={<AddUser />} />
                <Route path='/:mode/updatePassword' element={<ChangePassword />} />
                <Route path='/updatePassword' element={<AccountChangePassword />} />
                <Route path='/:mode/bookingList' element={<BookingList />} />
                <Route path='/:mode/addBooking' element={<AddBooking />} />
                <Route path='/:mode/clientList' element={<ClientList />} />
                <Route path='/:mode/addClient' element={<AddClient />} />
                <Route path='/:mode/updateClientPassword' element={<ClientChangePassword />} />
                <Route path='/login' element={<Navigate replace to="/" />} />
            </Route>


            <Route path='/ClientDashboard' element={<ClientDashboard />} >
                <Route path='/ClientDashboard' element={<ClientHome />} />
            </Route>




            <Route path='*' element={<NoMatch />} />
        </Routes>
    );

    // Client Routes - Routes accessible by client users
    const ClientRoutes = (
        <Routes>
            <Route path='/' element={<ClientDashboard />} >
                <Route path='/' element={<ClientHome />} />

                <Route path='/changePassword' element={<ChangePasswordClient />} />

            </Route>



            <Route path='/login' element={<Navigate replace to="/" />} />
            <Route path='*' element={<NoMatch />} />
        </Routes>
    );

    // Non-authenticated Routes - Only the login page
    const NonAuthenticatedRoutes = (
        <Routes>
            <Route path="/login" element={<Login />} />
            <Route path='*' element={<Navigate replace to="/login" />} />
        </Routes>
    );

    // Conditional rendering based on authentication and account type
    if (isAuthenticate) {
        return accountType === "admin" ? AdminRoutes : ClientRoutes;
    } else {
        return NonAuthenticatedRoutes;
    }
}

export default MasterRoute;
