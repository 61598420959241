import { createSlice } from "@reduxjs/toolkit";
import { PURGE } from "redux-persist";
import { downloadExcelContainer } from "../actions/fatechExcelContainerAction";

const initialState = {
    Object:null,
    loading : 'idle',
    hasError: false,
    errorMessage: '',
   
}
const fetchExcelContainerSlice = createSlice({
    name: 'downloadExcelContainer',
    initialState,
    reducers: {
         resetState: () => {
            return initialState;
        }

    },
    extraReducers: (builder) => {
        builder.addCase(PURGE, () => {
            return initialState;
        })
         
            .addCase(downloadExcelContainer.pending, (state,action) => {
                state.loading = "pending";
                state.hasError = false;
              })
              .addCase(downloadExcelContainer.fulfilled, (state) => {
                state.loading = "succeeded";


              })
              .addCase(downloadExcelContainer.rejected, (state, action) => {
                state.loading = "failed";
                state.hasError = true;
                state.errorMessage = action.payload;
              });
    }
});
export const {  resetState } = fetchExcelContainerSlice.actions;

export default fetchExcelContainerSlice.reducer;