/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import {
  FormControl,
  InputLabel,
  OutlinedInput,
  Chip,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Box,
} from '@mui/material';

import { useLocation, useNavigate } from 'react-router-dom';
import { t } from 'i18next';
import { isArray } from 'lodash';

const AdvancedSearch = ({ filter }) => {
  const location = useLocation();
  const navigate = useNavigate();

  const filterTypes = isArray(filter) ? filter : [];

  // Initialize filterInputs with empty values for each filter type
  const initialFilterInputs = filterTypes.map((type) => ({ type, value: '' }));

  const [isDialogOpen, setDialogOpen] = useState(false);
  const [filterInputs, setFilterInputs] = useState(initialFilterInputs);

  // Update URL with current filter inputs
  const updateURLQuery = () => {
    const searchParams = new URLSearchParams(location.search);

    const filterQueryString = filterInputs
      .filter(({ value }) => value && value !== '')
      .map(({ type, value }) => `${type}:${value}`)
      .join(',');

    if (filterQueryString) {
      searchParams.set('AdvancedSearch', filterQueryString);
    } else {
      searchParams.delete('AdvancedSearch');
    }

    navigate({ search: searchParams.toString() });
  };

  // Fetch filter inputs from the URL on initial load
  useEffect(() => {
    fetchDataFromUrl();
  }, [location.search]);

  // Open the advanced search dialog
  const handleOpenDialog = () => {

    setDialogOpen(true);
  };

  // Fetch filter inputs from the URL and set the state
  const fetchDataFromUrl = () => {
    const searchParams = new URLSearchParams(location.search);
    const filterQueryString = searchParams.get('AdvancedSearch');
  
    if (filterQueryString) {
      const filters = filterQueryString.split(',').map((filter) => {
        const [type, value] = filter.split(':');
        return { type, value };
      });
  
      // Ensure that filterInputs contains entries for each filter type
      const updatedFilterInputs = filterTypes.map((type) => {
        const existingFilter = filters.find((filter) => filter.type === type);
        return existingFilter || { type, value: '' };
      });
  
      setFilterInputs(updatedFilterInputs);
    } else {
      // If AdvancedSearch is not found in the URL, initialize filterInputs with default values
      const defaultFilterInputs = filterTypes.map((type) => ({ type, value: '' }));
      setFilterInputs(defaultFilterInputs);
    }
  };

  // Close the advanced search dialog and update filter inputs
  const handleCloseDialog = () => {
    setDialogOpen(false);
    fetchDataFromUrl();
  };

  // Save filter inputs to the URL and close the dialog
  const handleSaveDialog = () => {
    updateURLQuery();
    setDialogOpen(false);
  };

  // Remove a filter chip and update the URL


  // Remove a filter chip and update the URL
  const handleRemoveFilter = (typeToRemove) => {
    const updatedFilterInputs = filterInputs.map((filter) => {
      if (filter.type === typeToRemove) {
        // Reset the value to its default (empty string)
        return { ...filter, value: '' };
      }
      return filter;
    });

    setFilterInputs(updatedFilterInputs);

    // Update the URL with the new filterInputs
    const searchParams = new URLSearchParams(location.search);
    const filterQueryString = updatedFilterInputs
      .filter(({ value }) => value && value !== '')
      .map(({ type, value }) => `${type}:${value}`)
      .join(',');

    if (filterQueryString) {
      searchParams.set('AdvancedSearch', filterQueryString);
    } else {
      searchParams.delete('AdvancedSearch');
    }

    navigate({ search: searchParams.toString() });
  };



  // Handle input change for a specific filter type
  const handleFilterInputChange = (index) => (event) => {
    setFilterInputs((prevFilterInputs) => {
      const updatedFilterInputs = [...prevFilterInputs];
      updatedFilterInputs[index].value = event.target.value;
      return updatedFilterInputs;
    });
  };

  return (
    <Box >
      <Button sx={{ minWidth: '170px' }} color="primary" onClick={handleOpenDialog}>
        {t('Advanced Search')}
      </Button>
      <Box
        sx={{
          display: 'flex',
          width: '100%',
          flexDirection: 'row',
          flexWrap: 'wrap',
          gap: 2,
          justifyContent: 'start',
          alignContent: 'start',
        }}
      >
        {filterInputs.map((filter, index) => (
          <React.Fragment key={index}>
            {filter.value && filter.value !== '' && (
              <Chip
                label={`${filter.type}:${filter.value}`}
                onDelete={() => handleRemoveFilter(filter.type)}
                onClick={handleOpenDialog}
              />
            )}
          </React.Fragment>
        ))}
      </Box>
      <Dialog open={isDialogOpen} onClose={handleCloseDialog}>
        <DialogTitle>{t('Advanced Search')}</DialogTitle>
        <DialogContent sx={{ width: '600px' }}>
          <Box
            sx={{
              p: 2,
              width: '100%',
              display: 'flex',
              gap: 1,
              justifyContent: 'center',
              alignItems: 'center',
              flexWrap: 'wrap',
            }}
          >
            <Box sx={{ display: 'flex', flexDirection: 'row', width: '100%', gap: 2, flexWrap: 'wrap' }}>
              {filterInputs.map((filter, index) => (
                <FormControl key={index} sx={{ width: '45%' }} variant="outlined">
                  <InputLabel htmlFor={`value-input-${filter.type}`}>{filter.type}</InputLabel>
                  <OutlinedInput
                    id={`value-input-${filter.type}`}
                    label="Value"
                    value={filter.value || ''}
                    sx={{ width: '100%' }}
                    onChange={handleFilterInputChange(index)}
                  />
                </FormControl>
              ))}
            </Box>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="primary">
            {t('Cancel')}
          </Button>
          <Button onClick={handleSaveDialog} color="primary">
            {t('Done')}
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default AdvancedSearch;
