import { CloudUpload } from "@mui/icons-material";
import { useState } from "react";
import PreviewModal from "../PreviewModal";
import { useFormikContext } from "formik";
import { Box, Button, Typography } from "@mui/material";
import { resetState } from "../../slices/fileSlice";
import { useDispatch } from "react-redux";

const FileUpload = ({ label, uploadName, accept, formikName, disabled, }) => {
    const [openModal, setOpenModal] = useState(false);

    const dispatch = useDispatch();
    const handleClose = () => {setOpenModal(false)
    
       dispatch (resetState());
    
    };



    

    const formik = useFormikContext();


    const accessNestedProperty = (obj, path) => {
        const properties = path.split(".");
        let value = obj;
        for (const property of properties) {
          if (value !== null && value.hasOwnProperty(property)) {
            value = value[property];
          } else {
            value = undefined;
            break;
          }
        }
        return value;
      };



    const values =  accessNestedProperty(formik.values , formikName);



    return (
        <>
            <Button
                onClick={() => setOpenModal((pre) => !pre)}
                variant="upload"
                fullWidth
                color="default"
                sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    color: values?.length > 0 ? "green" : "black",
                    padding: "10px 20px",
                    borderRadius: "10px",
                    cursor: "pointer",
                    border: values?.length > 0 ? "1px solid green" : "1px solid #D9DCE1",
                }}
            >


                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        alignItems: "center",
                        width: "100%",
                    }}
                >
                    <Typography variant="button">{label}</Typography>
                    {values?.length > 0 && (
                        <Typography sx={{ pr: 1 }} variant="button">
                            {"(" + values?.length + ") Files Uploaded"}
                        </Typography>
                    )}
                </Box>




                <CloudUpload />

            </Button>



            {openModal && (
                <PreviewModal
                    open={openModal}
                    handleClose={handleClose}
                    label={label}
                    uploadName={uploadName}
                    formikName={formikName}
                    accept={accept}
                    disabled={disabled}
                />
            )}
        </>
    )
}
export default FileUpload