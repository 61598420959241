import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { t } from 'i18next'
import CustomDatePicker2 from '../CustomDatePicker/CustomDatePicker2'


const TableVehicle = (pros) => {


  const Data = pros.data;





  return (

    <Box>

      <TableContainer component={Paper}  >

        <Table aria-label="collapsible table">
          <TableHead                           >
            <TableRow sx={{ backgroundColor: 'primary.main' }}>
              <TableCell sx={{ color: 'secondary.main' }} variant='head'>ID</TableCell>
              <TableCell sx={{ color: 'secondary.main' }} variant='head'>Purchased Date</TableCell>
              <TableCell sx={{ color: 'secondary.main' }} variant='head'>Auction</TableCell>
              <TableCell sx={{ color: 'secondary.main' }} variant='head'>Client Name</TableCell>
              <TableCell sx={{ color: 'secondary.main' }} variant='head'>LOT</TableCell>
              <TableCell sx={{ color: 'secondary.main' }} variant='head'>VIN</TableCell>
              <TableCell sx={{ color: 'secondary.main' }} variant='head'>Description</TableCell>


            </TableRow>
          </TableHead>
          <TableBody>

            {Data?.map(((items, index) => {

              let vehicleType = ''
              let make = '';
              let model = '';

              const description = `${items.year} ${make} ${model} ${vehicleType}`

              return (
                <TableRow key={`SupActions-${index}}`}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 }, backgroundColor: index % 2 === 0 ? '#F5F5F5' : 'secondary.main' }}
                >

                  <TableCell >{items.id}</TableCell>
                  <TableCell >{items.purchaseDate}</TableCell>
                  <TableCell >{items.auction?.name}</TableCell>
                  <TableCell >{items.client?.name}</TableCell>
                  <TableCell >{items.lot}</TableCell>
                  <TableCell >{items.vin}</TableCell>
                  <TableCell>{description}</TableCell>



                </TableRow>
              )
            }



            ))}



          </TableBody>
        </Table>
      </TableContainer>

    </Box >

  )









}

function ArrivedModal({ selected, handelClose, handelSave }) {


  const [error, setError] = useState("");



  const [value, setValue] = useState(null)


  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === 'Enter' && !(error !== null || value === null)) {
        handelSave(value);
      }
    };

    // Attach the event listener when the component mounts
    window.addEventListener('keydown', handleKeyDown);

    // Detach the event listener when the component unmounts
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [error, value, handelSave]);






  return (
    <Dialog open={selected} onClose={handelClose} >
      <DialogTitle>Arrived</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Please specify the arrival date for container number : <Typography color="error">{selected?.containerNumber}</Typography>
        </DialogContentText>
        <Box sx={{ m: 2 }}>



          <CustomDatePicker2 value={value} setValue={setValue} label={t('Delivered')} name='Delivered' disableFuture={true} setError={setError} error={error} />




          <Typography variant='body1' color="error" sx={{ pt: 1 }}>
            Please be advised that the status of all vehicles listed below, which are inside the container, will be changed to 'Delivered'
          </Typography>
          <Box sx={{ m: 2 }}>
            <TableVehicle data={selected.vehicleList} />

          </Box>

        </Box>


      </DialogContent>
      <DialogActions>
        <Button onClick={handelClose} variant="b2">
          Cancel
        </Button>


       
        <Button
          disabled={error !== null || value === null}
          onClick={()=>handelSave(value)}

          variant="b1"
        >
          Confirm
        </Button>
       
      </DialogActions>


    </Dialog>
  )
}

export default ArrivedModal