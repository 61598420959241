import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

import en from '././assets/lang/en.json';
import ar from "././assets/lang/ar.json";
const resources = {
  en: { translation: en },
  ar: {translation:ar

  }
}

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init(

    {

      debug: false,
      lng: 'ar', // default language

      resources,
      fallbackLng: 'en',
      interpolation: {
        escapeValue: false
      },
      react: {
        useSuspense: false
      }
    });


export default i18n;