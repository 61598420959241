import { Box, Button, FormControl, InputLabel, MenuItem, Paper, Select, TextField } from '@mui/material'
import React from 'react'
import { t } from 'i18next'
import VehiclesTable from '../Dashboard/Components/Tables/VehiclesTable'

function VehiclesList() {
    return (
        <Paper variant="p1">
            <Box display='grid' gridTemplateColumns='repeat(4,1fr)' gap={'30px'}>
                <TextField label="Search In Vehicles" variant="outlined" placeholder='Search on Vin, Iot, Order ID' />
                <TextField label="Warehouse" variant="outlined" placeholder='Search on Warehouse' />
                <FormControl fullWidth>
                    <InputLabel >Agent</InputLabel>
                    <Select
                        onChange={() => { }}
                    >
                        <MenuItem value={10}>Ten</MenuItem>
                    </Select>
                </FormControl>
                <FormControl fullWidth>
                    <InputLabel >Status</InputLabel>
                    <Select

                        onChange={() => { }}
                    >
                        <MenuItem value={10}>Ten</MenuItem>
                    </Select>
                </FormControl>
            </Box>
            <Box display='grid' gridTemplateColumns='repeat(4,1fr)' gap={'30px'} marginTop='40px'>
                <FormControl fullWidth>
                    <InputLabel >Agent</InputLabel>
                    <Select
                        onChange={() => { }}
                    >
                        <MenuItem value={10}>Ten</MenuItem>
                    </Select>
                </FormControl>
                <FormControl fullWidth>
                    <InputLabel >Status</InputLabel>
                    <Select

                        onChange={() => { }}
                    >
                        <MenuItem value={10}>Ten</MenuItem>
                    </Select>
                </FormControl>
                <Button variant='b1' sx={{ marginRight: '20px' }}>
                    {t("search")}</Button>
            </Box>
            <Box marginTop={'40px'}>
                <VehiclesTable />

            </Box>
        </Paper>
    )
}

export default VehiclesList