import { createSlice } from "@reduxjs/toolkit";
import { PURGE } from "redux-persist";
import { fileUpload } from "../actions/fileAction";

const initialState = {
    files:{},
    isLoading: false,
    hasError: false,
    errorMessage: ''
}
const fileSlice = createSlice({
    name: 'files',
    initialState,
    reducers: {
        deleteFileById: (state, action) => {
            const { id, name } = action.payload;
            state.files[name] = state.files[name].filter(file => file.id !== id);
        },  resetState: () => {
            return initialState;
        }

    },
    extraReducers: (builder) => {
        builder.addCase(PURGE, () => {
            return initialState;
        })
            .addCase(fileUpload.pending, (state) => {
                state.isLoading = true
                state.hasError = false
            })
            .addCase(fileUpload.fulfilled, (state, action) => {
                const { response } = action.payload;
                state.isLoading = false;
                state.hasError = false;
                state.files = response;
                state.errorMessage = ""


            })
            .addCase(fileUpload.rejected, (state, action) => {
                state.isLoading = false;
                state.hasError = true;
                state.errorMessage = action.payload
            })
    }
});
export const { deleteFileById , resetState } = fileSlice.actions;

export default fileSlice.reducer;