import { Box, Typography } from '@mui/material'
import React, { useEffect } from 'react'
import DesktopDataGrid from './DesktopDataGrid'
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import { fetchVehiclesMobile } from '../../../actions/clientViewAction';
import DesktopFilter from './DesktopFilter';
import CountVehicleView from '../CountVehicleView';
import Progress from '../../../components/Loader/Progress ';

function DesktopHome() {
  const location = useLocation();

  const searchParams = new URLSearchParams(location.search);
  const { vehiclesMobile } = useSelector((state) => state.clientViewInitial);

  const dispatch = useDispatch();

  useEffect(() => {



    dispatch(fetchVehiclesMobile({

      query: searchParams.toString()
    }));


  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams.get("PageSize"), searchParams.get("PageNumber"), searchParams.get("SortOrder"), searchParams.get("SortBy")])


  const currentYear = new Date().getFullYear();



  return (
    <Box width={"100%"}>
      <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "start" }}>
        <DesktopFilter />

        {vehiclesMobile.loading === "succeeded" ? <CountVehicleView count={vehiclesMobile.data.totalElements} /> : <Progress Msg="Please wait ..." />}

      </Box>
      <DesktopDataGrid />

      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', }}>
                        <Typography sx={{ pt: 4 }}>
                            © Copyright <a href="https://pinetechs.com/" target="_blank" rel="noopener noreferrer">Pinetechs</a>. All Rights Reserved {currentYear}
                        </Typography>
                    </div>

    </Box>
  )
}

export default DesktopHome