import React, { useState } from 'react'
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Checkbox from '@mui/material/Checkbox';
import IconButton from '@mui/material/IconButton';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import { visuallyHidden } from '@mui/utils';
import { t } from 'i18next';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { Collapse, Divider } from '@mui/material';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';
import { useTheme } from '@mui/material/styles';
import { useSearchParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Loader from '../../../components/Loader';


function createData(vehicleId, action, dateTime, userName, make, model, actionLogs) {
  return {
    vehicleId, action, dateTime, userName, make, model, actionLogs
  };
}






function TablePaginationActions(props) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  );
}






const headCells = [



  {
    id: 'vehicle.id',
    numeric: true,
    disablePadding: true,
    label: 'VehicleId',
  },
  {
    id: 'action',
    numeric: true,
    disablePadding: false,
    label: 'Action',
  },

  {
    id: 'currentDateTime',
    numeric: true,
    disablePadding: false,
    label: 'DateTime',
  },

  {
    id: 'user',
    numeric: true,
    disablePadding: false,
    label: 'User',
  },

  {
    id: 'vehicle.make',
    numeric: true,
    disablePadding: false,
    label: 'Make',
  },
  {
    id: 'vehicle.model',
    numeric: true,
    disablePadding: false,
    label: 'model',
  },

];

function EnhancedTableHead(props) {
  const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } =
    props;
  const createSortHandler = (property) => (event) => {


    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox" >

          <Checkbox
            variant='head'

            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{
              'aria-label': 'select all desserts',
            }}
          />
        </TableCell>


        <TableCell padding="checkbox">

        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
            variant='head'
          >

            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {t(headCell.label).toUpperCase()}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};




const TableSupActions = (pros) => {


  const Data = pros.data;





  return (

    <Box>

      <TableContainer component={Paper}  >

        <Table aria-label="collapsible table">
          <TableHead                           >
            <TableRow sx={{ backgroundColor: 'primary.main' }}>
              <TableCell sx={{ color: 'secondary.main' }} variant='head'>ACTION</TableCell>
              <TableCell sx={{ color: 'secondary.main' }} variant='head'>VALUE</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>

            {Data?.map(((items, index) => (

              <TableRow key={`SupActions-${index}}`}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >

                <TableCell >{items.definitionAction.name}</TableCell>
                <TableCell >{items.info}</TableCell>
              </TableRow>

            )))}



          </TableBody>
        </Table>
      </TableContainer>

    </Box >

  )









}






function DataGridTableLogs(props) {

  const [searchParams, setSearchParams] = useSearchParams();

  const logsDataReducser = useSelector((state) => state.logsData)





  const data = logsDataReducser.Object || {};
  const content = data.content || [];




  const page = Number(searchParams.get(("PageNumber")) || 0)
  const rowsPerPage = Number(searchParams.get(("PageSize")) || 5)
  const order = searchParams.get(("SortOrder")) || "desc"
  const orderBy = searchParams.get(("SortBy")) || "invoiceNumber"



  const setPage = (value) => {
    searchParams.set("PageNumber", value)
    setSearchParams(searchParams);
  }
  const setRowsPerPage = (value) => {
    searchParams.set("PageSize", value)
    setSearchParams(searchParams);
  }

  const setOrder = (value) => {
    searchParams.set("SortOrder", value)
    setSearchParams(searchParams);
  }
  const setOrderBy = (value) => {
    searchParams.set("SortBy", value)
    setSearchParams(searchParams);
  }








  const rows = [];


  const [selected, setSelected] = useState([]);
  const [dense, setDense] = useState(true);
  const [openArray, setOpenArray] = useState({ Name: [] });















  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = rows.map((n) => n.name);


      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };


  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);

  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);

  };

  const handleChangeDense = (event) => {
    setDense(event.target.checked);
  };
  const isSelected = (name) => selected.indexOf(name) !== -1;

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - data.length) : 0;


  //  const emptyRows = 0 



  const checkIOpen = (index) => {


    let resutt;
    const value = openArray.Name.indexOf(index)
    if (value > -1) {


      resutt = true;
    } else {

      resutt = false;

    }



    return resutt;

  }
  const openCollapse = (index) => {


    if (checkIOpen(index)) {
      const value = openArray.Name.indexOf(index)
      const lastArray = openArray.Name;
      lastArray.splice(value, 1); // 2nd parameter means remove one item only
      setOpenArray({ Name: lastArray })


    } else {

      const lastArray = openArray.Name;
      lastArray.push(index);

      setOpenArray({ Name: lastArray })

    }



  }

  content.forEach(log => {




   const vehicle = log?.vehicle


   rows.push(createData(vehicle.id, log.action, log.currentDateTime, log.user.userName, vehicle.make.name, vehicle.model.name, log.actionLogs))


   

  });



  if (data.loading === 'pending' || data.loading === 'idle') {
    return <Loader />
  }



  return (
    <Box sx={{ width: '100%' }}>
      <Paper sx={{ width: '100%', mb: 2 }}>
        <TableContainer>
          <Table
            sx={{ minWidth: 750 }}
            aria-labelledby="tableTitle"
            size={dense ? 'small' : 'medium'}
          >
            <EnhancedTableHead
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={rows.length}
            />
            <TableBody>
              {/* if you don't need to support IE11, you can replace the `stableSort` call with:
                 rows.slice().sort(getComparator(order, orderBy)) */}
              {rows
                .map((row, index) => {
                  const isItemSelected = isSelected(row.invoiceNumber);
                  const labelId = `enhanced-table-checkbox-${index}`;

                  return (
                    <React.Fragment key={index}>
                      <TableRow
                        hover
                        //onClick={(event) => handleClick(event, row.name)}
                        role="checkbox"
                        aria-checked={isItemSelected}
                        tabIndex={-1}
                        key={row.name}
                        selected={isItemSelected}
                      >
                        <TableCell padding="checkbox">
                          <Checkbox
                            color="primary"
                            onClick={(event) => handleClick(event, row.invoiceNumber)}
                            checked={isItemSelected}
                            inputProps={{
                              'aria-labelledby': labelId,
                            }}
                          />
                        </TableCell>



                        <TableCell>
                          <IconButton
                            aria-label="expand row"
                            size="small"
                            onClick={() => openCollapse(index)}
                          >
                            {checkIOpen(index) ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                          </IconButton>
                        </TableCell>


                        <TableCell
                          component="th"
                          id={labelId}
                          scope="row"
                          padding="none"
                        >
                          {row.vehicleId}


                        </TableCell>
                        <TableCell >{row.action}</TableCell>
                        <TableCell >{row.dateTime}</TableCell>
                        <TableCell >{row.userName}</TableCell>
                        <TableCell >{row.make}</TableCell>
                        <TableCell >{row.model}</TableCell>





                      </TableRow>






                      <TableRow>
                        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={10}>
                          <Collapse in={checkIOpen(index)} timeout="auto" unmountOnExit>


                            <Box>
                              <Typography variant="subtitle1" gutterBottom component="div">
                                {t("Details")} :
                              </Typography>
                              <Divider sx={{ margin: '11px' }} />


                              <TableSupActions data={row.actionLogs} />

                            </Box>

                            <Typography variant="subtitle1" gutterBottom component="div" sx={{ marginTop: "10px" }}>
                              {t("TransactionsVoided")} :
                            </Typography>


                          </Collapse>
                        </TableCell>



                      </TableRow>

                    </React.Fragment>
                  );
                })}
              {emptyRows > 0 && (
                <TableRow
                  style={{
                    height: (dense ? 33 : 53) * emptyRows,
                  }}
                >
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25, 50]}
          component="div"
          count={data.totalElements}
          rowsPerPage={rowsPerPage}

          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}

          SelectProps={{
            inputProps: {
              'aria-label': 'rows per page',
            },
            native: true,
          }}

          ActionsComponent={TablePaginationActions}


        />
      </Paper>
      <FormControlLabel
        control={<Switch checked={dense} onChange={handleChangeDense} />}
        label={t("Densepadding")}
      />
    </Box>
  );
}

export default DataGridTableLogs