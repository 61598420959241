import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    message: "",
    severity: "success",
    open: false,
};

const toastSlice = createSlice({
    name: "toast",
    initialState,
    reducers: {
        showSuccessToast: (state, action) => {
            state.message = action.payload;
            state.severity = "success";
            state.open = true;
        },
        showErrorToast: (state, action) => {
            state.message = action.payload;
            state.severity = "error";
            state.open = true;
        },
        hideToast: (state) => {
            state.open = false;
        },
    },
});

export const { showSuccessToast, showErrorToast, hideToast } = toastSlice.actions;

export default toastSlice.reducer;
