/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Alert, AlertTitle, Snackbar } from "@mui/material";
import { hideToast } from "../../slices/toastSlice";

const Toast = () => {
    const dispatch = useDispatch();
    const { message, severity, open } = useSelector((state) => state.toast);
    useEffect(() => {
        let timeoutId;
        if (open) {
            timeoutId = setTimeout(() => {
                dispatch(hideToast());
            }, 5000);
        }
        return () => {
            if (timeoutId) {
                clearTimeout(timeoutId);
                dispatch(hideToast());

            }
        };
    }, [open])

    return (
        <Snackbar onClose={() => { }} open={open} autoHideDuration={6000} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
            <Alert variant="filled" sx={{ width: '600px' }} severity={severity} onClose={() => dispatch(hideToast())} >
                <AlertTitle>{severity === "success" ? "Success" : "Error"}</AlertTitle>
                {message}
            </Alert>
        </Snackbar>
    );
};

export default Toast;
