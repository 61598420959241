/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { t } from 'i18next';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { debounce } from 'lodash';
import {
    Alert,
    AlertTitle,
    Autocomplete,
    Box,
    Button,
    Checkbox,
    CircularProgress,
    FormControl,
    FormControlLabel,
    FormHelperText,
    FormLabel,
    InputLabel,
    MenuItem,
    Paper,
    Radio,
    RadioGroup,
    Select,
    TextField,
    TextareaAutosize,
    Typography,
} from '@mui/material';
import { auctionsbooking, initialForm, } from '../../actions/formAction';

import { addDeletedObject, resetState as resetStateInitial } from '../../slices/formInitialSlice'


import Loader from '../Loader';
import CustomDatePicker from '../CustomDatePicker/CustomDatePicker';
import FileUpload from '../FileUpload';
import { addManageRefrsh, getMangeDataById, updateManageByIdRefrsh } from '../../actions/mangeAction';
import { resetState } from '../../slices/manageSlice';
import PageHeaderManage from '../PageHeader/PageHeaderManage';


const defultInitialValues = {
    containerNumber: "",
    receivedRelease: null,
    delivered: null,
    partsInContainer: false,
    consolidateShipment: false,
    loaded3Cars: false,
    containerSize: 40,
    bolFIle: [],
    booking: null,
    consignee: null,
    etaDate: null

}
const AddContainer = () => {
    const [searchParams] = useSearchParams();
    const navigation = useNavigate();
    const dispatch = useDispatch();
    const [hasApiBookingBeenCalled, setHasApiBookingBeenCalled] = useState(false);
    const [hasLoadedBooking, setHasLoadedBooking] = useState(false);

    const formReducser = useSelector((state) => state.form);
    const objectById = useSelector((state) => state.manage.objectById);
    const itemId = searchParams.get('itemId') || null;
    const backLink = searchParams.get('backLink') || '';

    const add = useSelector((state) => state.add);
    const { carAddedSuccessfully } = useSelector((state) => state.add);
    const [mainData, setMainData] = useState({});
    const [bookingData, setBookingData] = useState([]);
    const { isLoadingBookingData, booking } = useSelector((state) => state.form);
    const { mode } = useParams();
    const [initialValues, setInitialValues] = useState(null);
    const [formikValue, setformikValue] = useState(defultInitialValues);

    const { updated, added, hasError } = useSelector((state) => state.manage)



    useEffect(() => {
        if (formReducser.loading === 'succeeded') {
            setMainData(formReducser.data);
        }
    }, [formReducser]);

    useEffect(() => {
        if (isLoadingBookingData === 'succeeded') {
            setBookingData(booking);
        }
    }, [isLoadingBookingData]);





    const handleBookingDataOnFocus = useCallback(() => {
        if (!hasApiBookingBeenCalled) {
            dispatch(auctionsbooking({ name: '' }));
            setHasApiBookingBeenCalled(true);
        }
    }, [dispatch, hasApiBookingBeenCalled]);


    const handleBookingChange = debounce((event, value) => {

        if (!hasLoadedBooking) {
            setHasLoadedBooking(true)
            return;
        }
        const name = value !== 'undefined' ? value : ''

        dispatch(auctionsbooking({ name }));

    }, 700);




    const validationSchema = Yup.object().shape({
        containerNumber: Yup.string().required("Container Number is required"),


    });



    const initialFormWithContenterId = () => async (dispatch) => {
        await dispatch(resetState())

        await await dispatch(resetStateInitial());
        if (itemId === null) {
            return await dispatch(initialForm())
        } else {
            await dispatch(initialForm())
            return await dispatch(getMangeDataById({ mode, id: itemId }))
        }
    }




    useEffect(() => {

        dispatch(initialFormWithContenterId());

    }, [dispatch])







    useEffect(() => {
        // eslint-disable-next-line no-mixed-operators
        if (updated || added && !hasError) {

            navigation(`/container/containerlist? ${backLink.replaceAll("*", "&")}`, {
                state: {
                    showSuccess: true,
                    name: formikValue.containerNumber

                }
            })


        }


    }, [updated, added, hasError]);








    const handleSubmit = async (values) => {

        if (itemId) {



            dispatch(updateManageByIdRefrsh({ mode, id: itemId, data: { ...values } }));
            return;
        }
        dispatch(addManageRefrsh({ mode, data: { ...values } }));

    };




    const hasFormErrors = (formik) => {

        return Object.keys(formik.errors).length > 0;
    };



    if (carAddedSuccessfully) {

        navigation(`/container/containerlist? ${backLink.replaceAll("*", "&")}`, {
            state: {
                showSuccess: false
            }
        })




    }

    const handleBack = () => {

        navigation(`/container/containerlist? ${backLink.replaceAll("*", "&")}`, {
            state: {
                showSuccess: false
            }
        })

    }






    useEffect(() => {


        if (itemId !== null) {

            if (objectById.loading === "succeeded") {



                setInitialValues({
                    ...objectById.content,
                    etaDate: objectById?.content?.booking?.etaDate,
                });

                if (objectById.content?.consignee && objectById.content?.consignee?.deleted) {
                    dispatch(addDeletedObject({ name: "consigneeList", object: objectById.content.consignee }))
                }
            }

        } else {

            setInitialValues(defultInitialValues)
        }
    }, [itemId, objectById.loading])


    const handleKeyDown = (event, submitForm) => {
        if (event.key === 'Enter') {
            event.preventDefault();
            submitForm();
        }
    };




    if (initialValues === null || (formReducser.loading === 'pending' || formReducser.loading === 'idle') || (itemId !== null && (objectById.loading === 'pending' || objectById.loading === 'idle'))) {
        return <Loader />
    }


    return (


        <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleSubmit} validateOnChange={false}>

            {(formik) => {


                setformikValue(formik.values)


                return (
                    <Form >


                        <Paper variant="p2">
                            <PageHeaderManage title={t(mode).toUpperCase()} />


                            <Box display="grid" margin="64px 49px" gridTemplateColumns="repeat(4, 1fr)" gap="30px">
                                <Box display='flex' flexDirection='column'>
                                    <TextField label={t('containerNumber')} variant="outlined" name="containerNumber" value={formik.values.containerNumber}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        error={formik.touched.containerNumber && formik.errors.containerNumber}
                                        type="containerNumber" // Use type="text" to avoid default validation on numeric input

                                    />
                                    {formik.touched.containerNumber && formik.errors.containerNumber && (
                                        <FormHelperText error>{formik.errors.containerNumber}</FormHelperText>
                                    )}
                                </Box>



                                <CustomDatePicker disabled={formik?.values?.booking == null} label={t('EtaDate')} name={'etaDate'} disableFuture={true} />

                                <CustomDatePicker label={t('ReceivedRelease')} name='receivedRelease' disableFuture={true} />


                                {formik?.values?.delivered !== null ? <CustomDatePicker label={t('Delivered')} name='delivered' disableFuture={true} /> : ""}



                            </Box>

                            <Box sx={{ margin: '64px 49px', display: "grid", gridTemplateColumns: 'repeat(4, 1fr)', gap: '30px' }}>





                                <FormControl >
                                    <InputLabel>{t("consignee")}</InputLabel>
                                    <Select
                                        name="consignee"
                                        value={formik.values.consignee?.id || ''} // Use optional chaining and nullish coalescing
                                        onChange={(event) => {
                                            const selectedValue = event.target.value;
                                            const selectedOption = mainData.consigneeList.find((item) => item.id === selectedValue);
                                            formik.setFieldValue('consignee', selectedOption || null);
                                        }}
                                        error={formik.touched.consignee && Boolean(formik.errors.consignee)}
                                    >
                                        {mainData.consigneeList && mainData.consigneeList.map(item => (
                                            <MenuItem key={item.id} value={item.id}>{item.name}</MenuItem>

                                        ))}
                                    </Select>
                                </FormControl>

                                <FileUpload label={t('bolFIle')} uploadName="BOL" formikName='bolFIle' />



                                <Autocomplete
                                    disablePortal
                                    id="combo-box-demo"
                                    onFocus={handleBookingDataOnFocus}
                                    value={formik?.values?.booking}
                                    onInputChange={handleBookingChange}
                                    clearText="Clear"
                                    onChange={(e, v) => {

                                        if (!(!!v)) {
                                            formik.setFieldValue('booking', null);
                                            return;
                                        };
                                        const selected = bookingData.find((item) => item.id === v.id);
                                        formik.setFieldValue('booking', selected)
                                        formik.setFieldValue('etaDate', selected?.etaDate)

                                    }}

                                    options={bookingData}
                                    getOptionLabel={(option, value) => {

                                        return option.number
                                    }}

                                    loading={isLoadingBookingData === 'pending'}
                                    renderInput={(params) => <TextField {...params} label={t('bookingNumber')} InputProps={{
                                        ...params.InputProps,
                                        endAdornment: (
                                            <>
                                                {isLoadingBookingData === 'pending' ? <CircularProgress color="error" size={20} /> : null}
                                                {params.InputProps.endAdornment}
                                            </>
                                        ),
                                    }} />}

                                />





                                <TextField
                                    name='ssLineName'
                                    disabled
                                    label={t('SSLineName')}
                                    variant="outlined"

                                    value={formik?.values?.booking?.ssLineName || ''}

                                />













                            </Box>

                            <Box display="grid" margin="64px 49px" gridTemplateColumns="repeat(4, 1fr)" gap="30px">





                                <FormControl sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                    <FormLabel sx={{ marginRight: 2, }} >{t('containerSize')}</FormLabel>
                                    <RadioGroup
                                        row
                                        value={formik.values?.containerSize || ''}
                                        onChange={formik.handleChange}

                                        name="containerSize"
                                    >
                                        <FormControlLabel value={40} control={<Radio />} label={40} />
                                        <FormControlLabel value={45} control={<Radio />} label={45} />
                                    </RadioGroup>
                                </FormControl>



                                <Box sx={{ display: 'flex', alignItems: 'center', }}>
                                    <Checkbox checked={formik.values.partsInContainer || false} onChange={formik.handleChange} name="partsInContainer" aria-label="check" sx={{ '& .MuiSvgIcon-root': { fontSize: 28 } }} />
                                    <Typography>{t('partsInContainer')}</Typography>
                                </Box>


                                <Box sx={{ display: 'flex', alignItems: 'center', }}>
                                    <Checkbox checked={formik.values.consolidateShipment || false} onChange={formik.handleChange} name="consolidateShipment" aria-label="check" sx={{ '& .MuiSvgIcon-root': { fontSize: 28 } }} />
                                    <Typography>{t('consolidateShipment')}</Typography>
                                </Box>

                                <Box sx={{ display: 'flex', alignItems: 'center', }}>
                                    <Checkbox checked={formik.values.loaded3Cars || false} onChange={formik.handleChange} name="loaded3Cars" aria-label="check" sx={{ '& .MuiSvgIcon-root': { fontSize: 28 } }} />
                                    <Typography>{t('loaded3Cars')}</Typography>
                                </Box>

                            </Box>

                            <Box display={'grid'} marginTop={'30px'} >
                                <TextareaAutosize
                                    disabled
                                    name='generalNote'
                                    style={{
                                        background: "#FFFFFF",
                                        boxShadow: '0px 10px 30px #00000029',
                                        minHeight: "55px",
                                        border: '1px solid #D9DCE1',
                                        borderRadius: '10px',
                                        padding: '10px',
                                        resize: 'none'
                                    }}
                                    placeholder={t('BookingGeneralNote')}

                                    value={
                                        formik?.values?.booking?.note || ''
                                    }


                                    onChange={formik.handleChange}
                                />
                            </Box>








                        </Paper >

                        {hasFormErrors(formik) || add.hasError ?
                            <Box className="errorBox" >
                                <Alert severity="error">
                                    <AlertTitle>Error</AlertTitle>
                                    {Object.keys(formik.errors).map((fieldName, index) => (
                                        <div key={index}>{formik.errors[fieldName]}</div>
                                    ))}


                                    {add.hasError ?
                                        <div>{add.errorMessage}</div>
                                        : ""}

                                </Alert>
                            </Box>
                            : ''}


                        <Box display="flex" flexDirection="row-reverse" margin='64px 49px'>
                            {!add.isLoading ?
                                <div onKeyDown={(e) => handleKeyDown(e, formik.submitForm)}>

                                    <Button variant='b1' type="submit" sx={{ marginRight: '20px', borderRadius: '10px', width: '231px', height: '55px' }}>{t('save')}</Button>
                                </div>


                                : <Loader />
                            }

                            <Button onClick={() => handleBack()} variant='b2' sx={{ marginRight: '20px', borderRadius: '10px', width: '231px', height: '55px' }}>{t('cancel')}</Button>
                        </Box>

                    </Form >

                )

            }

            }


        </Formik >

    )

}
export default AddContainer;