import * as React from 'react';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';
import { Box, FormHelperText } from '@mui/material';

const CustomDatePicker2 = ({ label,  disabled = false, value , setValue , setError , error}) => {


 


  const defaultValue = () => {
    if (value) {

      const date = dayjs(value);
      if (date.isValid()) {

        return date;
      }
    }

    return null;
  };



 // const handleChangeOld = (newValue) => {
//    if (!newValue) {
//      setError("Please pick a date");
//      setValue(null);
//    } else {
//      try {
        // Parsing the input date string
//        const inputDate = new Date(newValue);
  
        // Check if the parsed date is a valid date
//        if (!isNaN(inputDate.getTime())) {
//          const formattedDate = inputDate.toISOString().split('T')[0]; // Format to YYYY-MM-DD
//          setError(null);
//          setValue(formattedDate);
//        } else {
//          setError("Invalid date");
//        }
//      } catch (err) {
//        console.log("Error during date parsing:", err);
//        setError("Invalid date format");
//      }
//    }
//  };
  


  const handleChange = (newValue) => {






    if (!newValue) {

      
    



      setError('Please pick a date');
      setValue(null);
    } else {
      try {
        const formattedDate = newValue.format('YYYY-MM-DD');
        setError(null);

        if (formattedDate === "Invalid Date"){
          setValue(null)
        } else {
          setValue(formattedDate);

        }

      } catch (err) {
        setError('Invalid date format');
      }
    }
  };


  

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Box display="flex" flexDirection="column">
        <DatePicker
          value={defaultValue()}
          onChange={handleChange}
          label={label}
          disabled={disabled}
          format='YYYY-MM-DD'
          onError={(e)=>setError(e)}
        />
        {error && <FormHelperText error>{error}</FormHelperText>}
      </Box>
    </LocalizationProvider>
  );
};

export default CustomDatePicker2;
