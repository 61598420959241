import { createSlice } from '@reduxjs/toolkit'
import { addManage, deleteManageById, getMangeData, getMangeDataById, setDelivered, updateManageById, updatePasswordById } from '../actions/mangeAction'



const initialState = {
    loading: 'idle',
    object: [],
    updated: false,
    added: false,
    hasError: false,
    errorMessage: "",
    deleted: false,
    objectById: {
        content: {},
        loading: 'idle',
        hasError: false,
        errorMessage: "",
    },
    selectedUser: {}
}


export const manageSlice = createSlice({
    name: 'manage',
    initialState,
    reducers: {
        resetState: (state) => {
            return { ...initialState, selectedUser: state.selectedUser };
        },
        resetError: (state) => {
            state.hasError = false;
            state.errorMessage = ""
        },
        resetAddedDeleted: (state) => {
            state.deleted = false;
            state.added = false
        },
        setSelectedUser: (state, { payload }) => {
            state.selectedUser = payload;
        },
        resetSelectedUser: (state) => {
            state.selectedUser = {}
        }

    },
    extraReducers: (builder) => {
        builder

            .addCase(getMangeData.pending, (state, action) => {
                state.loading = 'pending';
                state.updated = false;
                state.hasError = false;
                state.errorMessage = "";

            })
            .addCase(getMangeData.fulfilled, (state, action) => {

                state.loading = 'succeeded';
                state.object = action.payload
                state.updated = false;
                state.hasError = false;
                state.errorMessage = "";
            })
            .addCase(getMangeData.rejected, (state, action) => {
                state.loading = 'failed';
                state.updated = false;
                state.hasError = true;
                state.errorMessage = action.payload;
            })

            ///
            .addCase(updateManageById.pending, (state, action) => {
                state.loading = 'pending';
                state.updated = false;
                state.hasError = false;
                state.errorMessage = "";
            })
            .addCase(updateManageById.fulfilled, (state, action) => {
                state.loading = 'succeeded';
                state.updated = true;
                state.object = action.payload
                state.added = false;
                state.hasError = false;
                state.errorMessage = "";
            })
            .addCase(updateManageById.rejected, (state, action) => {
                state.loading = 'failed';
                state.updated = false;
                state.added = false;
                state.hasError = true;
                state.errorMessage = action.payload;

            })
            //

            .addCase(getMangeDataById.pending, (state, action) => {
                state.objectById.loading = 'pending';
                state.objectById.hasError = false;
                state.objectById.errorMessage = "";
            })
            .addCase(getMangeDataById.fulfilled, (state, action) => {
                state.objectById.loading = 'succeeded';
                state.objectById.content = action.payload
                state.objectById.hasError = false;
                state.objectById.errorMessage = "";
            })
            .addCase(getMangeDataById.rejected, (state, action) => {
                state.objectById.loading = 'failed';
                state.objectById.hasError = true;
                state.objectById.errorMessage = action.payload;
            })
            //
            .addCase(deleteManageById.pending, (state, action) => {
                state.loading = 'pending';
                state.deleted = false;
                state.hasError = false;
                state.errorMessage = "";
            })
            .addCase(deleteManageById.fulfilled, (state, action) => {
                state.loading = 'succeeded';
                state.deleted = true;
                state.object = action.payload;
                state.hasError = false;
                state.errorMessage = "";
            })
            .addCase(deleteManageById.rejected, (state, action) => {
                state.loading = 'failed';
                state.updated = false;
                state.hasError = true;
                state.errorMessage = action.payload;

            })
            //
            .addCase(addManage.pending, (state, action) => {
                state.loading = 'pending';
                state.added = false;
                state.hasError = false;
                state.errorMessage = "";
            })
            .addCase(addManage.fulfilled, (state, action) => {
                state.loading = 'succeeded';
                state.added = true;
                state.hasError = false;
                state.errorMessage = "";
            })
            .addCase(addManage.rejected, (state, action) => {
                state.loading = 'failed';
                state.added = false;
                state.hasError = true;
                state.errorMessage = action.payload;

            })
            //updatePasswordById
            .addCase(updatePasswordById.pending, (state, action) => {
                state.loading = 'pending';
                state.added = false;
                state.hasError = false;
                state.errorMessage = "";
            })
            .addCase(updatePasswordById.fulfilled, (state, action) => {
                state.loading = 'succeeded';
                state.added = true;
                state.hasError = false;
                state.errorMessage = "";
            })
            .addCase(updatePasswordById.rejected, (state, action) => {
                state.loading = 'failed';
                state.added = false;
                state.hasError = true;
                state.errorMessage = action.payload;

            })




            //setDelivered container

            .addCase(setDelivered.pending, (state, action) => {
                state.loading = 'pending';
                state.added = false;
                state.hasError = false;
                state.errorMessage = "";
            })
            .addCase(setDelivered.fulfilled, (state, action) => {
                state.loading = 'succeeded';
                state.added = true;
                state.hasError = false;
                state.errorMessage = "";
            })
            .addCase(setDelivered.rejected, (state, action) => {
                state.loading = 'failed';
                state.added = false;
                state.hasError = true;
                state.errorMessage = action.payload;

            })
    }
})
export const { resetState, resetError, resetAddedDeleted, setSelectedUser, resetSelectedUser } = manageSlice.actions;

export default manageSlice.reducer