import axios from "axios";
import { baseUrl } from "../util/constant";
import { logoutUser } from "../actions/userAction";
import { persistor, store } from "../store/store";

export class AxiosInstanceService {
    static ApiAxiosInstance;
    static async getAxiosInstance() {
        if (this.ApiAxiosInstance) return Promise.resolve(this.ApiAxiosInstance);

        //create instance 
        this.ApiAxiosInstance = axios.create({
            withCredentials: true,
            baseURL: baseUrl,
            headers: { 'Content-Type': 'application/json' },

        })
        // Add interceptor to handle 401 responses
        this.ApiAxiosInstance.interceptors.response.use(
            (response) => response,
            (error) => {
                if (error.response && error.response.status === 401) {
                    store.dispatch(logoutUser());
                    persistor.purge();

                }
                return Promise.reject(error);
            }
        );
        return Promise.resolve(this.ApiAxiosInstance);
    }


}