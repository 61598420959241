/* eslint-disable react-hooks/exhaustive-deps */
import { TextField } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom';

export default function VehicleText() {
    const location = useLocation();

    const [selectedData, setSelectedData] = useState(null);
    const navigate = useNavigate();




    const updateURLQuery = () => {
        const searchParams = new URLSearchParams(location.search);
        if (!selectedData || selectedData === '') {
            searchParams.delete('VehiclesId');
        } else {
            searchParams.set('VehiclesId', selectedData);
        }
        navigate({ search: searchParams.toString() });
    };


    useEffect(() => {


        const searchParams = new URLSearchParams(location.search);
        const usersParam = searchParams.get('VehiclesId');
        setSelectedData(usersParam || '');



    }, [location.search]);

    useEffect(() => {

        updateURLQuery();
    }, [selectedData]);


    


    return (
        <TextField type="number" min="0" step="1" value={selectedData} onChange={(e)=>setSelectedData(e.target.value)} label="Vehicle Id" variant="outlined" placeholder='From Date' />

    )
}
