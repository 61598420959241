import React, { useEffect, useState } from 'react';
import MobileFilter from './MobileFilter';
import {
    Box,
    Card, CardContent, CardMedia, Grid, ImageList, ImageListItem,
    Table, TableBody, TableCell, TableRow, Typography
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { fetchVehiclesMobile } from '../../../actions/clientViewAction';
import { useLocation } from 'react-router-dom';
import CloseIcon from '@mui/icons-material/Close';
import CheckIcon from '@mui/icons-material/Check';
import { baseUrl } from '../../../util/constant';
import InfiniteScroll from 'react-infinite-scroll-component';
import Progress from '../../../components/Loader/Progress ';
import CountVehicleView from '../CountVehicleView';

function MobileHome() {
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const [page, setPage] = useState(0);
    const [vehicles, setVehicles] = useState([]);
    const { vehiclesMobile } = useSelector((state) => state.clientViewInitial);
    const dispatch = useDispatch();

    // Fetch vehicles on component mount and when page changes
    useEffect(() => {
        dispatch(fetchVehiclesMobile({
            PageNumber: page,
            PageSize: 10,
            query: searchParams.toString()
        }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [page]);

    // Function to fetch more data for infinite scrolling
    const fetchMoreData = () => {
        if (!vehiclesMobile.data.last) {
            setPage(prevPage => prevPage + 1);
        }
    };

    // Data transformation for vehicle information
    useEffect(() => {
        if (vehiclesMobile.loading === "succeeded") {
            const newData = vehiclesMobile.data.content
                .filter(d => !vehicles.some(vehicle => vehicle.id === d.id)) // Filter out any data that already exists
                .map(d => createData(d)); // Map the new data to the desired structure

            setVehicles(prevVehicles => [...prevVehicles, ...newData]);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [vehiclesMobile.loading]); // Add vehicles as a dependency


    const createData = (d) => {
        const destination = d.dispatchInformations?.[0]?.destination?.name ?? null;
        const port = d.dispatchInformations?.[0]?.port?.name ?? null;
        const extraAmount = d.towingInformation?.[0]?.extraAmount ?? null;

        return {
            id: d.id, make: d.make?.name, auction: d?.auctions?.name, model: d?.model?.name,
            year: d?.year, status: d?.status?.name, purchaseDate: d?.purchaseDate, etaDate: d?.container?.booking?.etaDate,
            arrivalDate: d?.container?.delivered, title: d.title, pickupLocation: d.pickupLocation,
            purchasePrice: d.carPrice, port, destination, contractNumber: d.contractNumber,
            carfax: d.carfax, images: d.warehousePictures, vin: d.vin,lot:d.lot,color:d?.color?.name , key :d.key, fuelType: d?.fuelType?.name ,
            salesInvoice:d?.invoiceSalesNo ,  purchasePriceFile :  d.purchasePriceFile, shippingPriceFile:d.shippingPriceFile,extraFees:extraAmount ,contractFile:d.contractFile,
            containerNumber:d?.container?.containerNumber,bookingNumber:d?.container?.booking?.name , ssLineName:d?.container?.booking?.ssLineName




        };
    };

    // Current year for footer
    const currentYear = new Date().getFullYear();

    return (
        <>
        <Box sx={{display:"flex",width:"100%" ,justifyContent:"space-between",alignItems:"start"}}>
        <MobileFilter setVehicles={setVehicles} setPage={setPage} />

        {vehiclesMobile.loading === "succeeded" ?<CountVehicleView count={vehiclesMobile.data.totalElements} /> : <Progress Msg="Please wait ..." />}

        </Box>




            <Box sx={{ pt: 2 }}>

                <InfiniteScroll
                    dataLength={vehicles.length}
                    next={fetchMoreData}
                    hasMore={!vehiclesMobile.data.last}
                    loader={<Box sx={{ pt: 2 }}> <Progress Msg="Please wait ..." /></Box>}
                    endMessage={
                        <Typography sx={{ pt: 4, textAlign: "center" }}>
                            © Copyright <a href="https://pinetechs.com/" target="_blank" rel="noopener noreferrer">Pinetechs</a>. All Rights Reserved {currentYear}
                        </Typography>
                    }
                >
                    <Grid container spacing={2}>
                        {vehicles.map(car => (
                            <Grid item xs={12} sm={6} md={4} key={`vehicle_${car.id}`}>
                                <CarCard car={car} />
                            </Grid>
                        ))}
                    </Grid>
                </InfiniteScroll>
            </Box>

        </>
    )
}

// Component to render individual car details
const CarCard = ({ car }) => {
    const url = baseUrl;
    const [mainImage, setMainImage] = useState(car.images[0] || "");

    return (
        <Card sx={{ p: 2 }}>
            {car.images.length !== 0 && (
                <CardMedia
                    component="img"
                    height="200"
                    image={`${url}${mainImage.filePath}`}
                    alt={`Main Image`}
                />
            )}
            <CardContent>
                <Typography gutterBottom variant="h6">
                    {car.make} {car.model} ({car.year})
                </Typography>
                {/* Image gallery for each car */}
                <ImageList cols={7}>
                    {car.images.map((img, index) => (
                        <ImageListItem key={index}>
                            <img
                                src={img.previewFilePath ? `${url}${img.previewFilePath}` : `${url}${img.filePath}`}
                                alt={`Car ${car.id} - View ${index + 1}`}
                                loading="lazy"
                                style={{ cursor: 'pointer', maxWidth: "50px", maxHeight: "50px" }}
                                onClick={() => setMainImage(img)}
                            />
                        </ImageListItem>
                    ))}
                </ImageList>

                {/* Car details in table format */}
                <Table size="small">
                    <TableBody>
                        {carDetailsRows(car).map((row, index) => (
                            <TableRow key={index}>
                                <TableCell>{row.label}</TableCell>
                                <TableCell>{row.value}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </CardContent>
        </Card>
    );
};

// Function to generate table rows for car details
const carDetailsRows = (car) => {
    const url = baseUrl;


    return [
        // Each object represents a row in the table
        { label: "VIN", value: car.vin },
        { label: "LOT", value: car.lot },
        { label: "Color", value: car.color },
        { label: "Fuel Type", value: car.fuelType },
        { label: "Sales Invoice No:", value: car.salesInvoice },
        { label: "Status", value: car.status },
        { label: "Auction", value: car.auction },
        { label: "Purchase Date", value: car.purchaseDate },
        { label: "ETA Date", value: car.etaDate },
        { label: "Arrival Date", value: car.arrivalDate },
        { label: "Key", value: car.key ? <CheckIcon /> : <CloseIcon /> },
        { label: "Title", value: car.title ? <CheckIcon /> : <CloseIcon /> },
        { label: "Pickup Location", value: car.pickupLocation },
        { label: "Purchase Price", value: car.carPrice },
        { label: "Port", value: car.port },
        { label: "Destination", value: car.destination },
        { label: "Contract Number", value: car.contractNumber },
        { label: "Extra Fees", value: car.extraFees },
        { label: "Container Number", value: car.containerNumber },
        { label: "Booking Number", value: car.bookingNumber },
        { label: "SS.Line Name", value: car.ssLineName },
        { label: "CarFax", value:car?.carfax && car?.carfax?.length !== 0 ? <a href={`${url}${car?.carfax[0]?.filePath}`} target="_blank" rel="noopener noreferrer">Open</a> : <CloseIcon /> },
        { label: "Purchase Price File", value: car?.purchasePriceFile !== null && car?.purchasePriceFile?.length !== 0 ? <a href={`${url}${car?.purchasePriceFile[0]?.filePath}`} target="_blank" rel="noopener noreferrer">Open</a> : <CloseIcon /> },
        { label: "Shipping Price File", value:car?.shippingPriceFile &&  car?.shippingPriceFile?.length !== 0 ? <a href={`${url}${car?.shippingPriceFile[0]?.filePath}`} target="_blank" rel="noopener noreferrer">Open</a> : <CloseIcon /> },
        { label: "Contract File", value:car?.contractFile && car?.contractFile?.length !== 0 ? <a href={`${url}${car?.contractFile[0]?.filePath}`} target="_blank" rel="noopener noreferrer">Open</a> : <CloseIcon /> },

        // More attributes can be added as needed
    ].filter(row => row.value !== null && row.value !== undefined);
    // Filters out rows where the value is null or undefined
};









export default MobileHome;
