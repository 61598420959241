import React from 'react'

import MobileHome from './MobileView/MobileHome';
import DesktopHome from './DesktopView/DesktopHome';
import { useMediaQuery } from '@mui/material';


















function ClientHome() {

    const isMobile = useMediaQuery('(max-width:600px)');

  


   
  


    if (isMobile) {
        return (

           <MobileHome />

        );
    }

   

    return <DesktopHome />;
}

export default ClientHome