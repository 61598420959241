import {
    Box,
    Card,
    CardContent,
    Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Progress from "../../Loader/Progress ";
import { getContainerCount } from "../../../actions/getContainerCountAction";
import AnimatedNumbers from "react-animated-numbers";
import { useLocation, useNavigate } from "react-router-dom";
import { resetState } from "../../../slices/containerCountSlice";

function CountView() {
    const [anchor] = useState(null);

    const containerCount = useSelector((state) => state.containerCount);


    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();

    const handleCardClick = (name, id) => {
        // Get the current query parameters
        const searchParams = new URLSearchParams(location.search);
        const pageSize = searchParams.get("PageSize") || null;

        // Set the 'name' parameter to the given id

        if (pageSize === null) {
            navigate(`?SearchOption=${id}&PageSize=5`);
        } else {
            navigate(`?SearchOption=${id}`);
        }
    };

    useEffect(() => {
        dispatch(getContainerCount({ Countries: "" }));

        return () => {
            dispatch(resetState());
        };


    }, [dispatch]);





    return (
        <Box
            sx={{
                flexGrow: 1,
                p: 2, m: 2
            }}
            anchorEl={anchor}
        >
            {containerCount.loading === "pending" ||
                containerCount.loading === "idle" ? (
                <Progress Msg="Loading ..." />
            ) : (
                <Box>
                    <Box
                        sx={{
                            display: "flex",
                            justifyContent: "end",
                            flexWrap: "wrap",
                            gap: "20px",
                        }}
                    >
                        {containerCount.Object?.map((item, index) => (
                            <Box
                                key={index}
                                sx={{
                                    borderTop: 8,
                                    borderTopColor: item.color,
                                    width: "220px",
                                    height: "70px"
                                }}
                                borderRadius={2}
                            >
                                <Box sx={{cursor:"pointer"}} onClick={() => handleCardClick(item.type, item.id)}>

                                    <Card sx={{ width: "100%", height: "100%"}}>
                                        <CardContent sx={{display:"flex" , justifyContent:"start" , alignItems:"center" ,gap:1}} >
                                            <Typography variant="h4" component={"div"}>
                                                <AnimatedNumbers
                                                    animateToNumber={item.count}
                                                    fontStyle={{ fontSize: 32 }}
                                                    configs={(number, index) => {
                                                        return {
                                                            mass: 1,
                                                            tension: 230 * (index + 1),
                                                            friction: 50,
                                                        };
                                                    }}
                                                />
                                            </Typography>
                                            <Typography>{item.name}</Typography>
                                        </CardContent>

                                    </Card>
                                </Box>

                            </Box>
                        ))}
                    </Box>
                </Box>
            )}
        </Box>
    );
}

export default CountView;
