/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import { Alert, AlertTitle, Box, Button, Paper } from "@mui/material";
import { Form, Formik } from 'formik';
import { t } from 'i18next';
import { useDispatch, useSelector } from 'react-redux';
import { addVehicle, initialFormWithVehiclesId } from '../../actions/formAction';
import Loader from '../../components/Loader';
import PageHeader from "../../components/PageHeader/PageHeader";
import CarStatus from "./components/CarStatus";
import DispatchInfo from "./components/DispatchInfo";
import NewVehicleInfo from "./components/NewVehicleInfo";
import TowingInfo from "./components/TowingInfo";
import WareHouseLoadInfo from "./components/WareHouseLoadInfo";
import FileUpload from "../../components/FileUpload";
import { useNavigate, useSearchParams } from "react-router-dom";
import { resetState as addNewResetState } from "../../slices/addNewVehiclesSlice";
import { addDeletedObject } from "../../slices/formInitialSlice";
import React from 'react';

import * as Yup from 'yup';
import { useEffect, useState } from "react";
import { baseUrl } from "../../util/constant";
import Country from "./components/Country";

const AddNewVehicles = () => {
    const [searchParams] = useSearchParams();


    const navigate = useNavigate();
    const dispatch = useDispatch();

    const formReducser = useSelector((state) => state.form);

    const userReducer = useSelector((state)=> state.user);



   

    const [isAbleEdit, setAbleEdit] = useState(true); 

    const vehicleInfo = useSelector((state) => state.vehicleInfo)
    const vehicleId = searchParams.get(("vehicleId")) || null
    const backLink = searchParams.get(("backLink")) || ''
    const country = useSelector((state) => state.user.userInfo?.user?.country)
    
    const add = useSelector((state) => state.add);
    const { carAddedSuccessfully } = useSelector((state) => state.add);
    const url = baseUrl + "/"


    const [initialValues, setInitialValues] = useState(null)




    const defultInitialValues = {
        purchaseDate: new Date().toISOString().substr(0, 10), // required today's date
        lot: '',
        vin: '',
        year: '', //required
        offsite: false,
        pickupLocation: '',
        pickupNewLocation: '',
        invoiceSalesNo: null,
        carPrice: 0,
        shippingPrice: 0,
        clientPaymentToAuction: false,
        storageFees: false,
        title: false,
        key: false,
        doNotLoadThisUnit: false,
        invoiceShippingNo: '',
        contractNumber: null,
        checkedInShowroom: false,
        client: null,
        noteForTitle: '',
        auction: null, //required to do add validation schema 
        auctionLocation: null,
        purchasePriceFile: [],
        shippingPriceFile: [],
        contractFile: [],
        auctionPictures: [],
        carFax: [],
        warehousePictures: [],
        make: null,
        model: null,
        buyerNumber: null,
        color: null,
        fuelType: null,
        titleType: null,
        titleProblem: null,
        towingInformation: [

        ],
        dispatchInformation: [
        ],
        container: null,
        booking: null,
        status: {

        },
        buyerDelivery: false,
        partsWithCarInAuction: false,
        removeEngine: false,
        deleted: false,
        country: country ? country : {}

    }


    const validationSchema = Yup.object().shape({


        offsite: Yup.boolean(),
        pickupNewLocation: Yup.string().when(['offsite', 'status.id'], {
            is: (offsite, statusId) => statusId === 1 && offsite === true,



            then: () => Yup.string().required('Pickup New Location is required when Offsite is true'),
            otherwise: () => Yup.string().nullable(),
        }),

        auction: Yup.object().when('status.id', {
            is: 1,
            then: () => Yup.object().required("Auction is required"),
            otherwise: () => Yup.object().notRequired()

        }),



        buyerNumber: Yup.object().when('status.id', {
            is: 1,
            then: () => Yup.object().required("Buyer number is required"),
            otherwise: () => Yup.object().notRequired()

        }),


        client: Yup.object().when('status.id', {
            is: 1,
            then: () => Yup.object().required("Client is required"),
            otherwise: () => Yup.object().notRequired()

        }),

        lot: Yup.string().when('status.id', {
            is: 1,
            then: () => Yup.string().required("Lot is required"),
            otherwise: () => Yup.string().notRequired()

        }),



        vin: Yup.string().when('status.id', {
            is: 1,
            then: () => Yup.string().required("VIN is required"),
            otherwise: () => Yup.string().notRequired()

        }),

        pickupLocation: Yup.string().when('status.id', {
            is: 1,
            then: () => Yup.string().required("PickupLocation is required"),
            otherwise: () => Yup.string().notRequired()

        }),



        year: Yup.string().when('status.id', {
            is: 1,
            then: () => Yup.string().matches(/^[0-9]{4}$/, 'Year must be a valid 4-digit number')
                .test('valid-year', 'Year must be between 1900 and the current year', value => {
                    const currentYear = new Date().getFullYear() + 1;
                    const year = parseInt(value, 10);
                    return year >= 1900 && year <= currentYear;
                })
                .required('Year is required'),
            otherwise: () => Yup.string().notRequired()

        }),



        make: Yup.object().when('status.id', {
            is: 1,
            then: () => Yup.object().required("Make is required"),
            otherwise: () => Yup.object().notRequired()

        }),


        model: Yup.object().when('status.id', {
            is: 1,
            then: () => Yup.object().required("model is required"),
            otherwise: () => Yup.object().notRequired()

        }),



        color: Yup.object().when('status.id', {
            is: 1,
            then: () => Yup.object().required("color is required"),
            otherwise: () => Yup.object().notRequired()

        }),


        fuelType: Yup.object().when('status.id', {
            is: 1,
            then: () => Yup.object().required("Fuel type is required"),
            otherwise: () => Yup.object().notRequired()

        }),


        // auctionLocation: Yup.object().when('status.id', {
        //     is: 1,
        //     then: () => Yup.object().required("Auction location is required"),
        //     otherwise: () => Yup.object().notRequired()

        // }),





        purchaseDate: Yup.string().when('status.id', {
            is: 1,
            then: () => Yup.string().matches(
                /^(19|20)\d{2}-(0[1-9]|1[0-2])-(0[1-9]|[12]\d|3[01])$/,
                "Purchase date must be in the format YYYY-MM-DD"
            ),
            otherwise: () => Yup.string().notRequired()

        }),

       //  towingInformation: Yup.array().when('status.id', {

          //   is: 6,

         //    then: () => Yup.array().of(Yup.object().shape({
          //       vehicleType: Yup.object().required("Vehicle Type is required")
         //    })),

           //  otherwise: () => Yup.array().nullable()


        // })









    });


    useEffect(() => {
        dispatch(initialFormWithVehiclesId({ vehicleId }))

        // dispatch(addNewResetState())
    }, [dispatch])



    useEffect(() => {



        userReducer?.userInfo?.user?.roles.every((role)=>{

           if  (role.name === 'ROLE_SUPER_EDITOR' ||  role.name  === 'ROLE_SUPER' || role.name === 'ROLE_ADMIN' ){

            setAbleEdit(true)

            return false;
        
           }

        })


      
    }, [userReducer])



    const handleKeyDown = (event, submitForm) => {
        if (event.key === 'Enter') {
            event.preventDefault();
            submitForm();
        }
    };


    const handleSubmit = async (values) => {

        dispatch(addVehicle({ data: values, id: vehicleId }));
    };




    const hasFormErrors = (formik) => {

        return Object.keys(formik.errors).length > 0;
    };



    if (carAddedSuccessfully) {
        dispatch(addNewResetState())

        navigate(`/?${backLink.replaceAll("*", "&")}`)
    }



    const handleBack = () => {
        navigate(`/?${backLink.replaceAll("*", "&")}`)

    }






    useEffect(() => {


        if (vehicleId !== null) {

            if (vehicleInfo.loading === "succeeded") {

                setInitialValues(vehicleInfo.Object)


                if (vehicleInfo.Object?.auction && vehicleInfo.Object?.auction?.deleted) {
                    dispatch(addDeletedObject({ name: "auctionsList", object: vehicleInfo.Object.auction }))
                }


                if (vehicleInfo.Object?.client && vehicleInfo.Object?.client?.deleted) {
                    dispatch(addDeletedObject({ name: "clientList", object: vehicleInfo.Object.client }))
                }




                if (vehicleInfo.Object?.make && vehicleInfo.Object?.make?.deleted) {
                    dispatch(addDeletedObject({ name: "makeList", object: vehicleInfo.Object.make }))
                }



                if (vehicleInfo.Object?.color && vehicleInfo.Object?.color?.deleted) {
                    dispatch(addDeletedObject({ name: "colorList", object: vehicleInfo.Object.color }))
                }


                if (vehicleInfo.Object?.titleType && vehicleInfo.Object?.titleType?.deleted) {
                    dispatch(addDeletedObject({ name: "titleTypeList", object: vehicleInfo.Object.titleType }))
                }




                if (vehicleInfo.Object?.titleProblem && vehicleInfo.Object?.titleProblem?.deleted) {
                    dispatch(addDeletedObject({ name: "titleProblemList", object: vehicleInfo.Object.titleProblem }))
                }




                if (vehicleInfo.Object?.fuelType && vehicleInfo.Object?.fuelType?.deleted) {
                    dispatch(addDeletedObject({ name: "fuelTypeList", object: vehicleInfo.Object.fuelType }))
                }




                if (!vehicleInfo.Object?.offsite && !Boolean(formReducser.data.pickupLocationList.find((item) => item.name === vehicleInfo.Object.pickupLocation))) {
                    dispatch(addDeletedObject({ name: "pickupLocationList", object: { id: 11112, name: vehicleInfo.Object.pickupLocation } }))
                }









                vehicleInfo.Object?.dispatchInformation
                    .filter(e => e.warehouse?.deleted)
                    .forEach(({ warehouse }) => {
                        dispatch(addDeletedObject({ name: "warehouseList", object: warehouse }));
                    });



                vehicleInfo.Object?.dispatchInformation
                    .filter(e => e.port?.deleted)
                    .forEach(({ port }) => {
                        dispatch(addDeletedObject({ name: "portList", object: port }));
                    });


                vehicleInfo.Object?.dispatchInformation
                    .filter(e => e.destination?.deleted)
                    .forEach(({ destination }) => {
                        dispatch(addDeletedObject({ name: "destinationList", object: destination }));
                    });


                vehicleInfo.Object?.towingInformation
                    .filter(e => e.vehicleType?.deleted)
                    .forEach(({ vehicleType }) => {
                        dispatch(addDeletedObject({ name: "vehicleTypeList", object: vehicleType }));
                    });





            }


            // formik.setValues(vehicleInfo.Object)
        } else {

            setInitialValues(defultInitialValues)


        }
    }, [vehicleId, vehicleInfo.loading])










    if (initialValues === null || (formReducser.loading === 'pending' || formReducser.loading === 'idle') || (vehicleId !== null && (vehicleInfo.loading === 'pending' || vehicleInfo.loading === 'idle'))) {
        return <Loader />
    }


    return (


        <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleSubmit} validateOnChange={true}>
            {(formik) => (


                <Form >


                    {/* Car Status */}
                    <CarStatus   />

                    {/*New Vehicle Information*/}
                    <Paper variant="p2">
                        <Country isAbleEdit ={isAbleEdit} />

                        <NewVehicleInfo isAbleEdit ={isAbleEdit}

                        />

                        {formik.values?.status?.id !== 1 ?

                            <Box>
                                <DispatchInfo isAbleEdit ={isAbleEdit} />
                                <TowingInfo isAbleEdit ={isAbleEdit} />
                            </Box>

                            : ''

                        }


                        <WareHouseLoadInfo isAbleEdit ={isAbleEdit} />





                        <Box display="grid" gridTemplateColumns="repeat(2, 2fr)" paddingTop="74px" margin='64px 49px' gap={'80px'}>
                            <Box>
                                <PageHeader title={t('auctionPictures')} />
                                <FileUpload disabled={isAbleEdit ? false : formik?.values?.status.id !== 1} label={t('auctionPictures')} uploadName="AuctionPictures" formikName='auctionPictures' />


                                <Box sx={{ display: "flex", flexWrap: "wrap", width: "100%", pt: 2, flexDirection: "row", gap: 2 }}>
                                    {formik.values?.auctionPictures?.map((item) => (
                                        <Box key={item.id}   >

                                            <a
                                                href={`${url}${item.filePath}`}
                                                download={item.fileName}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                style={{ textDecoration: 'none', color: 'inherit' }}
                                            >
                                                <img
                                                    src={`${url}${item.filePath}`}
                                                    alt={item.fileName}
                                                    width={'100px'}
                                                    height={'100px'}
                                                />
                                            </a>
                                        </Box>

                                    ))}


                                </Box>



                            </Box>
                            <Box sx={{ visibility: formik?.values?.status?.id === 1 || formik?.values?.status?.id === 6 ? 'hidden' : 'visible' }}>
                                <PageHeader title={t('warehousePictures')} />
                                <FileUpload disabled={isAbleEdit ? false : formik?.values?.status.id !== 8} label={t('warehousePictures')} uploadName="WarehousePictures" formikName='warehousePictures' />

                                <Box sx={{ display: "flex", flexWrap: "wrap", width: "100%", pt: 2, flexDirection: "row", gap: 2 }}>
                                    {formik.values?.warehousePictures?.map((item) => (
                                        <Box key={item.id}   >

                                            <a
                                                href={`${url}${item.filePath}`}
                                                download={item.fileName}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                style={{ textDecoration: 'none', color: 'inherit' }}
                                            >
                                                <img
                                                    src={`${url}${item.filePath}`}
                                                    alt={item.fileName}
                                                    width={'100px'}
                                                    height={'100px'}
                                                />
                                            </a>
                                        </Box>

                                    ))}


                                </Box>




                            </Box>

                        </Box >
                    </Paper >

                    {hasFormErrors(formik) || add.hasError ?
                        <Box className="errorBox" >
                            <Alert severity="error">
                                <AlertTitle>Error</AlertTitle>
                                {Object.keys(formik.errors).map((fieldName, index) => {
                                    if (Array.isArray(formik?.errors[fieldName])) {
                                    } else {
                                        return <div key={index}>{formik.errors[fieldName]}</div>

                                    }
                                }

                                )}
                                {add.hasError ? <div>{add.errorMessage}</div>
                                    : ""}

                            </Alert>
                        </Box>
                        : ''}


                    <Box display="flex" flexDirection="row-reverse" margin='64px 49px'>
                        {!add.isLoading ?
                        
                        
                        <div onKeyDown={(e) => handleKeyDown(e, formik.submitForm)}>

                            <Button variant='b1' type="submit" sx={{ marginRight: '20px', borderRadius: '10px', width: '231px', height: '55px' }}>{t('save')}</Button>
                            </div>

                            : <Loader />
                        }
                        <Button onClick={() => handleBack()} variant='b2' sx={{ marginRight: '20px', borderRadius: '10px', width: '231px', height: '55px' }}>{t('cancel')}</Button>
                    </Box>

                </Form >


            )}


        </Formik >

    )
}
export default AddNewVehicles;