import { Box, CircularProgress } from '@mui/material';
import React   from 'react';

const Loader = () => (
    <Box
        sx={{
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            background: 'rgba(0, 0, 0, 0.9)',
            color: '#fff',
            zIndex: 9999,
        }}
    >
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
            }}
        >
            <CircularProgress size={100} color='error' />
        </Box>
    </Box>
);
export default Loader;