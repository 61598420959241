import * as React from 'react';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';
import { Box, FormHelperText } from '@mui/material';
import { useFormikContext } from 'formik';

const CustomDatePicker = ({ label, name, disabled }) => {
  const [error, setError] = React.useState('');

  const formik = useFormikContext();



  const convertToDynamicFormikName = (string) => {
    const parts = string.split(".");
    const dynamicParts = parts.map((part) => {
      if (!isNaN(part)) {
        // If the part is a number, use it as an array index with square brackets
        return `[${part}]`;
      } else {
        // Otherwise, use it as a property name
        return part;
      }
    });

    return dynamicParts.join("");
  };


  
  const accessNestedProperty = (obj, path) => {
    const properties = path.split(".");
    let value = obj;
    for (const property of properties) {

      if (value !== null && value.hasOwnProperty(property)) {

        value = value[property];
      } else {
        value = undefined;
        break;
      }
    }
    return value;
  };



  const valuesF = accessNestedProperty(formik.values, name)
  const nameF = convertToDynamicFormikName(name);



  
  const defaultValue = () => {




    if (valuesF) {

      const date = dayjs(valuesF);
      if (date.isValid()) {

        return date;
      }
    }

    return null;
  };








  const handleChange = (newValue) => {



    if (!newValue) {
      setError('Please pick a date');
      formik.setFieldValue(name, null);
    } else {
      try {
        const formattedDate = newValue.format('YYYY-MM-DD');
        setError(null);
        formik.setFieldValue(nameF, formattedDate);
      } catch (err) {
        setError('Invalid date format');
      }
    }
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Box display="flex" flexDirection="column">
        <DatePicker
          value={defaultValue()}
          onChange={handleChange}
          label={label}
          disabled={disabled}
          format='YYYY-MM-DD'
        />
        {error && <FormHelperText error>{error}</FormHelperText>}
        {formik?.touched[name] && formik?.errors[name] && (
          <FormHelperText error>{formik.errors[name]}</FormHelperText>
        )}
      </Box>
    </LocalizationProvider>
  );
};

export default CustomDatePicker;
