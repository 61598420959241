/* eslint-disable react-hooks/exhaustive-deps */

import { Box, Button } from "@mui/material";

import React from "react";

import ClientsMenu from "./ClientsMenu";

import { t } from "i18next";
import { useLocation, useNavigate } from "react-router-dom";

const DropdownMenu = () => {
  const location = useLocation();
  const navigate = useNavigate();


 



  const handelResetFilter = () => {
    const searchParams = new URLSearchParams(location.search);

    searchParams.delete("Clients");


    navigate({ search: searchParams.toString() });
  };

  return (
    <Box sx={{pl: 2,display:"flex", flexDirection:"column", gap:2 , alignItems:"start",justifyContent:"start"}}>
      <Box sx={{  display: "flex", gap: 2, alignItems:"start",justifyContent:"start" }}>
       
        <ClientsMenu searchParams={"Clients"} />
        

        <Button color="colorD" onClick={handelResetFilter}>
          {t("ResetFilter")}
        </Button>
      </Box>
    </Box>
  );
};

export default DropdownMenu;
