/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import {  isArray } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { containers } from "../../../actions/formAction";
import { CircularProgress } from '@mui/material';
import { t } from 'i18next';

function ContainerMenu() {
    const location = useLocation();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [selectedContainerNumbers, setSelectedContainerNumbers] = useState([]);
    const [anchor, setAnchor] = useState(null);
    const open = Boolean(anchor);
    const [mainData, setMainData] = useState([]);
    const [debouncedInputValue, setDebouncedInputValue] = useState('');
    const [input, setInput] = useState('');



    const formReducer = useSelector((state) => state.form);

    useEffect(() => {
        if (formReducer.isLoadingContainerData === 'succeeded') {
            const newData = formReducer.container;
            const  addedList = newData.filter(item2 => !mainData.some(item1 => item1.id === item2.id));

            if (addedList) {
                setMainData(prevData => [...prevData, ...addedList]);
            }
        }

        const searchParams = new URLSearchParams(location.search);
        const ContainerParam = searchParams.get('Container');

        if (ContainerParam === null) {
            setSelectedContainerNumbers([]);
        } else {
            const selectedTypes = ContainerParam.split(',').map(Number);
            setSelectedContainerNumbers(selectedTypes);
        }
    }, [formReducer.isLoadingContainerData, location.search]);



    

    useEffect(() => {
        updateURLQuery();
    }, [selectedContainerNumbers]);

    useEffect(() => {
        const timeoutId = setTimeout(() => {
            dispatch(containers({ name: input }));
        }, 500);

        return () => clearTimeout(timeoutId);
    }, [debouncedInputValue, dispatch]);

    const updateURLQuery = () => {
        if (anchor) {
            const searchParams = new URLSearchParams(location.search);
            if (selectedContainerNumbers.length === 0) {
                searchParams.delete('Container');
            } else {
                searchParams.set('Container', selectedContainerNumbers.join(','));
            }
            navigate({ search: searchParams.toString() });
        }
    };

    const handleAutocompleteChange = (event, value) => {
        setSelectedContainerNumbers(value.map((item) => item.id));
    };

    const handleClick = (event) => {
        setAnchor(event.currentTarget);
    };

    const handleClose = () => {
        setAnchor(null);
    };

   

    return (
        <Box className="ContainerNumber">
            <Button
                id="ContainerNumber"
                aria-controls={open ? 'basic-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                onClick={handleClick}
            >
                Container 
                {open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
            </Button>

            <Menu
                id="basic-menu"
                anchorEl={anchor}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'ContainerNumber',
                }}
                PaperProps={{
                    style: {
                        width: '25%',
                        maxWidth: '100%',
                        maxHeight: '300px',
                        left: 0,
                        right: 0,
                    },
                }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        flexWrap: 'wrap',
                        width: '100%',
                        maxHeight: '300px',
                        overflow: 'auto',
                        overflowY: 'hidden',
                        p: 2
                    }}
                >
                    <Autocomplete
                        multiple
                        id="container-autocomplete"
                        options={mainData}
                        value={selectedContainerNumbers.map(id => isArray(mainData) ? mainData.find(item => item.id === id) || null :[])}
                        onChange={handleAutocompleteChange}
                        inputValue={input}
                        onInputChange={(event, value) => {
                            // Update the input value immediately without debouncing

                         setDebouncedInputValue(value);
                        }}
                        clearText="Clear"

                        disableCloseOnSelect
                        getOptionLabel={(option) => option?.containerNumber}
                        loading={formReducer.isLoadingContainerData === 'pending'}

                        renderInput={(params) => <TextField {...params} label={`#${t('containerNumber')}`} onChange={({ target }) => setInput(target.value)} InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                                <>
                                    {formReducer.isLoadingContainerData === 'pending' ? <CircularProgress color="error" size={20} /> : null}
                                    {params.InputProps.endAdornment}
                                </>
                            ),
                        }} />}








                    />

                </Box>
            </Menu>
        </Box>
    );
}

export default ContainerMenu;
