/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-mixed-operators */
import { Box, Button, FormControl, IconButton, InputAdornment, InputLabel, OutlinedInput, Paper } from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams, useSearchParams } from "react-router-dom";
import {  updatePasswordByIdRefresh } from "../../actions/mangeAction";
import CustomToast from "../CustomToast/CustomToast";
import { resetError } from "../../slices/manageSlice";
import PageHeaderManage from "../PageHeader/PageHeaderManage";
import { Visibility, VisibilityOff } from "@mui/icons-material";



const ChangePassword = () => {
    const [showPassword, setShowPassword] = useState(false);
    const handleClickShowPassword = () => setShowPassword((show) => !show);

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };
    const dispatch = useDispatch();
    const { mode } = useParams();
    const [searchParams] = useSearchParams()
    const id = searchParams.get('id');
    const location = useLocation();
    const [value, setValue] = useState();
    const { updated, added, hasError, errorMessage } = useSelector((state) => state.manage)
    const navigation = useNavigate();


    const handleSave = () => {
        if (id && value !== "") {
            dispatch(updatePasswordByIdRefresh({ mode, id, data: { newPassword: value } }));
            return;
        }
    }

    const handleBack = () => {
        navigation(`/${mode}/clientList` + location.search, {
            state: {
                showSuccess: false
            }
        })

    }
    useEffect(() => {
        if (updated || added && !hasError) {

            navigation(`/${mode}/clientList`, {
                state: {
                    showSuccess: true,
                    name: value
                }
            })
        }
    }, [updated, added, hasError]);

    const handleToastClose = () => {
        dispatch(resetError())
    };
    if (hasError) {
        return (<>
            <CustomToast open={hasError} message={errorMessage} onClose={handleToastClose} />
        </>)

    }

    return (
        <Paper variant="p1" >

            <PageHeaderManage title={"update Password".toUpperCase()} />

            <Box display='flex' justifyContent='space-between'>

                <FormControl variant="outlined" sx={{
                    flexGrow: 1,
                }}>
                    <InputLabel htmlFor="updatePassword">Password</InputLabel>
                    <OutlinedInput
                        fullWidth
                        id="newPassword"
                        type={showPassword ? 'text' : 'password'}
                        name="newPassword"
                        value={value}
                        onChange={(e) => setValue(e.target.value)}
                        endAdornment={
                            <InputAdornment position="end">
                                <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={handleClickShowPassword}
                                    onMouseDown={handleMouseDownPassword}
                                    edge="end"
                                >
                                    {showPassword ? <VisibilityOff /> : <Visibility />}
                                </IconButton>
                            </InputAdornment>
                        }
                        label="Update Password"
                    />

                </FormControl>
                <Box display='flex' justifyContent='space-evenly' alignItems='center'>
                    <Button variant="b2" sx={{
                        marginInline: '20px'
                    }} onClick={handleBack} >Cancel</Button>
                    <Button variant="b1" onClick={handleSave} disabled={value === '' || value === undefined ? true : false} >Update</Button>

                </Box>
            </Box>
        </Paper>
    )
}

export default ChangePassword;