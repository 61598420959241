import { createSlice } from "@reduxjs/toolkit";
import { PURGE } from "redux-persist";
import { fetchVehiclesMobile, initialClientView } from "../actions/clientViewAction";

const initialState = {
    initialData:null,
    loading : 'idle',
    hasError: false,
    errorMessage: '',
    vehiclesMobile :{
        loading : 'idle',
        hasError: false,
        errorMessage: '',
        data :{}
    }
}
const clientViewInitialSlice = createSlice({
    name: 'clientView',
    initialState,
    reducers: {
         resetState: () => {
            return initialState;
        }

    },
    extraReducers: (builder) => {
        builder.addCase(PURGE, () => {
            return initialState;
        })
            .addCase(initialClientView.pending, (state) => {
                state.loading = 'pending';
                state.hasError = false
            })
            .addCase(initialClientView.fulfilled, (state, action) => {
                state.loading = 'succeeded';
                state.hasError = false;
                state.initialData = action.payload ;
                state.errorMessage = ""


            })
            .addCase(initialClientView.rejected, (state, action) => {
                state.loading = 'failed';
                state.hasError = true;
                state.errorMessage = action.payload
            })




            .addCase(fetchVehiclesMobile.pending, (state) => {
                state.vehiclesMobile.loading = 'pending';
                state.vehiclesMobile.hasError = false
            })
            .addCase(fetchVehiclesMobile.fulfilled, (state, action) => {
                state.vehiclesMobile.loading = 'succeeded';
                state.vehiclesMobile.hasError = false;
                state.vehiclesMobile.data = action.payload ;
                state.vehiclesMobile.errorMessage = ""


            })
            .addCase(fetchVehiclesMobile.rejected, (state, action) => {
                state.vehiclesMobile.loading = 'failed';
                state.vehiclesMobile.hasError = true;
                state.vehiclesMobile.errorMessage = action.payload
            })
           
    }
});
export const {  resetState } = clientViewInitialSlice.actions;

export default clientViewInitialSlice.reducer;