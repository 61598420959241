import React from 'react'
import Logo from '../../assets/images/logo/logo.png'
import { Box, Breadcrumbs, Button, Link } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { t } from 'i18next';
import { useNavigate, useParams } from 'react-router-dom';

const AppHeader = () => {
    const navigate = useNavigate();
    const { mode } = useParams();
    return (
        <>
            <Box sx={{ display: "flex", alignItems: "center", marginBottom: "20px", marginTop: '80px' }} >
                <img src={Logo} alt='' className='' width={126} height={122} style={{
                    padding: '20px ',
                    marginRight: '34px'
                }} />

                <Box sx={{ flexGrow: 1 }}>
                    <Breadcrumbs aria-label="breadcrumb" className='container-r'>
                        <Link underline="hover" fontSize={34} onClick={() => navigate('/')} >
                            {t("dashboard")}
                        </Link>
                    </Breadcrumbs>
                </Box>


                {mode === undefined && (
                    <Button variant='b1' sx={{ marginRight: '20px' }} onClick={() => navigate('/VehicleaddEdit')}>
                        <AddIcon />{t("addNew")}</Button>
                )
                }
            </Box>

        </>
    )
}

export default AppHeader;