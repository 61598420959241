import React, { useState, useEffect } from "react";
import { Box, Typography, Modal, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";

import { baseUrl } from "../../util/constant";

const PreviewPicture = ({ open, label, handleClose, file }) => {
  // Supported image formats










  const imageFormats = [".jpg", ".jpeg", ".png", ".gif"];








  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleKeyPress = (event) => {
    if (event.key === "ArrowLeft") {
      handlePrev();
    } else if (event.key === "ArrowRight") {
      handleNext();
    }
  };

  useEffect(() => {
    // Add event listener when the component mounts
    window.addEventListener("keydown", handleKeyPress);

    // Remove event listener when the component unmounts
    return () => {
      window.removeEventListener("keydown", handleKeyPress);
    };
  }, [handleKeyPress]);







  // State for selected image and its index
  const [selected, setSelected] = useState(file[0]);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const url = baseUrl + "/";

  // Update selected image when index or file array changes
  useEffect(() => {
    setSelected(file[selectedIndex]);
  }, [selectedIndex, file]);

  // Check if a file is an image based on its extension
  const isImage = (file) => {
    const extension = file.extension.toLowerCase();
    return imageFormats.some((format) => extension.includes(format));
  };

  // Handle click on thumbnail
  const handleThumbnailClick = (item, index) => {
    setSelected(item);
    setSelectedIndex(index);
  };

  // Handle next image
  const handleNext = () => {
    const nextIndex = (selectedIndex + 1) % file.length;
    setSelected(file[nextIndex]);
    setSelectedIndex(nextIndex);
  };

  // Handle previous image
  const handlePrev = () => {
    const prevIndex = (selectedIndex - 1 + file.length) % file.length;
    setSelected(file[prevIndex]);
    setSelectedIndex(prevIndex);
  };

  return (
    <Modal open={open} onClose={handleClose}>
      <div>
        <Modal
          open={open}
          onClose={handleClose}
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Box
            className="content"
            sx={{
              width: "80%",
              maxHeight: "80%",
              bgcolor: "white",
              borderRadius: "10px",
              p: 4,
              zIndex: 1000,
              display: "flex",
              flexDirection: "column",
            }}
          >
            {/* Header */}
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              mb={2}
            >
              <Typography variant="h6" component="h2">
                {label}
              </Typography>
              <Box onClick={handleClose}>
                <CloseIcon />
              </Box>
            </Box>

            {/* Large Image Display */}
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignContent: "center",
                m: 2,
              }}
            >
              <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", gap: 2 }}>
                <IconButton onClick={handlePrev}>
                  <NavigateBeforeIcon />
                </IconButton>

                <Box position="relative">
                  {isImage(selected) ? (
                    // Render image for supported formats
                    <a
                      href={`${url}${selected.filePath}`}
                      download={selected.fileName}
                      target="_blank"
                      rel="noopener noreferrer"
                      style={{ textDecoration: "none", color: "inherit" }}
                    >
                      <img
                        src={`${url}${selected.filePath}`}
                        alt={selected.fileName}
                        height={"370px"}
                      />
                    </a>
                  ) : (
                    // Render placeholder for unsupported formats
                    <a
                      href={`${url}${selected.filePath}`}
                      download={selected.fileName}
                      target="_blank"
                      rel="noopener noreferrer"
                      style={{ textDecoration: "none", color: "inherit" }}
                    >
                      <Box
                        sx={{
                          width: "170px",
                          height: "170px",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          borderStyle: "ridge",
                        }}
                      >
                        <Typography variant="h3">
                          {selected.extension.substring(1).toUpperCase()}
                        </Typography>
                      </Box>
                    </a>
                  )}
                  <Typography variant="body1">
                    {selected.originalFileName}
                  </Typography>
                </Box>
                <IconButton onClick={handleNext}>
                  <NavigateNextIcon />
                </IconButton>
              </Box>
            </Box>

            {/* Thumbnails */}
            <Box sx={{ flex: 1, overflow: "auto" }}>
              <Box
                display="flex"
                alignContent="center"
                justifyContent="center"
                gap={5}
                sx={{ flexWrap: "wrap" }}
              >
                {file &&
                  file.length > 0 &&
                  file?.map((item, index) => (
                    <Box
                      key={item.id}
                      width={"200px"}
                      position="relative"
                      onClick={() => handleThumbnailClick(item, index)}
                      style={{ cursor: "pointer" }}
                    >
                      {isImage(item) ? (
                        // Render image for supported formats
                        <img
                          src={`${url}${item.filePath}`}
                          alt={item.fileName}
                          width={"170px"}
                          height={"170px"}
                        />
                      ) : (
                        // Render placeholder for unsupported formats
                        <Box
                          sx={{
                            width: "170px",
                            height: "170px",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            borderStyle: "ridge",
                          }}
                        >
                          <Typography variant="h3" >
                            {item.extension.substring(1).toUpperCase()}
                          </Typography>
                        </Box>
                      )}
                      <Typography variant="body1" sx={{ whiteSpace: 'pre-wrap' }}>
                        {item.originalFileName.slice(0, 15)}
                      </Typography>
                    </Box>
                  ))}
              </Box>
            </Box>
          </Box>
        </Modal>
      </div>
    </Modal>
  );
};

export default PreviewPicture;
