/* eslint-disable react-hooks/exhaustive-deps */
import { Alert, Box, Button, FormControl, FormHelperText, IconButton, InputAdornment, InputLabel, OutlinedInput, Paper } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {  updatePasswordClient } from '../../actions/userAction';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { resetError, resetUpdated } from '../../slices/userSlice';
import PageHeaderManage from '../../components/PageHeader/PageHeaderManage';

function ChangePasswordClient() {
    const [showOldPassword, setShowOldPassword] = useState(false);
    const [showNewPassword, setShowNewPassword] = useState(false);
    const [oldPassword, setOldPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [error, setError] = useState(null);
    const isButtonDisabled = !newPassword || !oldPassword;
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { error: storeError, passwordUpdated } = useSelector((state) => state.user);

    const handleClickShowOldPassword = () => setShowOldPassword((show) => !show);
    const handleClickShowNewPassword = () => setShowNewPassword((show) => !show);
    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };
    const handleSubmit = () => {
        const minLength = 8;
        const specialCharRegex = /[!@#$%^&*(),.?":{}|<>]/;

        if (newPassword.length < minLength) {
            setError('New password must be at least 8 characters long.');
        } else if (!specialCharRegex.test(newPassword)) {
            setError('New password must contain at least one special character.');
        } else {
            setError(null); // Clear error message
            dispatch(updatePasswordClient({ oldPassword, newPassword }));
            setOldPassword('');
            setNewPassword('');
        }
    };


    useEffect(() => {
        let timeoutId;
        if (passwordUpdated !== '' && !!passwordUpdated) {
            timeoutId = setTimeout(() => {
                navigate('/')
            }, 3000);
        }
        return () => {
            if (timeoutId) {
                clearTimeout(timeoutId);
                dispatch(resetUpdated());
            }
        };
    }, [passwordUpdated])

    useEffect(() => {
        setError(null)
        return () => { dispatch(resetUpdated()); }
    }, [newPassword])
    return (
        <Paper variant="p1" >

            <PageHeaderManage title={"update Password".toUpperCase()} />

            {storeError && (

                <Alert
                    onClose={() => { dispatch(resetError()) }}
                    sx={{
                        margin: "20px auto"
                    }} variant="filled" severity="error">
                    {storeError}
                </Alert>

            )}
            {passwordUpdated && (
                <Alert
                    onClose={() => { }}
                    sx={{
                        margin: "20px auto"
                    }} variant="filled" severity="success">
                    {passwordUpdated}
                </Alert>
            )}

            <Box sx={{display:"flex",flexDirection:"row", flexWrap:"wrap" , gap:2 }}>
                <FormControl variant="outlined">
                    <InputLabel htmlFor="password">Old Password</InputLabel>
                    <OutlinedInput
                        fullWidth
                        id="oldPassword"
                        type={showOldPassword ? 'text' : 'password'}
                        name="oldPassword"
                        value={oldPassword}
                        onChange={(e) => setOldPassword(e.target.value)}
                        endAdornment={
                            <InputAdornment position="end">
                                <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={handleClickShowOldPassword}
                                    onMouseDown={handleMouseDownPassword}
                                    edge="end"
                                >
                                    {showOldPassword ? <VisibilityOff /> : <Visibility />}
                                </IconButton>
                            </InputAdornment>
                        }
                        label="Password"
                    />
                </FormControl>

                <FormControl variant="outlined">
                    <InputLabel htmlFor="password">New Password</InputLabel>
                    <OutlinedInput
                        id="password"
                        type={showNewPassword ? 'text' : 'password'}
                        name="newPassword"
                        value={newPassword}
                        onChange={(e) => setNewPassword(e.target.value)}
                        error={!!error}
                        endAdornment={
                            <InputAdornment position="end">
                                <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={handleClickShowNewPassword}
                                    onMouseDown={handleMouseDownPassword}
                                    edge="end"
                                >
                                    {showNewPassword ? <VisibilityOff /> : <Visibility />}
                                </IconButton>
                            </InputAdornment>
                        }
                        label="Password"
                    />
                    {error && (
                        <FormHelperText variant='filled' sx={{
                            color: 'red'
                        }}>{error}</FormHelperText>
                    )}
                </FormControl>
                <Box display='flex' alignItems='center' gap={'20px'} >
                    <Button variant="b2" onClick={() => navigate('/')} sx={{
                        width: '50%'
                    }}>Cancel</Button>
                    <Button variant="b1" onClick={handleSubmit} disabled={isButtonDisabled} sx={{
                        width: '50%'
                    }}>Update</Button>

                </Box>

            </Box>

        </Paper>
    )
}

export default ChangePasswordClient