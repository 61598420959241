/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Button, Paper, TextField } from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams, useSearchParams } from "react-router-dom";
import { addManageRefrsh, updateManageByIdRefrsh } from "../../actions/mangeAction";
import CustomToast from "../CustomToast/CustomToast";
import { resetError } from "../../slices/manageSlice";
import { t } from "i18next";
import PageHeaderManage from "../PageHeader/PageHeaderManage";



const Add = () => {
    const dispatch = useDispatch();
    const { mode } = useParams();
    const [searchParams] = useSearchParams()
    const parentId = searchParams.get('parentId');
    const location = useLocation();
    const state = location.state;
    const { id, name } = state || {};
    const [value, setValue] = useState();
    const { updated, added, hasError, errorMessage } = useSelector((state) => state.manage)
    const navigation = useNavigate();


    useEffect(() => {
        // this for edit
        setValue(name);
    }, [state]);


    const handleSave = () => {
        if (id) {
            dispatch(updateManageByIdRefrsh({ mode, id, data: { name: value } }));
            return;
        }
        dispatch(addManageRefrsh({ mode, data: { name: value }, parentId }));
    }

    const handleBack = () => {

        navigation(`/${mode}/${parentId ? 'list' : 'view'}` + location.search, {
            state: {
                showSuccess: false
            }
        })

    }


    useEffect(() => {
        // eslint-disable-next-line no-mixed-operators
        if (updated || added && !hasError) {

            navigation(`/${mode}/${parentId ? 'list' : 'view'}` + location.search, {
                state: {
                    showSuccess: true,
                    name: value
                }
            })
        }


    }, [updated, added, hasError]);

    const handleKeyDown = (event) => {
        if (event.key === 'Enter' && !(value === '' || value === undefined ? true : false)) {
            handleSave();
        }
      };








    const handleToastClose = () => {
        dispatch(resetError())
    };
    if (hasError) {
        return (<>
            <CustomToast open={hasError} message={errorMessage} onClose={handleToastClose} />
        </>)

    }

    return (
        <Paper variant="p1" >

            <PageHeaderManage title={t(mode).toUpperCase()} />
            <div onKeyDown={handleKeyDown}>

            <Box display='flex' justifyContent='space-evenly'>
                <TextField
                    label={mode}
                    variant="outlined"
                    onChange={(e) => setValue(e.target.value)}
                    sx={{
                        width: '50%'
                    }}
                    value={value}
                />
                <Box display='flex' justifyContent='space-evenly' alignItems='center'>
                    <Button variant="b2" sx={{
                        marginInline: '20px'
                    }} onClick={handleBack} >Cancel</Button>
                    <Button variant="b1" onClick={handleSave} disabled={value === '' || value === undefined ? true : false} >Save</Button>

                </Box>
            </Box>
            </div>
        </Paper>
    )
}

export default Add;