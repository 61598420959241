/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Button, Paper, TextField } from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams, useSearchParams } from "react-router-dom";
import { updatePasswordByIdRefresh } from "../../actions/mangeAction";
import CustomToast from "../CustomToast/CustomToast";
import { resetError } from "../../slices/manageSlice";
import PageHeaderManage from "../PageHeader/PageHeaderManage";



const ChangePassword = () => {
    const dispatch = useDispatch();
    const { mode } = useParams();
    const [searchParams] = useSearchParams()
    const id = searchParams.get('id');
    const location = useLocation();
    const [value, setValue] = useState();
    const { updated, added, hasError, errorMessage } = useSelector((state) => state.manage)
    const navigation = useNavigate();


    const handleSave = () => {
        if (id && value !== "") {
            dispatch(updatePasswordByIdRefresh({ mode, id, data: { newPassword: value } }));
            return;
        }
    }

    const handleBack = () => {

        navigation(`/${mode}/userList` + location.search, {
            state: {
                showSuccess: false
            }
        })

    }
    useEffect(() => {
        // eslint-disable-next-line no-mixed-operators
        if (updated || added && !hasError) {

            navigation(`/${mode}/userList`, {
                state: {
                    showSuccess: true,
                    name: value
                }
            })
        }
    }, [updated, added, hasError]);

    const handleToastClose = () => {
        dispatch(resetError())
    };
    if (hasError) {
        return (<>
            <CustomToast open={hasError} message={errorMessage} onClose={handleToastClose} />
        </>)

    }

    return (
        <Paper variant="p1" >

            <PageHeaderManage title={"update Password".toUpperCase()} />

            <Box display='flex' justifyContent='space-evenly'>
                <TextField
                    label={"update Password"}
                    variant="outlined"
                    onChange={(e) => setValue(e.target.value)}
                    sx={{
                        width: '50%'
                    }}
                    value={value}
                    name="newPassword"
                />
                <Box display='flex' justifyContent='space-evenly' alignItems='center'>
                    <Button variant="b2" sx={{
                        marginInline: '20px'
                    }} onClick={handleBack} >Cancel</Button>
                    <Button variant="b1" onClick={handleSave} disabled={value === '' || value === undefined ? true : false} >Update</Button>

                </Box>
            </Box>
        </Paper>
    )
}

export default ChangePassword;