import React, { useEffect, useState } from "react";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import { useDispatch, useSelector } from "react-redux";
import { CircularProgress } from "@mui/material";
import { t } from "i18next";
import { searchClient } from "../../../actions/formAction";

function ClientsMoveMenu({selectedClient , setSelectedClient}) {
  const dispatch = useDispatch();
  const [anchor, setAnchor] = useState(null);
  const open = Boolean(anchor);
  const [debouncedInputValue, setDebouncedInputValue] = useState("");
  const [input, setInput] = useState("");

  const formReducer = useSelector((state) => state.form);
  const [mainData, setMainData] = useState([]);

  useEffect(() => {
    if (formReducer.isLoadingClientData === "succeeded") {
      const newData = formReducer.client;
      setMainData(newData);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formReducer.isLoadingClientData]);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      dispatch(searchClient({ name: input }));
    }, 500);
    return () => clearTimeout(timeoutId);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedInputValue, dispatch]);



  const handleClick = (event) => {
    setAnchor(event.currentTarget);
  };

  const handleClose = () => {
    setAnchor(null);
  };

  return (
    <Box className="Client">
      <Button
        id="Client"
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
      >
        Client
        {open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
      </Button>

      <Menu
        id="basic-menu"
        anchorEl={anchor}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "Client",
        }}
        PaperProps={{
          style: {
            width: "25%",
            maxWidth: "100%",
            maxHeight: "300px",
            left: 0,
            right: 0,
          },
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            flexWrap: "wrap",
            width: "100%",
            maxHeight: "300px",
            overflow: "auto",
            overflowY: "hidden",
            p: 2,
          }}
        >
          <Autocomplete
            id="client-autocomplete"
            options={mainData}
            value={
              selectedClient
                ? mainData.find((item) => item.id === selectedClient.id)
                : null
            }
            onChange={(event, value) => setSelectedClient(value)}
            inputValue={input}
            onInputChange={(event, value) => {
              setInput(value);
              setDebouncedInputValue(value);
            }}
            getOptionLabel={(option) => (option ? option.name : "")}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            loading={formReducer.isLoadingClientData === "pending"}
            renderInput={(params) => (
              <TextField
                {...params}
                label={`#${t("Client")}`}
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <>
                      {formReducer.isLoadingClientData === "pending" ? (
                        <CircularProgress color="error" size={20} />
                      ) : null}
                      {params.InputProps.endAdornment}
                    </>
                  ),
                }}
              />
            )}
          />
        </Box>
      </Menu>
    </Box>
  );
}

export default ClientsMoveMenu;
