/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Button, Checkbox, FormControl, FormControlLabel, FormHelperText, IconButton, InputAdornment, InputLabel, OutlinedInput, Paper, TextField } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useSearchParams } from 'react-router-dom';
import PageHeaderManage from '../PageHeader/PageHeaderManage';
import { t } from 'i18next';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { addManageRefrsh, updateManageByIdRefrsh } from '../../actions/mangeAction';
import { resetSelectedUser, resetState } from '../../slices/manageSlice';
import { showErrorToast } from '../../slices/toastSlice';
import Loader from '../Loader';

const AddClient = () => {
    const [showPassword, setShowPassword] = useState(false);
    const handleClickShowPassword = () => setShowPassword((show) => !show);

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };
    const dispatch = useDispatch();
    const [searchParams] = useSearchParams();
    const navigate = useNavigate()
    const id = parseInt(searchParams.get('id'));
    const selectedUser = useSelector((state) => state.manage.selectedUser) || {};
    const { updated, added, hasError, errorMessage, loading } = useSelector((state) => state.manage) || {};


    const validationSchema = yup.object().shape({
        userName: yup.string().required('User name is required'),
        name: yup.string().required('First name is required'),
        email: yup.string().nullable().email('Invalid email'),
        phoneNumber: yup
            .string().nullable()
            // .matches(/^\d+$/, 'Phone number must contain only digits')
            .matches(
                /^\+\d{6,14}$/,
                'Phone number is not valid. Please use the format: "+1234567890"'
            )
        ,
        password: yup
            .string()
            .required('Password is required')
            .min(8, 'Password must be at least 8 characters long')
            .matches(
                // eslint-disable-next-line no-useless-escape
                /^(?=.*[!@#$%^&*()_+{}\[\]:;<>,.?~\\/-]).*$/,
                'Password must contain at least one special character'
            ),

    });

    const validationSchemaWithoutPassword = yup.object().shape({
        userName: yup.string().required('User name is required'),
        name: yup.string().required('First name is required'),
        email: yup.string().nullable().email('Invalid email'),
        phoneNumber: yup
            .string().nullable()
            // .matches(/^\d+$/, 'Phone number must contain only digits')
            .matches(
                /^\+\d{6,14}$/,
                'Phone number is not valid. Please use the format: "+1234567890"'
            ),

    });

    const formik = useFormik({
        initialValues: {
            userName: selectedUser.userName || '',
            name: selectedUser.name || '',
            email: selectedUser.email || null,
            company: selectedUser.company || "",
            phoneNumber: selectedUser.phoneNumber || null,
            password: '',
            enabled: selectedUser.enabled || true
        },
        validationSchema: !id ? validationSchema : validationSchemaWithoutPassword,
        onSubmit: (values) => handleSaveUpdate(values),
        enableReinitialize: true,
    });
    const handleSaveUpdate = (data) => {

        if (data?.email === '') {
            data.email = null;
        }

        if (data?.phoneNumber === '') {
            data.phoneNumber = null
        }
        const payload = data
        if (id) {
            dispatch(updateManageByIdRefrsh({ mode: 'client', id, data: payload }));
            return;
        }
        dispatch(addManageRefrsh({ mode: 'client', data: payload }));

    }
    useEffect(() => {
        // eslint-disable-next-line no-mixed-operators
        if (updated || added && !hasError) {
            navigate('/client/clientList', {
                state: {
                    updated,
                    added,
                    hasError
                }
            })
        }

        return () => {
            dispatch(resetState());
            dispatch(resetSelectedUser());
        }

    }, [updated, added, hasError]);
    const handleKeyDown = (event, submitForm) => {
        if (event.key === 'Enter') {
            event.preventDefault();
            submitForm();
        }
    };

    if (hasError) {
        dispatch(showErrorToast(errorMessage));

    }
    if (loading === 'pending') {
        return (
            <Loader />
        )
    }

    return (
        <>
            <div onKeyDown={(e) => handleKeyDown(e, formik.submitForm)}>

                <Paper variant='p1'>
                    <PageHeaderManage title={t('client').toUpperCase()} />

                    <Box display='grid' gridTemplateColumns={`${!id ? "repeat(4, 1fr)" : "repeat(3, 1fr)"}`} gap={"20px"} >
                        <TextField
                            label="User name"
                            name="userName"
                            variant="outlined"
                            value={formik.values.userName}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            error={formik.touched.userName && Boolean(formik.errors.userName)}
                            helperText={formik.touched.userName && formik.errors.userName}
                        />
                        <TextField
                            label="Name"
                            variant="outlined"
                            name="name"
                            value={formik.values.name}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            error={formik.touched.name && Boolean(formik.errors.name)}
                            helperText={formik.touched.name && formik.errors.name}
                        />
                        <TextField
                            label="Email"
                            name="email"
                            variant="outlined"
                            value={formik.values.email}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            error={formik.touched.email && Boolean(formik.errors.email)}
                            helperText={formik.touched.email && formik.errors.email}
                        />
                        {!id && (
                            <FormControl variant="outlined">
                                <InputLabel htmlFor="password">Password</InputLabel>
                                <OutlinedInput
                                    id="password"
                                    type={showPassword ? 'text' : 'password'}
                                    name="password"
                                    value={formik.values.password}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    error={formik.touched.password && Boolean(formik.errors.password)}
                                    endAdornment={
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={handleClickShowPassword}
                                                onMouseDown={handleMouseDownPassword}
                                                edge="end"
                                            >
                                                {showPassword ? <VisibilityOff /> : <Visibility />}
                                            </IconButton>
                                        </InputAdornment>
                                    }
                                    label="Password"
                                />
                                <FormHelperText variant='filled' sx={{
                                    color: 'red'
                                }}>{formik.touched.password && formik.errors.password}</FormHelperText>

                            </FormControl>
                        )}

                    </Box>
                    <Box display='grid' gridTemplateColumns={"repeat(4, 1fr)"} marginY={"40px"} gap={"20px"} >

                        <TextField
                            label="Company"
                            variant="outlined"
                            name="company"
                            value={formik.values.company}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            error={formik.touched.company && Boolean(formik.errors.company)}
                            helperText={formik.touched.company && formik.errors.company}
                        />
                        <TextField
                            label="Phone Number"
                            variant="outlined"
                            name="phoneNumber"
                            value={formik.values.phoneNumber}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            error={formik.touched.phoneNumber && Boolean(formik.errors.phoneNumber)}
                            helperText={formik.touched.phoneNumber && formik.errors.phoneNumber}
                        />

                        <FormControlLabel
                            label="Enabled"
                            control={
                                <Checkbox
                                    name='enabled'
                                    // value={formik.values.enabled}
                                    defaultChecked={formik.values.enabled}
                                    onChange={formik.handleChange}

                                    sx={{ '& .MuiSvgIcon-root': { fontSize: 28 } }}
                                />
                            }
                        />



                        <Box display='grid' gridTemplateColumns={"repeat(2, 1fr)"} alignItems='center'>
                            <Button variant="b2" sx={{
                                marginInline: '20px'
                            }} onClick={() => {
                                navigate('/client/clientList');
                                dispatch(resetSelectedUser())
                            }} >Cancel</Button>

                            <Button variant="b1" onClick={formik.handleSubmit}>Save</Button>

                        </Box>
                    </Box>
                </Paper>
            </div>

        </>
    )
}

export default AddClient