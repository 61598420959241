import { AlertTitle, Stack } from '@mui/material';
import Alert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';
import React, { useEffect, useState } from 'react';


const CustomToast = ({ open, message, onClose, duration }) => {
    const [visible, setVisible] = useState(open);

    useEffect(() => {
        setVisible(open);
    }, [open]);

    const handleClose = () => {
        setVisible(false);
        onClose && onClose();
    };

    return (
        <Snackbar
            open={visible}
            autoHideDuration={duration}
            onClose={handleClose}
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}

        >
            <Stack spacing={2}>
                <Alert sx={{ width: '600px' }} severity='error' variant='filled' onClose={handleClose}>
                    <AlertTitle >
                        {message}
                    </AlertTitle>
                </Alert>
            </Stack>
        </Snackbar>
    );
};

export default CustomToast;
