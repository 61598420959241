import React, { useState } from 'react'

import { Box, Collapse, FormControlLabel, IconButton, Paper, Switch, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TableSortLabel, Typography } from '@mui/material';
import LastPageIcon from '@mui/icons-material/LastPage';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import { visuallyHidden } from '@mui/utils';
import { t } from 'i18next';
import CollectionsIcon from '@mui/icons-material/Collections';
import CloseIcon from '@mui/icons-material/Close';
import CheckIcon from '@mui/icons-material/Check';
import PreviewPicture from '../../../components/PreviewPic/PreviewPicture';
import { useSearchParams } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import { baseUrl } from '../../../util/constant';
import { useSelector } from 'react-redux';
import Progress from '../../../components/Loader/Progress ';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';


const headCells = [
    { id: 'v.status', numeric: true, disablePadding: false, label: '' },

    { id: 'v.status', numeric: true, disablePadding: false, label: 'Status' },
    { id: 'v.purchaseDate', numeric: false, disablePadding: false, label: 'Purchased Date' },
    { id: 'model', numeric: false, disablePadding: false, label: 'Description' },

    { id: 'v.vin', numeric: false, disablePadding: false, label: 'VIN' },
    { id: 'v.lot', numeric: false, disablePadding: false, label: 'LOT' },
    { id: 'v.color', numeric: false, disablePadding: false, label: 'Color' },
    { id: 'v.fuelType', numeric: false, disablePadding: false, label: 'Fuel Type' },





    { id: 'auction', numeric: false, disablePadding: false, label: 'Auction' },

    { id: 'v.key', numeric: false, disablePadding: false, label: 'Key' },

    { id: 'v.title', numeric: false, disablePadding: false, label: 'Title' },
    { id: 'v.id', numeric: false, disablePadding: false, label: 'Picture' },
    { id: 'v.carFax', numeric: false, disablePadding: false, label: 'carfax' },





];



const createData = (d) => {
    const destination = d.dispatchInformations?.[0]?.destination?.name ?? null;
    const port = d.dispatchInformations?.[0]?.port?.name ?? null;
    const vehicleType = d.towingInformation?.[0]?.vehicleType?.name ?? "";
    const extraAmount = d.towingInformation?.[0]?.extraAmount ?? null;

    let make = '';
    let model = '';

    if (d.make != null) {
        make = d.make.name;
    }

    if (d.model != null) {
        model = d.model.name;
    }


    const description = `${d.year} ${make} ${model} ${vehicleType}`
    return {
        id: d.id, make: d.make?.name, auction: d?.auctions?.name, model: d?.model?.name,
        year: d?.year, status: d?.status?.name, purchaseDate: d?.purchaseDate, etaDate: d?.container?.booking?.etaDate,
        arrivalDate: d?.container?.delivered, title: d.title, pickupLocation: d.pickupLocation,
        purchasePrice: d.carPrice, port, destination, contractNumber: d.contractNumber,
        carfax: d.carfax, images: d.warehousePictures, isLoading: false, description: description,
        vin: d.vin, lot: d.lot, color: d?.color?.name, key: d.key, fuelType: d?.fuelType?.name,
        salesInvoice: d?.invoiceSalesNo, purchasePriceFile: d.purchasePriceFile, shippingPriceFile: d.shippingPriceFile, extraFees: extraAmount, contractFile: d.contractFile,
        containerNumber: d?.container?.containerNumber, bookingNumber: d?.container?.booking?.name, ssLineName: d?.container?.booking?.ssLineName
    };
};















function TablePaginationActions(props) {
    const theme = useTheme();
    const { count, page, rowsPerPage, onPageChange } = props;

    const handlePageChange = (event, newPage) => {
        onPageChange(event, newPage);
    };

    return (
        <Box sx={{ flexShrink: 0, ml: 2.5 }}>
            <IconButton onClick={(event) => handlePageChange(event, 0)} disabled={page === 0} aria-label="first page">
                {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
            </IconButton>
            <IconButton onClick={(event) => handlePageChange(event, page - 1)} disabled={page === 0} aria-label="previous page">
                {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
            </IconButton>
            <IconButton
                onClick={(event) => handlePageChange(event, page + 1)}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="next page"
            >
                {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
            </IconButton>
            <IconButton
                onClick={(event) => handlePageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1))}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="last page"
            >
                {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
            </IconButton>
        </Box>
    );
}



// Component for the table head with sorting functionality

function EnhancedTableHead(props) {
    const { order, orderBy, onRequestSort } = props;

    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };

    return (
        <TableHead>
            <TableRow>
                {headCells.map((headCell, index) => (
                    <TableCell
                        key={index}
                        padding={headCell.disablePadding ? 'none' : 'normal'}
                        sortDirection={orderBy === headCell.id ? order : false}
                        variant="head"
                    >
                        <TableSortLabel active={orderBy === headCell.id} direction={orderBy === headCell.id ? order : 'asc'} onClick={createSortHandler(headCell.id)}>
                            {t(headCell.label).toUpperCase()}
                            {orderBy === headCell.id ? (
                                <Box component="span" sx={visuallyHidden}>
                                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                </Box>
                            ) : null}
                        </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}


const CollapseView = ({ Row }) => {

    const data = Row?.row

    const url = baseUrl;



    const booking = data?.bookingNumber

    const port = data?.port
    const pickupLocation = data?.pickupLocation
    const destination = data?.destination
    const arrivalDate = data?.arrivalDate




    const RowView = ({ textAlign, variant, value }) => (

        <Typography variant={variant} sx={{ textAlign: textAlign, borderBottomStyle: "solid", borderWidth: "thin", width: "100%", color: 'secondary.main', minHeight: 27 }}>
            {value ? value : "***"}
        </Typography>

    )



    return <Box sx={{ width: "100%", display: "flex", gap: 2, justifyContent: "start", m: 2, backgroundColor: 'colorA.main' }}>

        <Box sx={{ display: "flex", width: "45%", m: 2 }}>



            <Box sx={{ display: "flex", width: "47%", flexDirection: "column", alignItems: "center", justifyContent: "start", gap: 1 }}>


                <RowView variant='button' textAlign="center" value="INFO" />


                <RowView variant='body1' textAlign="start" value="CONTAINER NUMBER" />


                <RowView variant='body1' textAlign="start" value="BOOKING" />
                <RowView variant='body1' textAlign="start" value="SS.LINE NAME" />


                <RowView variant='body1' textAlign="start" value="ETA DATE" />
                <RowView variant='body1' textAlign="start" value="ARRIVAL DATE" />



                <RowView variant='body1' textAlign="start" value="PORT" />



                <RowView variant='body1' textAlign="start" value="PICKUP LOCATION" />



                <RowView variant='body1' textAlign="start" value="DESTINATION" />







            </Box>

            <Box sx={{ width: "1px", backgroundColor: "secondary.main", mr: 1, ml: 1 }} />

            <Box sx={{ display: "flex", width: "47%", flexDirection: "column", alignItems: "start", justifyContent: "start", gap: 1 }}>

                <RowView variant='button' textAlign="center" value="DETAILS" />

                <RowView variant='body1' textAlign="start" value={data.containerNumber} />

                <RowView variant='body1' textAlign="start" value={booking} />
                <RowView variant='body1' textAlign="start" value={data.ssLineName} />


                <RowView variant='body1' textAlign="start" value={data.etaDate} />
                <RowView variant='body1' textAlign="start" value={arrivalDate} />


                <RowView variant='body1' textAlign="start" value={port} />



                <RowView variant='body1' textAlign="start" value={pickupLocation} />



                <RowView variant='body1' textAlign="start" value={destination} />

            </Box>
        </Box>


        <Box sx={{ width: "1px", backgroundColor: "secondary.main", mt: 4, mb: 4 }} />
        <Box sx={{ width: "1px", backgroundColor: "secondary.main", mt: 4, mb: 4 }} />


        <Box sx={{ display: "flex", width: "25%", m: 2 }}>



            <Box sx={{ display: "flex", width: "47%", flexDirection: "column", alignItems: "center", justifyContent: "start", gap: 1 }}>


                <RowView variant='button' textAlign="center" value="INFO" />


                <RowView variant='body1' textAlign="start" value="SALES INVOICE NUMBER" />

                <RowView variant='body1' textAlign="start" value="CONTRACT NUMBER" />


                <RowView variant='body1' textAlign="start" value="EXTRA FEES" />













            </Box>

            <Box sx={{ width: "1px", backgroundColor: "secondary.main", mr: 1, ml: 1 }} />

            <Box sx={{ display: "flex", width: "47%", flexDirection: "column", alignItems: "start", justifyContent: "start", gap: 1 }}>

                <RowView variant='button' textAlign="center" value="DETAILS" />


                <RowView variant='body1' textAlign="start" value={data.invoiceSalesNo} />

                <RowView variant='body1' textAlign="start" value={data.contractNumber} />


                <RowView variant='body1' textAlign="start" value={data.extraAmount} />







            </Box>
        </Box>
        <Box sx={{ width: "1px", backgroundColor: "secondary.main", mt: 4, mb: 4 }} />
        <Box sx={{ width: "1px", backgroundColor: "secondary.main", mt: 4, mb: 4 }} />


        <Box sx={{ display: "flex", width: "25%", m: 2 }}>



            <Box sx={{ display: "flex", width: "47%", flexDirection: "column", alignItems: "center", justifyContent: "start", gap: 1 }}>


                <RowView variant='button' textAlign="center" value="INFO" />


                <RowView variant='body1' textAlign="start" value="Purchase Price File" />

                <RowView variant='body1' textAlign="start" value="Shipping Price File" />


                <RowView variant='body1' textAlign="start" value="Contract File" />













            </Box>

            <Box sx={{ width: "1px", backgroundColor: "secondary.main", mr: 1, ml: 1 }} />

            <Box sx={{ display: "flex", width: "47%", flexDirection: "column", alignItems: "start", justifyContent: "start", gap: 1 }}>

                <RowView variant='button' textAlign="center" value="DETAILS" />


                <RowView variant='body1' textAlign="start" value={data?.purchasePriceFile != null && data?.purchasePriceFile?.length !== 0 ? <a href={`${url}${data?.purchasePriceFile[0]?.filePath}`} target="_blank" rel="noopener noreferrer">Open</a> : <CloseIcon fontSize='small' />} />

                <RowView variant='body1' textAlign="start" value={data?.shippingPriceFile != null && data?.shippingPriceFile?.length !== 0 ? <a href={`${url}${data?.shippingPriceFile[0]?.filePath}`} target="_blank" rel="noopener noreferrer">Open</a> : <CloseIcon fontSize='small' />} />


                <RowView variant='body1' textAlign="start" value={data?.contractFile != null && data?.contractFile?.length !== 0 ? <a href={`${url}${data?.contractFile[0]?.filePath}`} target="_blank" rel="noopener noreferrer">Open</a> : <CloseIcon fontSize='small' />} />









            </Box>
        </Box>

    </Box>


}



function DesktopDataGrid() {


    const [searchParams, setSearchParams] = useSearchParams();

    const { vehiclesMobile } = useSelector((state) => state.clientViewInitial);

    const [openPreviewPicture, setOpenPreviewPicture] = useState(false);
    const [pictureFile, setPictureFile] = useState([]);



    const data = vehiclesMobile.data || {};



    const content = data.content || [];


    // URL query parameters and their setters
    const page = Number(searchParams.get('PageNumber')) || 0;
    const rowsPerPage = Number(searchParams.get('PageSize')) || 5;
    const order = searchParams.get('SortOrder') || 'desc';
    const orderBy = searchParams.get('SortBy') || 'id';

    const setPage = (value) => {
        searchParams.set('PageNumber', value);
        setSearchParams(searchParams);
    };

    const setRowsPerPage = (value) => {
        searchParams.set('PageSize', value);
        setSearchParams(searchParams);
    };

    const setOrder = (value) => {
        searchParams.set('SortOrder', value);
        setSearchParams(searchParams);
    };

    const setOrderBy = (value) => {
        searchParams.set('SortBy', value);
        setSearchParams(searchParams);
    };

    const [dense, setDense] = useState(true);

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };



    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleChangeDense = (event) => {
        setDense(event.target.checked);
    };




    const handleClose = () => {
        setOpenPreviewPicture(false)
        setPictureFile([])
    };


    const handelOpen = (file) => {
        setPictureFile(file)
        setOpenPreviewPicture(true)

    }









    // Table data preparation
    const rows = content.map((e) => (createData(e)))

    const [openArray, setOpenArray] = useState({ Name: [] });

    const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - data.totalElements) : 0;
    const url = baseUrl;



    if (vehiclesMobile.loading === 'pending' || vehiclesMobile.loading === 'idle') {
        return <Progress Msg="Please wait ..." />
    }

    const openCollapse = (index) => {


        if (checkIOpen(index)) {
            const value = openArray.Name.indexOf(index)
            const lastArray = openArray.Name;
            lastArray.splice(value, 1); // 2nd parameter means remove one item only
            setOpenArray({ Name: lastArray })


        } else {

            const lastArray = openArray.Name;
            lastArray.push(index);

            setOpenArray({ Name: lastArray })

        }



    }
    const checkIOpen = (index) => {


        let resutt;
        const value = openArray.Name.indexOf(index)
        if (value > -1) {


            resutt = true;
        } else {

            resutt = false;

        }



        return resutt;

    }

    return (
        <Box sx={{ width: '100%' }}>
            <Paper sx={{ width: '100%', mb: 2 }}>
                <TableContainer>
                    <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle" size={dense ? 'small' : 'medium'}>
                        <EnhancedTableHead
                            order={order}
                            orderBy={orderBy}
                            onRequestSort={handleRequestSort}
                            rowCount={rows.length}
                        />
                        <TableBody>
                            {rows.map((row, index) => (
                                <React.Fragment key={index}>
                                    <TableRow hover role="checkbox" tabIndex={-1} sx={{ backgroundColor: index % 2 === 0 ? '#F5F5F5' : 'secondary.main' }} key={index}>
                                        <TableCell>

                                            <IconButton
                                                aria-label="expand row"
                                                size="small"
                                                onClick={() => openCollapse(index)}
                                            >
                                                {checkIOpen(index) ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                                            </IconButton>



                                        </TableCell>
                                        <TableCell>{row.status}</TableCell>
                                        <TableCell>{row.purchaseDate}</TableCell>
                                        <TableCell>{row.description}</TableCell>
                                        <TableCell>{row.vin}</TableCell>
                                        <TableCell>{row.lot}</TableCell>
                                        <TableCell>{row.color}</TableCell>
                                        <TableCell>{row.fuelType}</TableCell>

                                        <TableCell>{row.auction}</TableCell>

                                        <TableCell>{row.key ? <CheckIcon /> : <CloseIcon />}</TableCell>
                                        <TableCell>{row.title ? <CheckIcon /> : <CloseIcon />}</TableCell>





                                        <TableCell>
                                            
                                            { row.images.length !== 0 ?
                                             <IconButton onClick={() => handelOpen(row.images)} aria-label="view" title="view"><CollectionsIcon /></IconButton>
                                            :<CloseIcon />}
                                        </TableCell>
                                        <TableCell>{  row?.carfax !== null && row?.carfax?.length !== 0 ? <a href={`${url}${row?.carfax[0]?.filePath}`} target="_blank" rel="noopener noreferrer">Open</a> : <CloseIcon />}</TableCell>



                                    </TableRow>


                                    <TableRow>
                                        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={13}>
                                            <Collapse in={checkIOpen(index)} timeout="auto" unmountOnExit>
                                                <CollapseView Row={{ row }} />
                                            </Collapse>
                                        </TableCell>

                                    </TableRow>




                                </React.Fragment>
                            ))}
                            {emptyRows > 0 && (
                                <TableRow style={{ height: (dense ? 33 : 53) * emptyRows }}>
                                    <TableCell colSpan={5} />
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[5, 10, 25, 50]}
                    component="div"
                    count={data.totalElements}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    SelectProps={{
                        inputProps: { 'aria-label': 'rows per page' },
                        native: true,
                    }}
                    ActionsComponent={TablePaginationActions}
                />
            </Paper>
            <FormControlLabel control={<Switch checked={dense} onChange={handleChangeDense} />} label={t('Densepadding')} />


            {openPreviewPicture && (
                <PreviewPicture
                    open={openPreviewPicture}
                    handleClose={handleClose}
                    label={'PreviewPicture'}
                    file={pictureFile}
                />
            )}


        </Box>
    );


}

export default DesktopDataGrid