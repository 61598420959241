import { Box, } from '@mui/material'
import React from 'react'
import UsersDropdown from './UsersDropdown'
import VehicleText from './VehicleText'
import LogsDatePickerFrom from './LogsDatePickerFrom'

import LogsDatePickerTo from './LogsDatePickerTo'

export default function LogsSearch() {
    return (
        <>
            <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems:"start", gap: 2 }} >

                <UsersDropdown />
                <VehicleText />
                <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "center", gap: 1 , alignItems:"start" }} >

                    <LogsDatePickerFrom />
                    <LogsDatePickerTo />
                </Box>
            </Box>

        </>
    )
}
