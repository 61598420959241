import { Box, CircularProgress, Typography } from '@mui/material'
import React from 'react'
import PropTypes from 'prop-types';

export default function Progress({Msg}) {
    return (
        <Box sx={{display:"flex", justifyContent:"center", flexDirection:"column" , alignItems:"center"}}>

            <CircularProgress color='colorD' />

            <Typography sx={{color:"colorD.main"}} variant='caption'>{Msg}</Typography>

        </Box>

    )
}



Progress.propTypes = {
    /**
     * The value of the progress indicator for the determinate variant.
     * Value between 0 and 100.
     * @default 0
     */
    Msg: PropTypes.string.isRequired,
  };