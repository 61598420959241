/* eslint-disable react-hooks/exhaustive-deps */

import { Box, Button } from "@mui/material";

import React, { useEffect, useState } from "react";
import VehiclesMenu from "./VehiclesMenu";
import AuctionsMenu from "./AuctionsMenu";
import PortsMenu from "./PortsMenu copy";
import ClientsMenu from "./ClientsMenu";
import StatusMenu from "./StatusMenu";
import SearchOptionMenu from "./SearchOptionMenu";

import { t } from "i18next";
import { useLocation, useNavigate } from "react-router-dom";
import MultiFieldSearch from "./MultiFieldSearch ";
import { useSelector } from "react-redux";
import MixSearch from "./MixSearch";

const DropdownMenu = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const formReducer = useSelector((state) => state.form);

  const [adavancedSearchPrems, setAdavancedSearchPrems] = useState(
    formReducer?.data?.advancedSearches || []
  );

  useEffect(() => {
    if (formReducer.loading === "succeeded") {
      setAdavancedSearchPrems(formReducer?.data?.advancedSearches || []);
    }
  }, [formReducer.loading]);

  const handelResetFilter = () => {
    const searchParams = new URLSearchParams(location.search);

    searchParams.delete("Clients");
    searchParams.delete("Auctions");
    searchParams.delete("Ports");
    searchParams.delete("SearchOption");
    searchParams.delete("Status");
    searchParams.delete("Vehicles");
    searchParams.delete("AdvancedSearch");
    searchParams.delete("MixSearch");

    navigate({ search: searchParams.toString() });
  };

  return (
    <Box sx={{pl: 2,display:"flex", flexDirection:"column", gap:2 , alignItems:"start",justifyContent:"start"}}>
      <Box sx={{  display: "flex", gap: 2, alignItems:"start",justifyContent:"start" }}>
        <VehiclesMenu />
        <AuctionsMenu />
        <PortsMenu />
        <ClientsMenu />
        <StatusMenu />
        <SearchOptionMenu />

        {!adavancedSearchPrems ? (
          ""
        ) : (
          <MultiFieldSearch filter={adavancedSearchPrems} />
        )}

        <Button color="colorD" sx={{minWidth:"150px"}} onClick={handelResetFilter}>
          {t("ResetFilter")}
        </Button>
      </Box>
      <MixSearch />
    </Box>
  );
};

export default DropdownMenu;
